export enum RowType {
  ALTERNATIVE_ASSET = "ALTERNATIVE_ASSET",
  CONTINUE_WATCHING = "CONTINUE_WATCHING",
  EDITORIAL_COLLECTION = "EDITORIAL_COLLECTION",
  FAVOURITE = "FAVOURITE",
  GRID = "GRID",
  LINKCARD = "LINKCARD",
  NOW_ON_YOUR_CHANNELS = "NOW_ON_YOUR_CHANNELS",
  OTHERS_HAVE_ALSO_SEEN = "OTHERS_HAVE_ALSO_SEEN",
  PAYED = "PAYED",
  POPULAR = "POPULAR",
  RECENTLY_ADDED = "RECENTLY_ADDED",
  RECOMMENDED_FOR_YOU = "RECOMMENDED_FOR_YOU",
  RECORDINGS = "RECORDINGS",
  REST_OF_DAY = "REST_OF_DAY",
  SEARCH = "SEARCH",
  SERIES_PAGE = "SERIES_PAGE",
  TVOD = "TVOD",
  TOP_CONTENT = "TOP_CONTENT",
  ZAPPER = "ZAPPER",
}

export default RowType;
