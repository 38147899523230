import styled from "styled-components";
import AudioController from "./AudioController";
import NextContent from "./NextContent";
import PlayButton from "./PlayButton";
import SeekBack from "./SeekBack";
import SeekForward from "./SeekForward";
import Subtitles from "./Subtitles";

const MediaController = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

export default function Controls(): JSX.Element {
  return (
    <MediaController id="media_control">
      <Group>
        <PlayButton />
        <SeekBack />
        <SeekForward />
        <AudioController />
      </Group>
      <Group>
        <NextContent />
        <Subtitles />
      </Group>
    </MediaController>
  );
}
