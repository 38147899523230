import { EDITORIAL_PROMOTIONS as FETCH } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import { CoverData, RequestStatus, TopBannerType } from "types/types";
import apiFetch from "utils/apiFetch";

export type EditorialPromotionsFetchProps = {
  page: string;
};

type Promo = {
  cover?: CoverData[];
  topBanner?: TopBannerType[];
};

export type EditorialPromotionsResponse = {
  status: RequestStatus;
  data: {
    home: Promo;
    tvArchive: Promo;
    series: Promo;
    movies: Promo;
    kids: Promo;
    movieRental: Promo;
    explore: Promo;
  };
};

const MAX_PROMO_COVERS = 4;

const initialState = {
  status: REQ.INIT,
  data: {
    home: {},
    tvArchive: {},
    series: {},
    movies: {},
    kids: {},
    movieRental: {},
  },
};

export default function editorialPromotionsAsync(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH.REQUEST:
      if (state.status === REQ.SUCCESS) {
        return {
          ...state,
          status: REQ.UPDATING,
        };
      }
      return {
        ...state,
        status: REQ.PENDING,
      };

    case FETCH.SUCCESS: {
      const updatedData = state.data;
      const { cover } = payload;
      const coverLimitedToMax =
        cover && cover.length ? cover.slice(0, MAX_PROMO_COVERS) : cover;

      payload.cover = coverLimitedToMax;
      updatedData[action.page] = payload;

      return {
        status: REQ.SUCCESS,
        data: updatedData,
      };
    }

    case FETCH.ERROR:
      return {
        status: REQ.ERROR,
        error: new Error(payload),
        data: state.data,
      };

    default:
      return state;
  }
}

export function fetchEditorialPromotions(page: string) {
  return (dispatch) => {
    dispatch({ type: FETCH.REQUEST });

    apiFetch(`/api/editorial/promotions/${page}`).then(
      (result) => {
        result.page = page;
        dispatch({ type: FETCH.SUCCESS, payload: result, page });
      },
      (non2xxResponseError) => {
        dispatch({ type: FETCH.ERROR, payload: non2xxResponseError });
      }
    );
  };
}
