import {
  CastControllerType,
  startCasting,
  stopCasting,
} from "ducks/castController";
import { StreamInformationAsyncType } from "ducks/stream/streamInformation";
import useCast from "hooks/cast/useCast";
import useCastPlayer from "hooks/cast/useCastPlayer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import theme from "styles/theme";
import { formatStreamToCast, loadCastQueue } from "utils/CastUtil";
import { trackError } from "utils/ErrorUtil";
import CastDeviceInfo from "./controls/CastDeviceInfo";
import Controls from "./controls/Controls";
import Progressbar from "./controls/Progressbar";

const Padding = styled.div`
  height: 18.5rem;
`;

const CastTitle = styled.h2`
  font-size: 3.2rem;
  margin: 0;
  font-weight: bold;
`;

const CastSubTitle = styled.h3`
  font-size: 2.4rem;
  margin: 0;
  font-weight: bold;
  color: ${theme.color.grey200};
`;

const TopBar = styled.div`
  padding-bottom: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
`;

const Wrapper = styled.div`
  background-color: ${theme.color.grey900};
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: calc(4vw - 1.6rem);
  padding-right: calc(4vw - 1.6rem);
  padding-top: 1.6rem;
  padding-bottom: 0.8rem;
`;

type RootState = {
  streamInformationAsync: StreamInformationAsyncType;
  castController: CastControllerType;
};

export default function CastControlbar(): JSX.Element | null {
  const dispatch = useDispatch();
  const castData = useSelector((state: RootState) => state.castController);
  const streamInfo = useSelector(
    (state: RootState) => state.streamInformationAsync
  );
  const [isLoading, setIsLoading] = useState(false);
  const [castActive, setCastActive] = useState(false);
  const [currentStreamId, setCurrentStreamId] = useState<string | undefined>(
    undefined
  );
  const { initialized, connected, player } = useCast();

  const { loadMedia, title, subTitle } = useCastPlayer();

  if (connected && !castData.casting) {
    setCastActive(true);
    dispatch(startCasting());
  }

  if (!connected && castActive) {
    setCastActive(false);
    dispatch(stopCasting());
  }

  const getStreamId = (streamData): string | undefined => {
    if (streamData?.stream) {
      return streamData?.stream?.streamId;
    }
    if (streamData?.streams) {
      return streamData?.streams[0]?.streamId;
    }
    return undefined;
  };

  useEffect(() => {
    if (
      initialized &&
      connected &&
      streamInfo.status === "SUCCESS" &&
      !isLoading &&
      currentStreamId !== getStreamId(streamInfo.data)
    ) {
      setIsLoading(true);
      setCurrentStreamId(getStreamId(streamInfo.data));
      if (streamInfo.data.stream) {
        loadMedia(formatStreamToCast(streamInfo.data, castData.position)).then(
          () => {
            setIsLoading(false);
          }
        );
      } else if (streamInfo.data.streams) {
        loadCastQueue(streamInfo.data, castData.position, () =>
          setIsLoading(false)
        );
      } else {
        trackError("Failed to load cast media");
      }
    }
  }, [initialized, connected, streamInfo.status, isLoading, currentStreamId]);

  const getCastTitle = (): string => {
    if (isLoading) {
      return title && title !== "No title" ? `Laster ${title}` : "Laster...";
    }
    if (player?.playerState === "PLAYING" || player?.playerState === "PAUSED") {
      return title;
    }
    if (player?.playerState === "BUFFERING") {
      return `Laster ${title}`;
    }

    return "Klar til å caste";
  };

  if (!castData.casting) {
    return null;
  }

  return (
    <>
      <Padding />
      <Wrapper>
        <TopBar>
          <div>
            <CastTitle>{getCastTitle()}</CastTitle>
            <CastSubTitle>{subTitle || <>&nbsp;</>}</CastSubTitle>
          </div>
          <CastDeviceInfo />
        </TopBar>
        <Progressbar />
        <Controls />
      </Wrapper>
    </>
  );
}
