import styled from "styled-components";
import theme from "styles/theme";
import { MenuItem } from "types/types";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import { MenuItemType } from "./MobileMenuItem";

const Nav = styled.nav`
  display: flex;
  height: 5.8rem;
  padding-left: 4vw;
  padding-right: 4vw;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.245) 47.92%,
    rgba(0, 0, 0, 0.18) 77.08%,
    rgba(0, 0, 0, 0.07) 100%
  );
  background-blend-mode: darken;

  @media ${theme.newbp.medium} {
    padding-left: 4vw;
    padding-right: 4vw;
  }

  @media ${theme.newbp.huge} {
    padding-left: 4vw;
    padding-right: 4vw;
  }

  @media ${theme.newbp.enormous} {
    height: 8rem;
  }
`;

const loggedInMenuItems: MenuItem[] = [
  {
    title: "HJEM",
    url: "/hjem",
    trackingId: "home",
    activeIfExact: true,
  },
  {
    title: "TV",
    url: "/kanaler",
    trackingId: "live",
    activeIfExact: true,
  },
  {
    title: "TV-ARKIV",
    url: "/arkiv",
    trackingId: "archive",
    activeIfExact: true,
  },
  {
    title: "SERIER",
    url: "/serier",
    trackingId: "series",
    activeIfExact: true,
  },
  {
    title: "FILMER",
    url: "/filmer",
    trackingId: "movies",
    activeIfExact: true,
  },
  {
    title: "SPORT",
    url: "/sport",
    trackingId: "sport",
    activeIfExact: true,
  },
  {
    title: "BARN",
    url: "/barn",
    trackingId: "children",
    activeIfExact: true,
  },
  {
    title: "FILMBUTIKKEN",
    url: "/filmbutikken",
    trackingId: "payedMovieOverview",
    activeIfExact: true,
  },
  {
    title: "MITT INNHOLD",
    url: "/mittinnhold",
    trackingId: "myContent",
    activeIfExact: true,
  },

  // {
  //   title: "STRØMMETJENESTER",
  //   url: "/utforsk",
  //   trackingId: "explore",
  //   activeIfExact: true,
  // },
];

const publicMenuItems: MenuItem[] = [
  // {
  //   title: "TV",
  //   url: "/tv-guide",
  //   trackingId: "guide",
  //   activeIfExact: true,
  // },
  {
    title: "SERIER",
    url: "/serier",
    trackingId: "series",
    activeIfExact: true,
  },
  {
    title: "FILMER",
    url: "/filmer",
    trackingId: "movies",
    activeIfExact: true,
  },
  {
    title: "BARN",
    url: "/barn",
    trackingId: "children",
    activeIfExact: true,
  },
  {
    title: "FILMBUTIKKEN",
    url: "/filmbutikken",
    trackingId: "payedMovieOverview",
    activeIfExact: true,
  },
];

const secondMenuItems = [
  {
    title: "Søk",
    url: "/sok",
    trackingId: "search",
    icon: "search",
  },
  {
    title: "TV-guide",
    url: "/tv-guide",
    trackingId: "guide",
    icon: "tvguide",
  },
] as MenuItemType[];

type Props = {
  loggedIn?: boolean;
};

export default function Header({ loggedIn = true }: Props): JSX.Element {
  return (
    <Nav>
      <MobileHeader
        menuItems={loggedIn ? loggedInMenuItems : publicMenuItems}
        secondMenuItems={loggedIn ? secondMenuItems : []}
        loggedIn={loggedIn}
      />
      <DesktopHeader
        menuItems={loggedIn ? loggedInMenuItems : publicMenuItems}
        secondMenuItems={loggedIn ? secondMenuItems : []}
        loggedIn={loggedIn}
      />
    </Nav>
  );
}
