/* eslint-disable max-classes-per-file */
import * as legacyAuth from "auth/legacyAuth";
import * as oidcAuth from "auth/oidcAuth";
import { IAuthContext, RedirectSearchParams, StrategyType } from "auth/types";
import React, { useContext, useEffect, useMemo } from "react";
import { useLocalStorage } from "utils/ReactComponent";

type StrategySetter = (value: StrategyType) => void;

abstract class BaseAuth implements IAuthContext {
  protected abstract readonly type: StrategyType;

  readonly #strategySetter: StrategySetter;

  constructor(strategySetter: StrategySetter) {
    this.#strategySetter = strategySetter;
  }

  get strategy() {
    return this.type;
  }

  set strategy(value) {
    this.#strategySetter(value);
  }

  getAuthHeaders(this: void) {
    return {};
  }

  signinRedirectCallback(this: void) {
    return Promise.resolve({});
  }

  signoutRedirect(this: void) {
    return Promise.resolve();
  }

  signoutRedirectCallback(this: void) {
    return Promise.resolve({});
  }

  signinRedirect(this: void) {
    return Promise.resolve();
  }

  signinSilent(this: void) {
    return Promise.resolve();
  }

  signinSilentCallback(this: void) {
    return Promise.resolve();
  }
}

class VoidAuth extends BaseAuth {
  readonly type = StrategyType.VOID;
}

class LegacyAuth extends BaseAuth {
  readonly type = StrategyType.LEGACY;

  constructor(strategySetter) {
    super(strategySetter);
    super.strategy = this.type;
  }

  signinRedirect(this: void) {
    return legacyAuth.signinRedirect();
  }

  signoutRedirect(this: void) {
    super.strategy = StrategyType.OIDC;
    return legacyAuth.signoutRedirect();
  }
}

class OidcAuth extends BaseAuth {
  readonly type = StrategyType.OIDC;

  constructor(strategySetter) {
    super(strategySetter);
    super.strategy = this.type;
  }

  getAuthHeaders(this: void) {
    return oidcAuth.getAuthHeaders();
  }

  signinRedirectCallback(this: void) {
    return oidcAuth.signinRedirectCallback();
  }

  signoutRedirect(this: void, args?: RedirectSearchParams) {
    return oidcAuth.signoutRedirect(args);
  }

  signoutRedirectCallback(this: void) {
    return oidcAuth.signoutRedirectCallback();
  }

  signinRedirect(this: void, args?: RedirectSearchParams) {
    return oidcAuth.signinRedirect(args);
  }

  signinSilent(this: void) {
    return oidcAuth.signinSilent();
  }

  signinSilentCallback(this: void) {
    return oidcAuth.signinSilentCallback();
  }
}

const defaultAuthContext: IAuthContext = new VoidAuth(() => {
  throw new Error("Cannot set strategy; no AuthContext provided.");
});

export const AuthReactContext = React.createContext(defaultAuthContext);
AuthReactContext.displayName = "AuthContext";

export const AuthConsumer = AuthReactContext.Consumer;

type Props = {
  children: React.ReactNode;
};

// Workaround for function that can't use hooks
let authContext: IAuthContext = defaultAuthContext;
export function getAuthContext(): IAuthContext {
  return authContext;
}

export const STRATEGY_KEY = "no.teliaplay.auth.provider.strategy";

export function AuthProvider({ children }: Props): JSX.Element {
  if (useContext(AuthReactContext) !== defaultAuthContext) {
    throw new Error(`Nested ${AuthReactContext.displayName} are not supported`);
  }

  let defaultLoginStrategy = StrategyType.OIDC;

  if (window.location.hostname === "localhost") {
    defaultLoginStrategy = StrategyType.LEGACY;
  }

  const [strategy, setter] = useLocalStorage(
    STRATEGY_KEY,
    defaultLoginStrategy
  );

  useEffect(() => {
    setter(strategy);
  }, [strategy, setter]);

  // See comment above
  authContext = useMemo(() => {
    switch (strategy) {
      case StrategyType.OIDC:
        return new OidcAuth(setter);
      case StrategyType.LEGACY:
        return new LegacyAuth(setter);
      default:
        return new VoidAuth(setter);
    }
  }, [strategy, setter]);

  return (
    <AuthReactContext.Provider value={authContext}>
      {children}
    </AuthReactContext.Provider>
  );
}
