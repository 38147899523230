import {
  PlayerAPI,
  SubtitleEvent,
  SubtitleTrack,
  TimelineReferencePoint,
  VideoQuality,
  VideoQualityChangedEvent,
  ViewMode,
} from "bitmovin-player";
import { OffsetOptions, Stream, Subtitle } from "types/types";
import apiFetch from "utils/apiFetch";
import { getPreferredSubs } from "utils/localStorage";

const formatDrmInfo = (drmType) => ({
  LA_URL: drmType.url,
  headers: {
    [drmType.headers[0].name]: drmType.headers[0].value,
  },
});

function getPlayingTitle(title: string, caption: string) {
  if (caption) {
    return `${title} · ${caption}`;
  }
  return title;
}

function formatSubs(subtitles: Subtitle[]): Subtitle[] {
  if (subtitles) {
    return subtitles.map((sub, index) => ({
      ...sub,
      kind: "caption",
      lang: sub.language,
      id: `apiSub${index}`,
    }));
  }
  return [];
}

export function getPlaylist(streams: any[]): Stream[] {
  const playableStreams = streams.filter((stream) => !stream.errorMessages);

  return playableStreams.map((stream) => {
    const {
      subtitles,
      thumbnails,
      drmSettings,
      mediaStreams,
      title,
      caption,
      previousPosition,
      endCredits,
      streamId,
      entityId,
    } = stream;

    const drm = {
      widevine: formatDrmInfo(drmSettings.widevine),
      playready: formatDrmInfo(drmSettings.playready),
      fairplay: {
        ...formatDrmInfo(drmSettings.fairplay),
        certificateURL: drmSettings.fairplay.certificateUrl,
        prepareMessage: (event, session) =>
          `spc=${encodeURIComponent(event.messageBase64Encoded)}`,
        prepareContentId: (contentId) => {
          const startIndex = contentId.indexOf("skd://drmtoday");
          if (startIndex > -1) {
            return contentId.substring(startIndex);
          }
          return "";
        },
        useUint16InitData: true,
      },
    };

    const editedStreamObject: Stream = {
      dash: mediaStreams.dash,
      hls: mediaStreams.hls,
      drm,
      title: getPlayingTitle(title, caption),
      caption,
      previousPosition,
      endCredits,
      streamId,
      entityId,
      youbora: stream["youbora-v6"],
      subtitleTracks: formatSubs(subtitles),
      thumbnailTrack: {},
    };

    if (thumbnails) {
      editedStreamObject.thumbnailTrack = { url: thumbnails };
    }

    return editedStreamObject;
  });
}

export function getFullLanguageString(language: string): string {
  if (["nor", "no"].includes(language)) {
    return "Norsk";
  }
  if (["eng", "en-US", "en"].includes(language)) {
    return "Engelsk";
  }
  if (["swe", "sv"].includes(language)) {
    return "Svensk";
  }
  if (["dan", "da"].includes(language)) {
    return "Dansk";
  }
  if (["fin", "fi"].includes(language)) {
    return "Finsk";
  }
  return language;
}

export function setSubLabel(subtitle: SubtitleTrack): SubtitleTrack {
  subtitle.label = subtitle.label ? getFullLanguageString(subtitle.label) : "";

  if (subtitle?.role?.[0].value === "caption") {
    subtitle.label += " (for hørselshemmede)";
  }
  if (subtitle.label === "nor hoh") {
    subtitle.label = "Norsk (for hørselshemmede)";
  }

  return subtitle;
}

function formatVideoQuality(quality?: VideoQuality | null) {
  if (!quality) {
    return "undefined";
  }
  if (quality.id === "auto") {
    return "auto";
  }
  return `${quality.width}x${quality.height}`;
}

type QualityChangeType = {
  qualityBefore: string;
  qualityAfter: string;
};
export function getVideoQuality(
  qualityChangeEvent: VideoQualityChangedEvent
): QualityChangeType {
  return {
    qualityBefore: formatVideoQuality(qualityChangeEvent.sourceQuality),
    qualityAfter: formatVideoQuality(qualityChangeEvent.targetQuality),
  };
}

export function trackWatchPosition(streamId: string, position: number): void {
  apiFetch(`api/watch/position/stream/${streamId}`, {
    method: "POST",
    body: JSON.stringify({ position }),
  });
}

// Used by backend to calculate popularity
export function trackWatchPeriod(
  streamId: string,
  currentWatchPeriod: number,
  offset?: number
): void {
  if (currentWatchPeriod > 0) {
    apiFetch(`api/watch/period/stream/${streamId}`, {
      method: "POST",
      body: JSON.stringify({
        length: Math.floor(currentWatchPeriod),
        offset: offset ? Math.floor(offset) : 0,
      }),
    });
  }
}

export function handleSubtitleAdded(
  player: PlayerAPI,
  event: SubtitleEvent
): void {
  /*
   For content which is not live, we do not wish to use the subtitles
   from the hls manifest, which the player automatically tries to load.
   When loading the subs from both the api and the manifest
   the player generates problems in Safari.
  */
  if (
    !player.isLive() &&
    player.getStreamType() === "hls" &&
    !event.subtitle.id.includes("apiSub")
  ) {
    player.subtitles.remove(event.subtitle.id);
  } else if (event.subtitle.label === getPreferredSubs()) {
    player.subtitles.enable(event.subtitle.id, true);
  }
}

function getOffsetOptions(offset: number | false): OffsetOptions {
  return offset
    ? {
        startOffsetTimelineReference: TimelineReferencePoint.Start,
        startOffset: offset,
      }
    : {};
}

export function formatCurrentStream(
  stream: Stream,
  offset: number | false
): Stream {
  const offsetOptions = getOffsetOptions(offset);

  return {
    ...stream,
    options: offsetOptions,
  };
}

export function seekBack(player: PlayerAPI, seconds = 15): void {
  if (player.isLive()) {
    player.timeShift(
      Math.max(player.getTimeShift() - seconds, player.getMaxTimeShift())
    );
  } else {
    player.seek(player.getCurrentTime() - seconds);
  }
}

export function seekForward(player: PlayerAPI, seconds = 15): void {
  if (player.isLive() && player.getTimeShift() < 0) {
    player.timeShift(Math.min(player.getTimeShift() + seconds, 0));
  } else {
    player.seek(player.getCurrentTime() + seconds);
  }
}

export const findActiveSub = (player: PlayerAPI): string => {
  const subs = player.subtitles?.list();
  const activeSub = subs?.find((sub) => sub.enabled);

  return activeSub ? activeSub.id : "";
};

const toggleSubs = (player: PlayerAPI) => {
  if (findActiveSub(player)) {
    player.subtitles.disable(findActiveSub(player));
  } else {
    const subs = player.subtitles.list();

    if (subs.length > 0) {
      const nor = subs.find((sub) => sub.lang === "nor");
      const eng = subs.find((sub) => sub.lang === "eng");

      if (nor) {
        player.subtitles.enable(nor.id, true);
      } else if (eng) {
        player.subtitles.enable(eng.id, true);
      } else {
        player.subtitles.enable(subs[0].id, true);
      }
    }
  }
};

const seekToPercentage = (player: PlayerAPI, percentage: number) => {
  player.seek(player.getSeekableRange().end * (percentage / 100));
};

export function handleKeyboardEvent(e: KeyboardEvent, player: PlayerAPI): void {
  if (e.ctrlKey || e.metaKey || e.altKey) {
    return;
  }
  switch (e.code) {
    case "Escape":
      e.preventDefault();
      player.setViewMode(ViewMode.Inline);
      break;
    case "KeyK":
    case "Space":
      e.preventDefault();
      if (player.isPlaying()) {
        player.pause();
      } else {
        player.play();
      }
      break;
    case "ArrowLeft":
    case "KeyJ":
      e.preventDefault();
      seekBack(player);
      break;
    case "ArrowRight":
    case "KeyL":
      e.preventDefault();
      seekForward(player);
      break;
    case "ArrowUp":
      e.preventDefault();
      player.setVolume(player.getVolume() + 10);
      break;
    case "ArrowDown":
      e.preventDefault();
      // setVolume handles negative numbers badly
      player.getVolume() - 10 < 0
        ? player.setVolume(0)
        : player.setVolume(player.getVolume() - 10);
      break;
    case "KeyC":
      e.preventDefault();
      toggleSubs(player);
      break;
    case "KeyF":
      e.preventDefault();
      if (player.getViewMode() === ViewMode.Inline) {
        player.setViewMode(ViewMode.Fullscreen);
      } else {
        player.setViewMode(ViewMode.Inline);
      }
      break;
    case "KeyM":
      e.preventDefault();
      player.isMuted() ? player.unmute() : player.mute();
      break;
    case "Digit1":
    case "Digit2":
    case "Digit3":
    case "Digit4":
    case "Digit5":
    case "Digit6":
    case "Digit7":
    case "Digit8":
    case "Digit9":
      e.preventDefault();
      !player.isLive() && seekToPercentage(player, parseInt(e.key, 10) * 10);
      break;
    default:
      break;
  }
}

const customSort = (x: VideoQuality, y: VideoQuality) => {
  if (x.height < y.height) {
    return 1;
  }
  if (x.height > y.height) {
    return -1;
  }
  if (x.bitrate < y.bitrate) {
    return 1;
  }
  if (x.bitrate > y.bitrate) {
    return -1;
  }
  return 0;
};

export const filterVideoQualityList = (
  qualities: VideoQuality[]
): VideoQuality[] => {
  const heights: number[] = [];

  const sortedQualities = qualities.sort(customSort).filter((quality) => {
    if (heights.includes(quality.height)) {
      return false;
    }
    heights.push(quality.height);
    return true;
  });

  return sortedQualities;
};
