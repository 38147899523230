function createSymbols(entity) {
  return {
    REQUEST: Symbol(`Fetch ${entity} request`),
    SUCCESS: Symbol(`Fetch ${entity} success`),
    UPDATE: Symbol(`Fetch ${entity} update`),
    ERROR: Symbol(`Fetch ${entity} error`),
  };
}

export const CHANNEL_EPG_DAILY = createSymbols("Channel EPG daily");
export const CHANNEL_EPG_UNTIL_DAYS = createSymbols("Channel EPG until days");
export const CHANNELS = createSymbols("Channels");
export const CONTINUE_WATCHING_FROM_ARCHIVE = createSymbols(
  "Continue watching assets from archive for channel"
);
export const CONTINUE_WATCHING_KIDS_FROM_ARCHIVE = createSymbols(
  "Continue watching kids assets from archive for channel"
);
export const EDITORIAL_COLLECTIONS = createSymbols("Editorial collections");
export const EDITORIAL_PROMOTIONS = createSymbols("Editorial promotions");
export const FAVOURITES = createSymbols("Favourites my list");
export const KIDS = createSymbols("Kids");
export const LIVE_EPG = createSymbols("Live EPG");
export const MEDIA_DETAILS = createSymbols("Media Details");
export const MOVIES = createSymbols("Movies");
export const POPULAR_FROM_ARCHIVE = createSymbols(
  "Popular assets from archive"
);
export const POPULAR_LIVE_EPG = createSymbols("Popular live EPG");
export const PROGRAM = createSymbols("Program");
export const PROVIDERS = createSymbols("Providers overview");
export const QUOTA = createSymbols("Recordings quota for user");
export const RECOMMENDED_MOVIES_FOR_USER = createSymbols(
  "Movies recommended for user"
);
export const RECOMMENDED_SERIES_FOR_USER = createSymbols(
  "Series recommended for user"
);
export const RECORDINGS = createSymbols("Recordings");
export const SCHEDULED_RECORDINGS = createSymbols("Scheduled npvr recordings");
export const SEARCH = createSymbols("Search");
export const SERIES_DETAILS = createSymbols("Series Details");
export const SERIES_NPVR_OPTIONS = createSymbols("Series nPVR options");
export const SERIES = createSymbols("TV Series");
export const SHOWS = createSymbols("TV Shows");
export const SOURCES = createSymbols("Sources");
export const STREAM_INFORMATION = createSymbols("Stream Information");
export const TVOD_TRANSACIONS = createSymbols("Tvod transactions");
export const TVOD = createSymbols("Tvod");
export const USER_DATA = createSymbols("User data");
export const USER_PROFILE = createSymbols("User profile");
export const TV_GUIDE_EPG = createSymbols("TV guide epg");
export const COLLECTIONS = createSymbols("collections");
