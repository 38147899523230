import { fetchEpgForChannelId } from "ducks/epg/epgAsyncForChannel";
import { CHANNELS as FETCH } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import { createSelector } from "reselect";
import apiFetch from "utils/apiFetch";

const ADD = Symbol("Add channel as favourite");
const REMOVE = Symbol("Remove channal as favourite");

const initialState = {
  status: REQ.INIT,
  data: [],
};

export const sortChannelsByIndex = (a, b) => {
  const aCustomerIndex = a.customerIndex || Infinity;
  const bCustomerIndex = b.customerIndex || Infinity;

  if (aCustomerIndex < bCustomerIndex) {
    return -1;
  }
  if (aCustomerIndex > bCustomerIndex) {
    return 1;
  }

  return a.defaultIndex - b.defaultIndex;
};

const sortOnSubscribing = (a, b) => b.subscribing - a.subscribing;

export default function channelsAsync(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH.REQUEST:
      return {
        status: REQ.PENDING,
      };

    case FETCH.SUCCESS:
      return {
        status: REQ.SUCCESS,
        data: payload.sort(sortChannelsByIndex).sort(sortOnSubscribing),
      };

    case FETCH.ERROR:
      return {
        status: REQ.ERROR,
      };

    case ADD:
      return {
        status: state.status,
        data: state.data
          .map((channel) => {
            if (channel.id === payload.channelId) {
              channel.customerIndex = 1;
              return channel;
            }
            return channel;
          })
          .sort(sortChannelsByIndex),
      };

    case REMOVE:
      return {
        status: state.status,
        data: state.data
          .map((channel) => {
            if (channel.id === payload.channelId) {
              channel.customerIndex = 0;
              return channel;
            }
            return channel;
          })
          .sort(sortChannelsByIndex),
      };

    default:
      return state;
  }
}

const getChannels = (state) => state.channelsAsync.data;

export const getChannelByName = (state, channelName) => {
  const channels = getChannels(state);

  return (
    channels && channels.find((channel) => channel.simpleName === channelName)
  );
};

export const getChannelIdByName = (state, channelName) =>
  (getChannelByName(state, channelName) || {}).id;

export const isChannelUrlInvalid = (state, channelName) =>
  createSelector(
    getChannels,
    () => getChannelByName(state, channelName),
    (channels, currentChannel) => channels && !currentChannel
  )(state);

export const isFavouriteChannel = (state, channelName) => {
  const channel = getChannelByName(state, channelName);

  return !!(channel && channel.customerIndex);
};

export const getSubscribedChannels = createSelector(
  getChannels,
  (channels) => channels && channels.filter((channel) => channel.subscribing)
);

export const getWatchableSubscribedChannels = createSelector(
  getChannels,
  (channels) =>
    channels &&
    channels
      .filter((channel) => channel.watchable)
      .filter((channel) => channel.subscribing)
);

export const getSubscribedFavouritesBucket = createSelector(
  getSubscribedChannels,
  (channels) =>
    channels &&
    channels.reduce(
      (buckets, channel) => {
        if (channel.customerIndex) {
          buckets.favourite.push(channel);
        } else {
          buckets.rest.push(channel);
        }
        return buckets;
      },
      {
        favourite: [],
        rest: [],
      }
    )
);

export const getFavouriteChannelSimpleNames = createSelector(
  getChannels,
  (channels) =>
    channels &&
    channels
      .filter((channel) => channel.customerIndex && channel.customerIndex > 0)
      .map((channel) => channel.simpleName)
);

function fetchDataForChannel(channel) {
  return (dispatch) => {
    const { id } = channel;

    dispatch(fetchEpgForChannelId(id));
  };
}

export function fetchChannels() {
  return (dispatch) => {
    dispatch({ type: FETCH.REQUEST });

    return apiFetch("/api/channels/sorted/all")
      .catch((error) => {
        dispatch({ type: FETCH.ERROR, payload: error });

        throw error;
      })
      .then((result) => {
        dispatch({ type: FETCH.SUCCESS, payload: result });

        return result;
      });
  };
}

export function syncDataForChannel(channel) {
  return (dispatch) => {
    if (!channel) {
      throw Error("Error in syncDataForChannel, channel was not provided.");
    }

    dispatch(fetchDataForChannel(channel));
  };
}

export function addFavouriteChannel(channelId) {
  return (dispatch) => {
    dispatch({ type: ADD, payload: { channelId } });

    return apiFetch(`/api/channels/favourite/${channelId}`, {
      method: "POST",
      body: JSON.stringify({ position: 1 }),
    });
  };
}

export function removeFavouriteChannel(channelId) {
  return (dispatch) => {
    dispatch({ type: REMOVE, payload: { channelId } });

    return apiFetch(`/api/channels/favourite/${channelId}`, {
      method: "DELETE",
      body: "{}",
    });
  };
}
