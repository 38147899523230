import { CastControllerType } from "ducks/castController";
import CastContext from "hooks/cast/CastContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

type RootState = {
  castController: CastControllerType;
};

interface ChromecastProviderProps extends Partial<cast.framework.CastOptions> {
  children?: JSX.Element | JSX.Element[];
  autoJoinPolicy?: chrome.cast.AutoJoinPolicy;
}

function ChromecastProvider({
  children,
  receiverApplicationId,
  autoJoinPolicy = chrome?.cast?.AutoJoinPolicy?.ORIGIN_SCOPED ||
    "origin_scoped",
  language,
  resumeSavedSession,
}: ChromecastProviderProps): JSX.Element {
  const [connected, setConnected] = useState<boolean>(false);
  const [deviceName, setDeviceName] = useState<string>("");
  const [player, setPlayer] = useState<cast.framework.RemotePlayer | null>(
    null
  );
  const [playerController, setPlayerController] =
    useState<cast.framework.RemotePlayerController | null>(null);
  const castInitialized: boolean = useSelector(
    (state: RootState) => state.castController.castEnabledDevice
  );

  const resetCast = useCallback(() => {
    setConnected(false);
    setDeviceName("");
  }, []);

  /* onCast Initalized */
  useEffect(() => {
    const onSessionStateChange = (
      data: cast.framework.SessionStateEventData
    ) => {
      if (
        data.sessionState ===
          window.cast.framework.SessionState.SESSION_RESUMED ||
        data.sessionState === window.cast.framework.SessionState.SESSION_STARTED
      ) {
        setConnected(true);
      }
      if (
        data.sessionState === window.cast.framework.SessionState.SESSION_ENDED
      ) {
        resetCast();
        setConnected(false);
      }
    };

    if (window.chrome && window.chrome.cast && window.cast) {
      window.cast.framework.CastContext.getInstance().setOptions({
        receiverApplicationId,
        resumeSavedSession,
        autoJoinPolicy,
        language,
      });
      const player = new window.cast.framework.RemotePlayer();

      setPlayer(player);
      setPlayerController(
        new window.cast.framework.RemotePlayerController(player)
      );

      window.cast.framework.CastContext.getInstance().addEventListener(
        window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        onSessionStateChange
      );
    }
  }, [
    autoJoinPolicy,
    castInitialized,
    language,
    receiverApplicationId,
    resetCast,
    resumeSavedSession,
  ]);

  useEffect(() => {
    const onConnectedChange = (
      _data: cast.framework.RemotePlayerChangedEvent
    ) => {
      if (_data.value) {
        setConnected(true);
        const session =
          window.cast.framework.CastContext.getInstance().getCurrentSession();

        if (session) {
          setDeviceName(session.getSessionObj().receiver.friendlyName);
        }
      } else {
        setConnected(false);
      }
    };

    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
        onConnectedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
          onConnectedChange
        );
      }
    };
  }, [playerController]);

  const value = useMemo(() => {
    const value = {
      connected,
      initialized: castInitialized,
      deviceName,
      player,
      playerController,
    };

    return value;
  }, [castInitialized, connected, deviceName, player, playerController]);

  return <CastContext.Provider value={value}>{children}</CastContext.Provider>;
}

export default ChromecastProvider;
