import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function useScrollRestoration(): JSX.Element | null {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (history.action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
