import { STREAM_INFORMATION as FETCH } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import apiFetch from "utils/apiFetch";

const RESET = Symbol("reset stream information");

const initialState = {
  status: REQ.INIT,
};

export type StreamInformationAsyncType = {
  status: string;
  data: any;
  error: Error;
  errorInfoObject: any;
};

export default function streamInformationAsync(state = initialState, action) {
  const { type, payload, errorInfoObject } = action;

  switch (type) {
    case RESET:
      return initialState;

    case FETCH.REQUEST:
      return {
        status: REQ.PENDING,
      };

    case FETCH.SUCCESS:
      return {
        status: REQ.SUCCESS,
        data: payload,
      };

    case FETCH.ERROR:
      return {
        status: REQ.ERROR,
        error: payload,
        errorInfoObject,
      };

    default:
      return state;
  }
}

export function fetchAssetStreamInformation(assetId) {
  return function (dispatch) {
    dispatch({ type: FETCH.REQUEST });

    apiFetch(`api/stream/asset/${assetId}`).then(
      (result) => {
        dispatch({ type: FETCH.SUCCESS, payload: result });
      },
      (non2xxResponseError) => {
        dispatch({
          type: FETCH.ERROR,
          payload: non2xxResponseError,
          errorInfoObject: non2xxResponseError.errorInfoObject,
        });
      }
    );
  };
}

function fixData(payload) {
  payload.streams = payload.streams.filter(
    (stream) => stream.mediaStreams && !stream.errorMessages
  );
  return payload;
}

export function fetchAssetsStreamInformation(assetId) {
  return function (dispatch) {
    dispatch({ type: FETCH.REQUEST });

    apiFetch(`api/stream/assets/from/${assetId}`).then(
      (result) => {
        dispatch({ type: FETCH.SUCCESS, payload: fixData(result) });
      },
      (non2xxResponseError) => {
        dispatch({
          type: FETCH.ERROR,
          payload: non2xxResponseError,
          errorInfoObject: non2xxResponseError.errorInfoObject,
        });
      }
    );
  };
}

export function fetchChannelStreamInformation(channelId) {
  return function (dispatch) {
    dispatch({ type: FETCH.REQUEST });

    apiFetch(`api/stream/channel/${channelId}`).then(
      (result) => {
        dispatch({ type: FETCH.SUCCESS, payload: result });
      },
      (non2xxResponseError) => {
        dispatch({ type: FETCH.ERROR, payload: non2xxResponseError });
      }
    );
  };
}

export function resetStreamInformation() {
  return {
    type: RESET,
  };
}
