/* eslint-disable react/no-multi-comp */
import "3rd-party-scripts";

import "@reach/listbox/styles.css";
import "normalize.css";
import "style.css";
// ↑↑↑ These should be at the top

import {
  LOGIN_RETURN_PATHNAME,
  LOGIN_SILENT_PATHNAME,
  LOGOUT_RETURN_PATHNAME,
} from "auth/constants";
import { useAuthContext, useAuthentication } from "auth/hooks";
import { ssoCheckRedirect } from "auth/legacyAuth";
import { StrategyType } from "auth/types";
import { AuthProvider } from "components/auth/AuthProvider";
import CastProvider from "components/cast-controlbar/CastProvider/CastProvider";
import GenericError from "components/error/GenericError";
import SubscriptionError from "components/error/SubscriptionError";
import Spinner from "components/spinner/Spinner";
import { differenceInMinutes } from "date-fns";
import { syncTimestamp } from "ducks/currentTime";
import { fetchUserData } from "ducks/userSpecificData/userData";
import REQ from "enums/requestStatus";
import useCustomerData from "hooks/useCustomerData";
import LegacyLogin from "pages/login/LegacyLogin";
import Login from "pages/login/Login";
import Logout from "pages/logout/Logout";
import PublicRoutes from "publicRoutes";
import { lazy, Suspense, useCallback, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Routes from "routes";
import store from "store";
import RootStyle from "styles/RootStyle";
import { SWRConfig } from "swr";
import { UserData, UserDataAsync } from "types/types";
import fetcher from "utils/apiSWRFetcher";
import { getSelectedCustomerId, setSelectedCustomerId } from "utils/customerId";
import {
  useReduxActions,
  useReduxSelector,
  useValue,
} from "utils/ReactComponent";
import { SeoContext } from "utils/SEO";
import { identifyUserMixpanel, updateTriggerAction } from "utils/tracking";

const LoginReturn = lazy(() => import("pages/login/LoginReturn"));
const LoginSilent = lazy(() => import("pages/login/LoginSilent"));
const LogoutReturn = lazy(() => import("pages/logout/LogoutReturn"));

type ReduxState = Readonly<{
  userDataAsync: UserDataAsync;
}>;

type ReduxDispatch = Readonly<{
  fetchUserData: () => Promise<UserData>;
  syncTimestamp: () => void;
}>;

type Fields = {
  isRedirecting: boolean;
};

function Root(): JSX.Element {
  const redux = useReduxSelector<ReduxState>((state) => ({
    userDataAsync: state.userDataAsync,
  }));
  const reduxDispatch = useReduxActions<ReduxDispatch>({
    fetchUserData,
    syncTimestamp,
  });
  const fields = useValue<Fields>({ isRedirecting: false });
  const { strategy } = useAuthContext();
  const isAuthenticated = useAuthentication();
  const { isCrawler } = useContext(SeoContext);
  const customers = useCustomerData(!!isAuthenticated);

  const updateLegacyUserData = useCallback(
    () =>
      reduxDispatch.fetchUserData().then((userData) => {
        if (userData?.isAuthenticated) {
          reduxDispatch.syncTimestamp();
          identifyUserMixpanel();
        }
      }),
    [reduxDispatch]
  );

  const { data: userData, status } = redux.userDataAsync;

  const willUserNeedSelfRecovery = useCallback(() => {
    if (strategy === StrategyType.OIDC && isAuthenticated && customers) {
      return !customers.some((customer) => customer.hasTvProducts);
    }
    return false;
  }, [strategy, isAuthenticated, customers]);

  useEffect(() => {
    if (strategy === StrategyType.LEGACY) {
      updateLegacyUserData();
    }
  }, [strategy, updateLegacyUserData]);

  useEffect(() => {
    const selectedCustomerId = getSelectedCustomerId();
    if (
      customers &&
      (selectedCustomerId === null ||
        !customers?.find(
          (customer) => customer.customerId === selectedCustomerId
        )?.hasTvProducts)
    ) {
      setSelectedCustomerId(
        customers?.find((customer) => customer.hasTvProducts === true)
          ?.customerId ?? 0
      );
    }
  }, [customers]);

  useEffect(() => {
    if (
      strategy === StrategyType.OIDC &&
      isAuthenticated !== undefined &&
      status === REQ.INIT
    ) {
      updateLegacyUserData();
    }
  }, [strategy, isAuthenticated, status, updateLegacyUserData]);

  if (
    [REQ.INIT, REQ.PENDING].includes(status) ||
    fields.isRedirecting ||
    isAuthenticated === undefined
  ) {
    return <Spinner fullPage />;
  }

  if (willUserNeedSelfRecovery()) {
    return <SubscriptionError />;
  }

  if (status === REQ.ERROR || !userData) {
    <GenericError title="Error while fetching userData" />;
  }

  if (
    !isAuthenticated &&
    strategy === StrategyType.LEGACY &&
    window.location.hostname !== "localhost" &&
    !isCrawler
  ) {
    const lastSsoCheck = window.sessionStorage.getItem("last_sso_check");
    const now = new Date();
    const lastSsoCheckAsDate =
      (lastSsoCheck &&
        !Number.isNaN(parseInt(lastSsoCheck, 10)) &&
        new Date(parseInt(lastSsoCheck, 10))) ||
      new Date(0);

    if (differenceInMinutes(now, lastSsoCheckAsDate) > 5) {
      window.sessionStorage.setItem("last_sso_check", now.valueOf().toString());
      fields.isRedirecting = true;
      ssoCheckRedirect();
      return <Spinner fullPage />;
    }
  }

  if (!isAuthenticated) {
    // ##############################
    // NOTE: Keep sitemap.xml updated
    // ##############################
    return <PublicRoutes />;
  }

  return <Routes />;
}

function App(): JSX.Element {
  const backbuttonListener = () => {
    updateTriggerAction({});
  };

  useEffect(() => {
    window.addEventListener("popstate", backbuttonListener);

    return () => {
      window.removeEventListener("popstate", backbuttonListener);
    };
  });

  return (
    <Provider store={store}>
      <SWRConfig value={{ fetcher, revalidateOnFocus: false }}>
        <RootStyle />
        <AuthProvider>
          <BrowserRouter>
            <Suspense fallback={<Spinner fullPage />}>
              <Switch>
                <Route
                  exact
                  path={LOGIN_RETURN_PATHNAME}
                  component={LoginReturn}
                />
                <Route
                  exact
                  path={LOGIN_SILENT_PATHNAME}
                  component={LoginSilent}
                />
                <Route
                  exact
                  path={LOGOUT_RETURN_PATHNAME}
                  component={LogoutReturn}
                />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/legacy-login" component={LegacyLogin} />
                <Route path="/">
                  <CastProvider>
                    <Root />
                  </CastProvider>
                </Route>
              </Switch>
            </Suspense>
          </BrowserRouter>
        </AuthProvider>
      </SWRConfig>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("app"));
