import { Location } from "history";
import { useLocation } from "react-router-dom";
import { mapClientToServerSort } from "./AssetSorting";

export function getSortParams(location: Location): string {
  const searchParams = new URLSearchParams(location?.search);

  return mapClientToServerSort(searchParams.get("sorter"));
}

export function getGenreParams(location: Location): string {
  const searchParams = new URLSearchParams(location?.search);
  const genre = searchParams.get("genre");

  return genre || "";
}

export function getSourceParams(location: Location): string {
  const searchParams = new URLSearchParams(location?.search);
  const source = searchParams.get("source");

  return source || "";
}

export function useSearchQuery(): string {
  const searchParams = new URLSearchParams(useLocation()?.search);
  const q = searchParams.get("q");

  return q || "";
}
