import {
  getPreferredSubsContrast,
  getPreferredSubsSize,
  setPreferredSubsContrast,
  setPreferredSubsSize,
} from "utils/localStorage";

export type SubtitleSize = "small" | "medium" | "large";

export type SubtitlePreferenceType = {
  size: SubtitleSize;
  highContrast: boolean;
};

const initialState: SubtitlePreferenceType = {
  size: getPreferredSubsSize(),
  highContrast: getPreferredSubsContrast(),
};

export default function subtitlePreference(state = initialState, action) {
  const { type, data } = action;

  switch (type) {
    case "setSubSize":
      setPreferredSubsSize(data.size);
      return {
        ...state,
        size: data.size,
      };
    case "setSubsHighContrast":
      setPreferredSubsContrast(data.highContrast);
      return {
        ...state,
        highContrast: data.highContrast,
      };
    default:
      return state;
  }
}

export function setSubSize(size: SubtitleSize) {
  return function (dispatch) {
    dispatch({
      type: "setSubSize",
      data: { size },
    });
  };
}

export function setSubsHighContrast(highContrast: boolean) {
  return function (dispatch) {
    dispatch({
      type: "setSubsHighContrast",
      data: { highContrast },
    });
  };
}
