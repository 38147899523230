declare const API_HOST: string;

type Environments = "production" | "qa" | "test" | "development";

export default function getEnvironment(): Environments {
  const { hostname } = window.location;

  if (hostname === "teliaplay.no" || hostname === "legacy.teliaplay.no") {
    return "production";
  }
  if (hostname === "qa.teliaplay.no" || hostname === "legacy.qa.teliaplay.no") {
    return "qa";
  }
  if (process && process.env && process.env.NODE_ENV === "test") {
    return "test";
  }
  if (hostname.includes("localhost")) {
    return "development";
  }

  return "development";
}

export type ApiHost = "prod" | "qa";

export function getApiHost(): ApiHost {
  return API_HOST === "prod" ? "prod" : "qa";
}

export type IOidcConfig = Readonly<{
  identityAuthority: string;
}>;

const environmentConfigForPROD: IOidcConfig = {
  identityAuthority: "https://identity.telia.no/realms/telia/",
};

const environmentConfigForStaging: IOidcConfig = {
  identityAuthority: "https://stage.identity.telia.no/realms/telia/",
};

export function getOidcConfig(): IOidcConfig {
  // const apiHost = getApiHost();
  const env = getEnvironment();
  switch (env) {
    case "production":
      return environmentConfigForPROD;
    case "qa":
      return environmentConfigForStaging;
    default:
      return environmentConfigForStaging;
    // throw new Error(`Cannot find config for API host "${apiHost}"`);
  }
}
