import { User } from "oidc-client-ts";
import { CustomerData } from "types/types";

export type PartyId = string;

export enum RecoveryStatus {
  SUCCESS = "success",
  ABORTED = "aborted",
  ERROR = "error",
}

export type RedirectSearchParams = Readonly<{
  returnPath?: string;
}>;

export type RedirectCallbackSearchParams = RedirectSearchParams &
  Readonly<{
    recoveryStatus?: RecoveryStatus;
    error?: string;
    errorDescription?: string;
  }>;

export type ScopeProps = Readonly<{
  customerData?: CustomerData;
  partyId?: PartyId;
}>;

export type UserWithScopes = User & {
  profile: User["profile"] & ScopeProps;
};

export { UserWithScopes as OIDCUser };

export type AuthHeaders = Readonly<{
  [x: string]: string;
}>;

export const enum StrategyType {
  LEGACY = "legacy",
  OIDC = "oidc",
  VOID = "void",
}

export type IAuthContext = {
  strategy: StrategyType;
} & Readonly<{
  getAuthHeaders(this: void): AuthHeaders;
  signinRedirect(this: void, args?: RedirectSearchParams): Promise<void>;
  signinRedirectCallback(this: void): Promise<RedirectCallbackSearchParams>;
  signoutRedirect(this: void, args?: RedirectSearchParams): Promise<void>;
  signoutRedirectCallback(this: void): Promise<RedirectCallbackSearchParams>;
  signinSilent(this: void): Promise<void>;
  signinSilentCallback(this: void): Promise<void>;
}>;
