import { SearchHit } from "types/types";

export const CACHED_SCHEMA_VERSION = "2";

export const isDifferentHit = (hit1: SearchHit, hit2: SearchHit): boolean =>
  hit1.CACHED_SCHEMA_VERSION !== hit2.CACHED_SCHEMA_VERSION ||
  hit1.to !== hit2.to;

const encodeHits = (hits: { hits: SearchHit[] }): string =>
  encodeURIComponent(JSON.stringify(hits));

const decodeHits = (hits: string): { hits: SearchHit[] } =>
  JSON.parse(decodeURIComponent(hits));

export function addSearchHit(value: SearchHit): void {
  const cookie = window.localStorage.getItem("searchHits");
  let prevHits: { hits: SearchHit[] } = { hits: [] };

  if (cookie !== null) {
    prevHits = decodeHits(cookie);
  }

  const hitsWithoutDuplicates = prevHits.hits.filter((hit) =>
    isDifferentHit(hit, value)
  );

  hitsWithoutDuplicates.unshift(value);

  window.localStorage.setItem(
    "searchHits",
    encodeHits({ hits: hitsWithoutDuplicates.slice(0, 6) })
  );
}

export function getSearchHits(): { hits: SearchHit[] } {
  const hits = window.localStorage.getItem("searchHits");

  if (hits !== null) {
    return decodeHits(hits);
  }
  return { hits: [] };
}

export function deleteSearchHits(): void {
  window.localStorage.setItem("searchHits", encodeHits({ hits: [] }));
}

function clamp(num: number, min: number, max: number): number {
  // eslint-disable-next-line no-nested-ternary
  return num <= min ? min : num >= max ? max : num;
}

export function setStoredVolume(volume: number): void {
  window.localStorage.setItem("playerVolume", clamp(volume, 0, 100).toString());
}

export function getStoredVolume(): number {
  const volume = window.localStorage.getItem("playerVolume");

  return volume === null || volume === undefined ? 100 : parseInt(volume, 10);
}

export function setPreferredSubs(sub: string): void {
  window.localStorage.setItem("preferredSubs", sub);
}

export function getPreferredSubs(): string {
  const sub = window.localStorage.getItem("preferredSubs");

  return sub === null || sub === undefined ? "Norsk" : sub;
}

export function setPreferredVideoQuality(videoQuality: string): void {
  window.localStorage.setItem("preferredVideoQuality", videoQuality);
}

export function getPreferredVideoQuality(): string {
  const videoQuality = window.localStorage.getItem("preferredVideoQuality");

  return videoQuality === null || videoQuality === undefined
    ? "auto"
    : videoQuality;
}

export function setPreferredAudioLanguage(audioLanguage: string): void {
  window.localStorage.setItem("preferredAudioLanguage", audioLanguage);
}

export function getPreferredAudioLanguage(): string {
  const audioLanguage = window.localStorage.getItem("preferredAudioLanguage");

  return audioLanguage === null || audioLanguage === undefined
    ? "nor"
    : audioLanguage;
}

export function setPreferredSubsSize(subsSize: string): void {
  window.localStorage.setItem("preferredSubsSize", subsSize);
}

export function getPreferredSubsSize(): "small" | "medium" | "large" {
  const subsSize = window.localStorage.getItem("preferredSubsSize");

  if (subsSize === "small" || subsSize === "medium" || subsSize === "large") {
    return subsSize;
  }

  return "medium";
}

export function setPreferredSubsContrast(highContrast: boolean): void {
  window.localStorage.setItem(
    "preferredSubsContrast",
    highContrast ? "true" : "false"
  );
}

export function getPreferredSubsContrast(): boolean {
  const highContrast = window.localStorage.getItem("preferredSubsContrast");

  return highContrast === "true";
}
