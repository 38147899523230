import Arrow from "@teliaplay/arrow";
import Icon from "@teliaplay/icon";
import LinkButton from "@teliaplay/linkbutton";
import { useAuthContext, useOIDCUser } from "auth/hooks";
import useSignout from "auth/hooks/useSignout";
import { StrategyType } from "auth/types";
import TeliaLink from "components/helpers/TeliaLink";
import useCustomerData from "hooks/useCustomerData";
import { UserProfileData } from "pages/ProfilePage";
import { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "styles/theme";
import useSWR from "swr";
import { getSelectedCustomerId } from "utils/customerId";
import { channelPickerPath, profileOverviewLink } from "utils/URLUtil";
import { LegacyProfileItem, OIDCProfileItem } from "./ProfileItem";

const Link = styled(TeliaLink)`
  color: ${theme.color.grey900};
  font-size: 2rem;
  font-weight: bold;
  padding-right: 0.2rem;
  padding-bottom: 0.2rem;

  :hover {
    color: ${theme.color.corePurple400};
    border-bottom: 2px solid ${theme.color.corePurple400};
    text-decoration: none;
  }
`;

const Divider = styled.hr`
  margin-top: 0px;
  border-top-width: 0;
  margin-bottom: 0;
  color: ${theme.color.grey200};
`;

const LinkMargin = styled.div`
  margin-top: 1.5rem;
`;

const Bold = styled.div`
  font-weight: bold;
`;

const IconStyled = styled(Icon)`
  height: 2rem;
  margin-right: 1.4rem;
  margin-bottom: 0.4rem;
  vertical-align: middle;
  display: inline-block;
`;

const Wrapper = styled.li`
  position: relative;
  max-width: 100%;
`;

const ProfileInfo = styled.div<{
  multipleProfiles?: boolean;
  selected?: boolean;
  selectable?: boolean;
}>`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  color: ${(props) =>
    props.selected ? theme.color.corePurple400 : theme.color.grey900};
  font-size: 1.6rem;
  line-height: 2rem;

  :hover {
    cursor: ${(props) =>
      !props.selected && props.selectable ? "pointer" : "auto"};
    color: ${(props) =>
      props.selectable ? theme.color.corePurple400 : theme.color.grey900};
  }
`;

const LogoutButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  border-top: 1px solid ${theme.color.grey300};
`;

const AvatarIcon = styled(Icon)`
  height: 2rem;
  width: 2rem;
  margin-right: 1.2rem;
`;

const ProfileButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: white;
  user-select: none;
  fill: white;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${theme.color.corePurple300};
  }
`;

const DropdownArrow = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  right: 1.3rem;
  transform: rotate(45deg);
  content: " ";
  background: white;
  border-top: 1px solid lighten(black, 70);
  border-left: 1px solid lighten(black, 70);
  position: absolute;
  top: -0.6rem;
`;

const DropdownWrapper = styled.div`
  background-color: white;
  border: 1px solid lighten(black, 70);
  border-radius: 0.8rem;
  color: black;
  text-align: left;
  position: absolute;
  display: inline-block;
  font-size: 1.6rem;
  z-index: 5;
  right: calc(4% - 1.45rem); /* Overall page padding minus arrow offset */
  top: 3.6rem;
  min-width: 32rem;
`;

const UserInfo = styled.div`
  padding: 2rem;
`;

const FullName = styled.div`
  margin-left: 2.4rem;
  margin-right: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  white-space: nowrap;
`;

export default function ProfileDropdown(): JSX.Element | null {
  if (useAuthContext().strategy === StrategyType.OIDC) {
    return <OIDCProfileDropdown />;
  }
  return <LegacyProfileDropdown />;
}

function CommonProfileDropdown({
  fullName,
  children: profileItems,
}: {
  fullName: string;
  children?: React.ReactNode;
}): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false);

  const closeProfileDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener("click", closeProfileDropdown);

    return () => {
      window.removeEventListener("click", closeProfileDropdown);
    };
  });

  const signout = useSignout();

  return (
    <Wrapper>
      <ProfileButton
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((isOpen) => !isOpen);
        }}
        type="button"
      >
        <AvatarIcon name="avatar" />
        <Arrow rotate={isOpen ? 90 : 270} size="m" />
      </ProfileButton>
      {isOpen && (
        <DropdownWrapper onClick={(e) => e.stopPropagation()}>
          <DropdownArrow />
          <UserInfo>
            <Link href={profileOverviewLink}>
              <IconStyled name="telia" />
              Min side
            </Link>
            <LinkMargin>
              <Link href={channelPickerPath("")}>
                <IconStyled name="add" />
                Valgmenyen
              </Link>
            </LinkMargin>
          </UserInfo>
          <Divider />
          <FullName>{fullName}</FullName>
          <Divider />
          <div>{profileItems}</div>
          <LogoutButtonWrapper>
            <LinkButton onClick={signout} text="Logg ut" isExternal />
          </LogoutButtonWrapper>
        </DropdownWrapper>
      )}
    </Wrapper>
  );
}

const StyledIcon = styled(Icon)`
  width: 20px;
`;

function LegacyProfileDropdown(): JSX.Element | null {
  const { data } = useSWR<UserProfileData>("/api/user-profile");
  const customers = useCustomerData(true);

  if (!data) {
    return null;
  }

  const selectedCustomerId = getSelectedCustomerId();
  const { fullName } = data;
  const usersWithCustomerId = customers ?? [];
  const hasMultipleProfiles = usersWithCustomerId?.length > 1;

  return (
    <CommonProfileDropdown fullName={fullName}>
      {usersWithCustomerId.map((user) => (
        <LegacyProfileItem
          key={user.customerId}
          userKey={user.customerId.toString()}
          selected={selectedCustomerId === user.customerId}
          multipleProfiles={hasMultipleProfiles}
          selectable={user.hasTvProducts}
        >
          <ProfileInfo
            selected={selectedCustomerId === user.customerId ? true : undefined}
            multipleProfiles={hasMultipleProfiles ? true : undefined}
          >
            <div>
              <Bold>{user.street}</Bold>
              <Bold>{user.city}</Bold>
              <div>Kundenummer: {user.customerId}</div>
            </div>
            {user.hasTvProducts && <StyledIcon name="checked" />}
          </ProfileInfo>
        </LegacyProfileItem>
      ))}
    </CommonProfileDropdown>
  );
}

function OIDCProfileDropdown(): JSX.Element | null {
  const user = useOIDCUser();
  const customers = useCustomerData(true);

  if (user === null) {
    return null;
  }

  const { profile } = user;
  const fullName =
    profile?.name ??
    profile?.given_name ??
    profile?.nickname ??
    profile?.email ??
    "";
  // const usersWithCustomerId = profile.customerData?.userAssets ?? null;
  const usersWithCustomerId = customers;
  const hasMultipleProfiles =
    (usersWithCustomerId && usersWithCustomerId.length > 1) ?? false;
  const selectedCustomerId = getSelectedCustomerId();

  return (
    <CommonProfileDropdown fullName={fullName}>
      {usersWithCustomerId?.map((user, index) => (
        <OIDCProfileItem
          key={user.customerId}
          customerId={user.customerId}
          customerIndex={index}
          multipleProfiles={hasMultipleProfiles}
          selectable={user.hasTvProducts}
        >
          <ProfileInfo
            selected={selectedCustomerId === user.customerId ? true : undefined}
            multipleProfiles={hasMultipleProfiles ? true : undefined}
            selectable={user.hasTvProducts}
          >
            Kundenummer: {user.customerId}{" "}
            {user.hasTvProducts && <StyledIcon name="checked" />}
          </ProfileInfo>
        </OIDCProfileItem>
      ))}
    </CommonProfileDropdown>
  );
}
