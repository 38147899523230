import Icon from "@teliaplay/icon";
import { AdditionalInformationText } from "components/helpers/Typography";
import { getFullLanguageString } from "components/video-player/BitmovinHelperFunctions";
import PlayerButton from "components/video-player/ui/PlayerButton";
import useCast from "hooks/cast/useCast";
import useCastPlayer from "hooks/cast/useCastPlayer";
import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components";
import theme from "styles/theme";

const Wrapper = styled.div`
  position: relative;
`;

const SubsList = styled.ul<{ menuOpen?: "true" }>`
  padding: 1rem 1.6rem;
  border-radius: 0.8rem;
  background: ${theme.color.grey800};
  position: absolute;
  display: ${(props) => (props.menuOpen ? "block" : "none")};
  bottom: 100%;
  right: 0;
`;

const Subtitle = styled.li<{ current?: "true" }>`
  font-size: 1.6rem;
  margin-top: 0.8rem;
  cursor: pointer;
  color: ${(props) =>
    props.current ? theme.color.corePurple500 : theme.color.grey300};
  transition: color 200ms;

  &:hover {
    color: ${(props) => (props.current ? theme.color.corePurple400 : "white")};
  }
`;

const getDisplayName = (sub: chrome.cast.media.Track): string => {
  let displayName = getFullLanguageString(sub.language);

  if ((sub.subtype as string) === "CAPTION") {
    displayName += " (for hørselshemmede)";
  }

  return displayName || sub.language;
};

export default function Subtitles(): JSX.Element | null {
  const { player } = useCast();
  const { editTracks } = useCastPlayer();

  const [activeSub, setActiveSub] = useState<
    chrome.cast.media.Track | undefined
  >();
  const [subsMenuOpen, setSubsMenyOpen] = useState(false);
  const [subtitleList, setSubtitleList] = useState<chrome.cast.media.Track[]>(
    []
  );

  useEffect(() => {
    setSubtitleList(
      player?.mediaInfo?.tracks?.filter((track) => track.type === "TEXT") || []
    );
  }, [player?.mediaInfo?.tracks]);

  const setSub = (sub: chrome.cast.media.Track) => {
    editTracks([sub.trackId]).then(() => setActiveSub(sub));
  };

  const disableSub = () => {
    editTracks([]).then(() => {
      setActiveSub(undefined);
    });
  };

  const toggleSubsMenu = () => {
    setSubsMenyOpen((prevState) => !prevState);
  };

  if (subtitleList.length === 0) {
    return null;
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setSubsMenyOpen(false)}>
      <Wrapper>
        <SubsList menuOpen={subsMenuOpen ? "true" : undefined}>
          <AdditionalInformationText>Undertekst</AdditionalInformationText>
          {subtitleList.map((sub) => (
            <Subtitle
              onClick={() => setSub(sub)}
              key={sub.trackId}
              current={sub.trackId === activeSub?.trackId ? "true" : undefined}
            >
              {getDisplayName(sub)}
            </Subtitle>
          ))}
          <Subtitle
            onClick={() => disableSub()}
            current={activeSub === undefined ? "true" : undefined}
          >
            Av
          </Subtitle>
        </SubsList>
        <PlayerButton onClick={toggleSubsMenu} text="Undertekst">
          <Icon name={activeSub ? "subactive" : "subinactive"} />
        </PlayerButton>
      </Wrapper>
    </OutsideClickHandler>
  );
}
