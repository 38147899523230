import { Card } from "types/card";

// OldCard is missing contentType
export type OldCard = Card & {
  kind?: string;
};

export default function formatCard(card: OldCard): Card {
  if (card.id !== undefined && card.contentId === undefined) {
    card.contentId = card.id;
  }
  if (card.kind !== undefined && card.contentType === undefined) {
    if (
      card.kind === "movie" ||
      card.kind === "program" ||
      card.kind === "episode"
    ) {
      card.contentType = card.kind;
    } else {
      card.contentType = "series";
    }
  }

  if (
    card.asset !== undefined &&
    card.asset.id !== undefined &&
    card.asset.assetId === undefined
  ) {
    card.asset.assetId = card.asset.id;
  }
  return card;
}

export const formatResult = (result: any[]): Card[] => result.map(formatCard);
