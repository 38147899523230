import { getDurationInSeconds } from "utils/DateUtil";

const extractTrackInformation = (index, track) => ({
  trackId: index,
  trackContentId: track.url,
  type: "TEXT",
  name: track.label,
  language: track.language,
  subtype: "CAPTIONS",
});

const getTracks = (subtitles) =>
  subtitles
    ? subtitles.map((track, index) => extractTrackInformation(index, track))
    : [];

const extractMediaInformation = (streamInfo, stream, position) => {
  const { youboraSettings, playSessionId, playContext, topTitle } = streamInfo;

  return {
    file: stream.mediaStreams?.dash,
    contentType: "application/dash+xml",
    customData: {
      stream,
      youboraSettings,
      playSessionId,
    },
    tracks: getTracks(stream.subtitles),
    duration: getDurationInSeconds(stream.runningTime) || null,
    currentTime:
      position?.streamId === stream?.streamId
        ? position.value
        : stream.previousPosition || null,
    subtitle: stream?.caption || "",
    title: topTitle || stream?.title || stream?.source?.name || "",
    streamType: playContext === "linear" ? "LIVE" : "BUFFERED",
  };
};

function formatMetadataImages(streamType: string, stream): chrome.cast.Image[] {
  const getPosterUrl = (stream) => {
    const posters = stream?.images?.poster;
    return posters && posters[0] && posters[0].path;
  };
  // For details, see the Receiver docs: the first image in the list should be displayed in the sender
  // the last image in the list should be displayed in the receiver
  const posterUrl = streamType === "BUFFERED" && getPosterUrl(stream);
  const logoUrl = stream?.source?.logos?.fixed?.onDark;
  const imageUrls = [posterUrl, logoUrl];
  return imageUrls
    .filter((url) => !!url)
    .map((url) => new chrome.cast.Image(url));
}

function formatMetadata(media): chrome.cast.media.GenericMediaMetadata {
  const metadata = new chrome.cast.media.GenericMediaMetadata();
  metadata.title = media.title;
  metadata.subtitle = media.subtitle;
  metadata.images = formatMetadataImages(
    media.streamType,
    media.customData.stream
  );
  return metadata;
}

const makeCastObject = (media) => {
  const mediaInfo = new chrome.cast.media.MediaInfo(media?.file, "");
  const isLive = !!(media.streamType === "LIVE");

  mediaInfo.contentType = "application/dash+xml";
  mediaInfo.customData = media.customData;
  mediaInfo.streamType = media.streamType;
  mediaInfo.duration = isLive ? null : media.duration;
  mediaInfo.metadata = formatMetadata(media);
  mediaInfo.tracks = isLive ? null : media.tracks;

  const request = new chrome.cast.media.LoadRequest(mediaInfo);

  request.activeTrackIds = !isLive && media.tracks.length > 0 ? [0] : [];

  request.currentTime = media.isLiveRestart ? 0 : media.currentTime;

  return request;
};

const makeCastQueueObject = (media, index) => {
  const mediaInfo = new chrome.cast.media.MediaInfo(media.file, "");

  mediaInfo.contentType = "application/dash+xml";
  mediaInfo.customData = media.customData;
  mediaInfo.streamType = chrome.cast.media.StreamType.BUFFERED;
  mediaInfo.duration = media.duration;
  mediaInfo.metadata = formatMetadata(media);
  mediaInfo.tracks = media.tracks;

  const queueItem = new chrome.cast.media.QueueItem(mediaInfo);

  queueItem.activeTrackIds = media.tracks.length > 0 ? [0] : [];
  queueItem.preloadTime = media.preloadTime;
  queueItem.startTime = index === 0 ? media.currentTime : 0;

  return queueItem;
};

type Position = { streamId: string; value: number };
type StreamInfo = any;

export const loadCastQueue = (
  streamInfo: StreamInfo,
  position: Position,
  callback: () => void
): void => {
  const { streams } = streamInfo;

  const castQueue = streams.map((streamItem, index) =>
    makeCastQueueObject(
      extractMediaInformation(streamInfo, streamItem, position),
      index
    )
  );

  const castSession =
    cast.framework.CastContext?.getInstance()?.getCurrentSession();
  const castSessionObject = castSession?.getSessionObj();

  const initialRequest = new chrome.cast.media.QueueLoadRequest([castQueue[0]]);

  castSessionObject?.queueLoad(
    initialRequest,
    () => {
      const mediaSession = castSession?.getMediaSession();

      for (let i = 1; i < castQueue.length; i++) {
        mediaSession?.queueAppendItem(
          castQueue[i],
          () => null,
          () => null
        );
      }
      callback();
    },
    (error) => {
      console.log(
        "error loading first request: ",
        error
      ); /* eslint no-console: 0 */
      callback();
    }
  );
};

export const formatStreamToCast = (
  streamInfo: StreamInfo,
  position: {
    value: number;
    streamId: string;
  }
): any =>
  makeCastObject(
    extractMediaInformation(streamInfo, streamInfo?.stream, position)
  );

export const getDurationString = (durationInSeconds: number): string => {
  let string = "";
  const secondsInHour = 60 * 60;
  const hoursLeft = Math.floor(durationInSeconds / secondsInHour);
  const minutesLeft = Math.floor((durationInSeconds % secondsInHour) / 60);
  const secondsLeft = Math.floor((durationInSeconds % secondsInHour) % 60);

  if (durationInSeconds === -1) {
    return "00:00";
  }

  if (hoursLeft > 0) {
    string += `${hoursLeft.toString().padStart(2, "0")}:`;
  }

  if (minutesLeft > 0) {
    string += `${minutesLeft.toString().padStart(2, "0")}:`;
  } else {
    string += "00:";
  }

  string += `${secondsLeft.toString().padStart(2, "0")}`;

  return string;
};
