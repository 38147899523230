import Icon from "@teliaplay/icon";
import PlayerButton from "components/video-player/ui/PlayerButton";
import useCastPlayer from "hooks/cast/useCastPlayer";

export default function SeekForward(): JSX.Element | null {
  const { seek, currentTime } = useCastPlayer();

  const onClick = () => {
    seek(currentTime + 15);
  };

  return (
    <PlayerButton onClick={onClick} text="Spol fremover">
      <Icon name="forward15" />
    </PlayerButton>
  );
}
