import {
  RecoveryStatus,
  RedirectCallbackSearchParams,
  RedirectSearchParams,
} from "./types";

const PROP_MAP: Record<keyof RedirectCallbackSearchParams, string> = {
  returnPath: "return",
  recoveryStatus: "status",
  error: "error",
  errorDescription: "error_description",
};

export function getRedirectParams(): RedirectCallbackSearchParams {
  const params = new URLSearchParams(window.location.search);
  const result = Object.fromEntries(
    Object.entries(PROP_MAP).map(([prop, param]) => [
      prop,
      params.get(param) ?? undefined,
    ])
  );
  if (!Object.values(RecoveryStatus).find((s) => s === result.recoveryStatus)) {
    delete result.recoveryStatus;
  }
  return result;
}

export function setRedirectParams(url: URL, args: RedirectSearchParams): URL {
  Object.entries(args).forEach(([prop, value]) =>
    url.searchParams.set(PROP_MAP[prop], value)
  );
  return url;
}
