import { addDays, format, isBefore } from "date-fns";
import {
  Asset,
  Card,
  Channel,
  Episode,
  Provider,
  Series,
  Source,
  Storyline,
} from "types/types";
import {
  isAvailableLiveNow,
  isAvailableNow,
  isAvailableNowFromCard,
} from "./Availability";
import { isContentTouched, isContentWatched, isFavourite } from "./Card";
import { isRecording } from "./Recordings";

type AssetsObject = { asset: Asset; alternativeAssets?: Asset[] };

export function getStoryline(storyline?: Storyline | string): string {
  if (storyline && typeof storyline === "string") {
    return storyline;
  }
  if (
    storyline &&
    typeof storyline !== "string" &&
    Object.prototype.hasOwnProperty.call(storyline, "default")
  ) {
    return storyline.default;
  }
  return "";
}

export function formatStartTime(asset: Asset): string | undefined {
  return (
    asset?.availabilities?.linear?.start &&
    format(new Date(asset.availabilities.linear.start), "HH:mm")
  );
}

export function getSelectedEpisode(card: Series): Episode | undefined {
  if (card.selectedAssetId && card.episodes) {
    return card.episodes.find(
      (episode) => episode?.asset?.id === card.selectedAssetId
    );
  }
  return undefined;
}

export function getSelectedAsset(card: Series): Asset | undefined {
  return getSelectedEpisode(card)?.asset;
}

export function getAssetFromAssetId(
  { asset, alternativeAssets }: AssetsObject,
  assetId: number
): Asset | undefined {
  const allAssets = alternativeAssets
    ? [asset].concat(alternativeAssets)
    : [asset];

  return allAssets.find((asset) => asset?.assetId === assetId);
}

export function getProviderOrExternalProviderName(asset?: Asset): string {
  const providerName = getProviderName(asset?.provider);
  if (providerName === "")
    return (
      asset?.availabilities.external?.registrationStatus?.providerName || ""
    );

  return providerName;
}

export function getProviderName(
  provider?: Provider | Source | Channel
): string {
  return provider?.name ? provider.name : "";
}

export function getProviderNameFromCard(card: Card, fallback?: string): string {
  if (card?.asset?.sourceName) {
    return card.asset.sourceName;
  }
  if (card?.newSources?.length && card.newSources.length > 0) {
    return getProviderName(card.newSources[0]);
  }

  if (card?.asset?.provider) {
    return getProviderName(card.asset.provider);
  }
  if (card?.asset?.channel) {
    return getProviderName(card.asset.channel);
  }
  return fallback || "";
}

export function generateProviderTitle(card: Card): string {
  if (card?.asset?.channel?.name) {
    return `Gå til ${getProviderNameFromCard(card)}`;
  }
  if (card?.asset?.channel) {
    return "Gå til kanalside";
  }

  if (card?.asset?.provider?.name) {
    return `Gå til ${getProviderNameFromCard(card)}`;
  }
  return "Gå til tilbyder";
}

export function hasSubscribingInfo(asset: Asset): boolean {
  return (
    (asset?.channel &&
      Object.hasOwnProperty.call(asset.channel, "subscribing")) ||
    (asset && Object.hasOwnProperty.call(asset, "subscribing"))
  );
}

export function isSubscribingToChannel(channel: Channel): boolean {
  if (Object.hasOwnProperty.call(channel, "subscribing")) {
    return !!channel.subscribing;
  }
  return false;
}

export function isSubscribing(asset: Asset): boolean {
  if (
    asset?.channel &&
    Object.hasOwnProperty.call(asset.channel, "subscribing")
  ) {
    return !!asset.channel.subscribing;
  }
  return !!asset?.subscribing;
}

function isLessThanSevenDaysOld(asset: Asset, currentTime: Date): boolean {
  let start;

  if (asset?.availabilities?.linear?.start) {
    start = new Date(asset.availabilities.linear.start);
  }
  if (asset?.availabilities?.subscription?.start) {
    start = new Date(asset.availabilities.subscription.start);
  }
  if (start) {
    return (
      isBefore(currentTime, addDays(start, 7)) && isBefore(start, currentTime)
    );
  }
  return false;
}

function catchupExpiresInLessThanTwoDays(
  card: Card,
  currentTime: Date
): boolean {
  const catchupAvailability = card?.asset?.availabilities?.catchUp;

  if (catchupAvailability) {
    const endTimeDate = new Date(catchupAvailability.end);
    const twoDaysFromNow = addDays(currentTime, 2);

    return isBefore(endTimeDate, twoDaysFromNow);
  }
  return false;
}

function notNewestOrIsNewest(card: Card): boolean {
  if (Object.prototype.hasOwnProperty.call(card, "newest")) {
    return !!card.newest;
  }
  return true;
}

export function isNewEpisode(card: Card, currentTime: Date): boolean {
  if (!card?.asset?.availabilities) {
    if (card && Object.hasOwnProperty.call(card, "isNew")) {
      return !!card.isNew;
    }
    return card?.labelType === "newEpisode";
  }
  return (
    !!card?.asset &&
    notNewestOrIsNewest(card) &&
    isLessThanSevenDaysOld(card.asset, currentTime) &&
    !isAvailableLiveNow(card.asset, currentTime) &&
    (isFavourite(card) || !!card.activeSeriesRecording) &&
    !isContentTouched(card) &&
    isAvailableNow(card.asset, currentTime)
  );
}

export function isLastChance(card: Card, currentTime: Date): boolean {
  if (!card?.asset?.availabilities?.catchUp) {
    if (card && Object.hasOwnProperty.call(card, "isLastChance")) {
      return !!card.isLastChance;
    }
    return card?.labelType === "lastChance";
  }
  return (
    !!card?.favourite &&
    !isContentWatched(card) &&
    !isRecording(card) &&
    isAvailableNowFromCard(card, currentTime) &&
    catchupExpiresInLessThanTwoDays(card, currentTime)
  );
}

export function removeNpvrAvailability(
  content: Episode,
  id: number
): false | Episode {
  // TODO: this is not good enough, do not check alternatieAssets..
  if (!content || !id) {
    return false;
  }
  if (content?.asset?.recordingId === id) {
    delete content.asset.availabilities.npvr;
    delete content.asset.recordingId;
  }
  return content;
}

export function addScheduledSingleRecordingId(
  content: Episode,
  assetId: number,
  scheduledSingleRecordingId: number
): Episode {
  if (!assetId || !scheduledSingleRecordingId) {
    return content || {};
  }

  if (content?.asset?.assetId === assetId) {
    content.asset.scheduledSingleRecordingId = scheduledSingleRecordingId;
  } else if (
    content.alternativeAssets &&
    content.alternativeAssets.length > 0
  ) {
    const matchingAssetIndex = content.alternativeAssets.findIndex(
      (asset) => asset.assetId === assetId
    );

    if (matchingAssetIndex >= 0 && content.alternativeAssets) {
      content.alternativeAssets[matchingAssetIndex].scheduledSingleRecordingId =
        scheduledSingleRecordingId;
    }
  }
  return content;
}

export function removeScheduledSingleRecordingId(
  content: Episode,
  scheduledSingleRecordingId: number
): Episode {
  if (!scheduledSingleRecordingId) {
    return content || {};
  }
  if (
    content.asset &&
    content.asset.scheduledSingleRecordingId === scheduledSingleRecordingId
  ) {
    delete content.asset.scheduledSingleRecordingId;
  } else if (
    content.alternativeAssets &&
    content.alternativeAssets.length > 0
  ) {
    const matchingAssetIndex = content.alternativeAssets.findIndex(
      (asset) => asset.scheduledSingleRecordingId === scheduledSingleRecordingId
    );

    if (matchingAssetIndex >= 0 && content.alternativeAssets) {
      delete content.alternativeAssets[matchingAssetIndex]
        .scheduledSingleRecordingId;
    }
  }
  return content;
}

/**
 * Returns boolean for if channel or provider is of type bySubscription
 * @param {Card} card
 * @param {Date} currentTime
 * @returns {boolean} is past content
 */
export function pastContent(card: Card, currentTime: Date): boolean {
  return !!(
    card?.asset?.availabilities?.linear &&
    isBefore(new Date(card.asset.availabilities.linear.end), currentTime)
  );
}

export function isChannelBySubscription(channel: Channel): boolean {
  return channel.type === "bySubscription";
}

/**
 * Returns boolean for if channel or provider is of type bySubscription
 * @param {Asset} asset
 * @returns {boolean} year
 */
export function isBySubscription(asset: Asset): boolean {
  return (
    asset?.channel?.type === "bySubscription" ||
    asset?.provider?.type === "bySubscription"
  );
}

export function getChannelPickerTargetPathFromSource(
  source: Provider | Channel,
  type: string
): string {
  if (!source || !source.id) {
    return "";
  }

  return `&type=${type}&id=${source.id}`;
}

export function getChannelPickerTargetPath(asset: Asset): string {
  if (!asset) {
    return "";
  }
  if (asset.channel && asset.channel.id) {
    return getChannelPickerTargetPathFromSource(asset.channel, "kanal");
  }
  if (asset.provider && asset.provider.id) {
    return getChannelPickerTargetPathFromSource(asset.provider, "tilbyder");
  }
  if ((asset as any).type === "bySubscription") {
    return `&type=tilbyder`;
  }
  return "";
}

export function isAssetDefined(asset: Asset | undefined): boolean {
  return !!(asset && isSubscribing(asset) && !asset?.subscribingError);
}
