import QuotaBanner from "components/banner/QuotaBanner";
import CastControlbar from "components/cast-controlbar/CastControlbar";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import Spinner from "components/spinner/Spinner";
import UseScrollRestoration from "hooks/useScrollRestoration";
import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";

const NotFound = lazy(() => import("components/notfound/NotFound"));
const AboutPage = lazy(() => import("pages/AboutPage"));
const ArchiveOverview = lazy(() => import("pages/ArchiveOverview"));
const AssetPage = lazy(() => import("pages/AssetPage"));
const ChannelEpgPage = lazy(() => import("pages/channel-epg/ChannelEpgPage"));
const Channel = lazy(() => import("pages/channel/Channel"));
const CollectionPage = lazy(() => import("pages/CollectionPage"));
const DiscoverArchive = lazy(() => import("pages/DiscoverArchive"));
const DiscoverKids = lazy(() => import("pages/DiscoverKids"));
const DiscoverMovies = lazy(() => import("pages/DiscoverMovies"));
const DiscoverPayedMovies = lazy(() => import("pages/DiscoverPayedMovies"));
const DiscoverProvider = lazy(() => import("pages/DiscoverProvider"));
const Episode = lazy(() => import("pages/episode/Episode"));
const DiscoverPage = lazy(() => import("pages/ExplorePage"));
const HelpPage = lazy(() => import("pages/HelpPage"));
const Home = lazy(() => import("pages/Home"));
const KidsOverview = lazy(() => import("pages/KidsOverview"));
const Movie = lazy(() => import("pages/Movie"));
const MoviesOverview = lazy(() => import("pages/MoviesOverview"));
const OrderNpvrPage = lazy(() => import("pages/OrderNpvrPage"));
const PayedMoviesOverview = lazy(() => import("pages/PayedMoviesOverview"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));
const Program = lazy(() => import("pages/Program"));
const SearchPerson = lazy(() => import("pages/search-person/SearchPerson"));
const Search = lazy(() => import("pages/search/Search"));
const DiscoverSeries = lazy(() => import("pages/series/DiscoverSeries"));
const Series = lazy(() => import("pages/series/Series"));
const SeriesOverview = lazy(() => import("pages/series/SeriesOverview"));
const TvGuide = lazy(() => import("pages/tvguide/TvGuide"));
const ContinueWatching = lazy(
  () => import("pages/user-content/ContinueWatchingOverview")
);
const Favourites = lazy(() => import("pages/user-content/FavouriteOverview"));
const PayedPage = lazy(() => import("pages/user-content/PayedPage"));
const RecordingsOverview = lazy(
  () => import("pages/user-content/RecordingsOverview")
);
const UserContent = lazy(() => import("pages/user-content/UserContent"));
const WatchAsset = lazy(() => import("pages/watch/watchAsset"));
const WatchChannel = lazy(() => import("pages/watch/watchChannel"));
const WatchPlaylist = lazy(() => import("pages/watch/watchPlaylist"));
const WatchTrailer = lazy(() => import("pages/watch/watchTrailer"));
const DiscoverSports = lazy(() => import("pages/DiscoverSports"));

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default function Routes(): JSX.Element {
  return (
    <Suspense fallback={<Spinner fullPage />}>
      <UseScrollRestoration />
      <Switch>
        <Redirect exact path="/" to="/hjem" />
        <Route
          exact
          path="/se/spilleliste/:id/:assetId/:restart?"
          component={WatchPlaylist}
        />
        <Route
          exact
          path="/se/innhold/:contentId/:assetId/:restart?"
          component={WatchAsset}
        />
        <Route exact path="/se/trailer/:contentId" component={WatchTrailer} />
        <Route
          exact
          path="/se/direkte/:channelName/:restart?"
          component={WatchChannel}
        />
        <Route path="/">
          <div className="main">
            <Header loggedIn />
            <QuotaBanner />
            <Wrapper>
              <Suspense fallback={<Spinner fullPage />}>
                <Switch>
                  <Redirect exact path="/leiefilm" to="/filmbutikken" />
                  <Route
                    exact
                    path="/filmbutikken"
                    component={DiscoverPayedMovies}
                  />
                  <Route
                    exact
                    path="/filmbutikkenoversikt"
                    component={PayedMoviesOverview}
                  />
                  <Redirect exact path="/filmleie" to="/filmbutikken" />
                  <Redirect exact path="/betaltfilm" to="/filmbutikken" />
                  <Route
                    path="/filmer/:contentId/:assetId?"
                    component={Movie}
                  />
                  <Route exact path="/tv-guide" component={TvGuide} />
                  <Route exact path="/sok" component={Search} />
                  <Route
                    exact
                    path="/sok/person/:person"
                    component={SearchPerson}
                  />
                  <Route
                    exact
                    path="/serier/:seriesId/:seasonId?"
                    component={Series}
                  />
                  <Route
                    exact
                    path="/episode/:seriesId/:assetId"
                    component={Episode}
                  />
                  <Route
                    exact
                    path="/kanaler/:channelId/epg/:offset"
                    component={ChannelEpgPage}
                  />
                  <Route
                    exact
                    path="/kanaler/:channelName?"
                    component={Channel}
                  />
                  <Route
                    exact
                    path="/program/:contentId/:assetId?"
                    component={Program}
                  />
                  <Route exact path="/filmer" component={DiscoverMovies} />
                  <Route
                    exact
                    path="/filmoversikt"
                    component={MoviesOverview}
                  />
                  <Route exact path="/serier" component={DiscoverSeries} />
                  <Route
                    exact
                    path="/serieoversikt"
                    component={SeriesOverview}
                  />
                  <Route exact path="/arkiv" component={DiscoverArchive} />
                  <Route
                    exact
                    path="/arkivoversikt"
                    component={ArchiveOverview}
                  />
                  <Route exact path="/barn" component={DiscoverKids} />
                  <Route exact path="/barnoversikt" component={KidsOverview} />
                  <Route exact path="/mittinnhold" component={UserContent} />
                  <Route exact path="/fortsett" component={ContinueWatching} />
                  <Route exact path="/favoritter" component={Favourites} />
                  <Route exact path="/opptak" component={RecordingsOverview} />
                  <Route exact path="/betalt" component={PayedPage} />
                  <Route
                    exact
                    path="/tilbyder/:simpleName/:pageType?"
                    component={DiscoverProvider}
                  />
                  <Route
                    exact
                    path="/innhold/:collectionId/:assetId"
                    component={AssetPage}
                  />
                  <Route
                    exact
                    path="/samling/:collectionId"
                    component={CollectionPage}
                  />
                  <Route exact path="/sport" component={DiscoverSports} />
                  <Route exact path="/utforsk" component={DiscoverPage} />
                  <Route exact path="/om" component={AboutPage} />
                  <Route exact path="/hjelp" component={HelpPage} />
                  <Route exact path="/hjem" component={Home} />
                  <Route exact path="/profil" component={ProfilePage} />
                  <Route exact path="/bestill/npvr" component={OrderNpvrPage} />
                  <Route path="/404" component={NotFound} />
                  <Route path="/" component={NotFound} />
                </Switch>
              </Suspense>
            </Wrapper>
            <Footer />
          </div>
        </Route>
      </Switch>
      <CastControlbar />
    </Suspense>
  );
}
