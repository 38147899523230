import Icon from "@teliaplay/icon";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import theme from "styles/theme";
import { isBetaUser } from "utils/UserUtil";
import BetaLogo from "./images/beta";
import TeliaLogo from "./images/TeliaLogo";
import MobileMenu from "./MobileMenu";
import { MenuItemType } from "./MobileMenuItem";

const Wrapper = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  @media ${theme.newbp.medium} {
    display: none;
  }
`;

const ShowIconBPSmall = styled.span`
  display: none;

  @media ${theme.newbp.small} {
    display: inline;
  }
`;

const BetaLogoStyled = styled(BetaLogo)`
  height: 2.7rem;
  margin-left: 1.4rem;
`;

const TeliaLogoWrapper = styled.div`
  height: 2.7rem;
  margin-right: 2rem;

  @media ${theme.bp.enormous} {
    height: 4.2rem;
  }
`;

function LogoStyled() {
  return isBetaUser() ? (
    <BetaLogoStyled />
  ) : (
    <TeliaLogoWrapper>
      <TeliaLogo />
    </TeliaLogoWrapper>
  );
}

const MenuLink = styled(NavLink)`
  margin-right: 1.4rem;
`;

const MobileIcon = styled(Icon)`
  width: 2.4rem;
  height: 2.4rem;
  color: white;
  :hover {
    color: ${theme.color.corePurple300};
  }
`;

const HamburgerWrapper = styled.li`
  display: flex;
  align-items: center;
`;

type Props = {
  menuItems: MenuItemType[];
  secondMenuItems: MenuItemType[];
  loggedIn?: boolean;
};

function MobileHeader({
  menuItems,
  secondMenuItems,
  loggedIn,
}: Props): JSX.Element {
  return (
    <Wrapper>
      <HamburgerWrapper>
        <MobileMenu
          menuItems={menuItems}
          secondMenuItems={secondMenuItems}
          loggedIn={loggedIn}
        />
        <NavLink to="/hjem">
          <LogoStyled />
        </NavLink>
      </HamburgerWrapper>
      <li>
        {loggedIn && (
          <MenuLink to="/sok" data-cy="mobile_header_sok">
            <MobileIcon name="search" />
          </MenuLink>
        )}
        <ShowIconBPSmall>
          <MenuLink to="/tv-guide">
            <MobileIcon name="tvguide" />
          </MenuLink>
        </ShowIconBPSmall>
        {loggedIn && (
          <NavLink to="/profil">
            <MobileIcon name="avatar" />
          </NavLink>
        )}
      </li>
    </Wrapper>
  );
}

export default MobileHeader;
