import Banner from "@teliaplay/banner";
import { RecordingQuotaType } from "components/recording-quota/RecordingQuota";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import { UserDataAsyncState } from "types/types";
import { isQuotaBannerHidden, persistQuotaBannerHidden } from "utils/Cookie";
import { lowRecordingQuota, overRecordingQuota } from "utils/Recordings";
import { isNPVRUser } from "utils/UserUtil";
import BannerWrapper from "./BannerWrapper";

const Title = styled.p`
  margin-bottom: 0.4rem;
`;
const Info = styled.p`
  font-size: 1.4rem;
  font-weight: normal;
`;

export default function QuotaBanner(): JSX.Element | null {
  const user = useSelector((state: UserDataAsyncState) => state.userDataAsync);

  if (!isNPVRUser(user)) {
    return null;
  }
  const { data: recordingQuota } = useSWR<RecordingQuotaType, Error>(
    "/api/npvr/quota"
  );
  const [bannerClosed, setbannerClosed] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  const isOverRecordingQuota = overRecordingQuota(user, recordingQuota);

  const hasLowRecordingQuota = lowRecordingQuota(user, recordingQuota);

  const closeable = !(
    (pathname === "/mittinnhold" || pathname === "/opptak") &&
    isOverRecordingQuota
  );

  if (pathname === "/tv-guide") {
    return null;
  }

  /* Banner should be visible only when the quota is low or full.
    And then it should always show if it is not closeable. If it is closeable,
    it should not show if it is closed according to state or the cookie */
  const showQuotaBanner =
    (hasLowRecordingQuota || isOverRecordingQuota) &&
    (!closeable || (closeable && !bannerClosed && !isQuotaBannerHidden()));

  if (showQuotaBanner || !closeable) {
    return (
      <BannerWrapper>
        <Banner
          show={isOverRecordingQuota || showQuotaBanner}
          close={
            closeable
              ? () => {
                  persistQuotaBannerHidden();
                  setbannerClosed(true);
                }
              : undefined
          }
        >
          <Title>
            {isOverRecordingQuota
              ? "Din opptakskvote er full. "
              : "Din opptakskvote er snart full. "}
          </Title>
          <Info>
            {" "}
            <NavLink to="/opptak" exact>
              Slett opptak
            </NavLink>{" "}
            {isOverRecordingQuota
              ? "for å ta opp flere programmer. "
              : "for å unngå at dine planlagte opptak ikke blir tatt opp. "}
          </Info>
        </Banner>
      </BannerWrapper>
    );
  }

  return null;
}
