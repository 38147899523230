import useCast from "hooks/cast/useCast";
import useCastPlayer from "hooks/cast/useCastPlayer";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import theme from "styles/theme";
import { getDurationString } from "utils/CastUtil";
import SeekThumbnail, { HoverData } from "../SeekThumbnail";

const CurrentTimestamp = styled.div`
  margin-right: 1.6rem;
  font-size: 1.6rem;
`;

const Duration = styled.div`
  font-size: 1.6rem;
  margin-left: 1.6rem;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
`;

const sliderThumbStyles = css<{ isLive?: "true" }>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background: ${(props) =>
    props.isLive ? theme.color.red500 : theme.color.corePurple400};
`;

type RangeProps = {
  valuePercent: number;
  isLive?: "true";
};

const Range = styled.input.attrs((props: RangeProps) => ({
  style: {
    background: `linear-gradient(to right,
      ${props.isLive ? theme.color.red500 : theme.color.corePurple500}, ${
      props.isLive ? theme.color.red300 : theme.color.corePurple300
    }
       ${props.valuePercent}%,
       ${theme.color.grey400} ${props.valuePercent}%,
     ${theme.color.grey600} 100% )`,
  },
}))<RangeProps>`
  height: 0.3rem;
  width: 100%;
  padding: 0;

  margin-right: 1.6rem;
  margin-left: 1.6rem;
  opacity: 0.8;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active {
    outline: none;
    opacity: 1;
  }

  /* stylelint-disable */
  -webkit-appearance: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    ${sliderThumbStyles}
  }
  &::-moz-range-thumb {
    -moz-appearance: none;
    border: none;
    ${sliderThumbStyles}
  }
  &::-moz-focus-outer {
    border: 0;
  }
  /* stylelint-enable */
`;

export default function Progressbar(): JSX.Element {
  const { player } = useCast();
  const [streamDuration, setStreamDuration] = useState(0);
  const { currentTime, duration, seek } = useCastPlayer();
  const [currentMedia, setCurrentMedia] = useState(player?.mediaInfo);
  const [thumbnailData, setThumbnailData] = useState<HoverData | undefined>();
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>(
    (player?.mediaInfo?.customData as any)?.stream?.thumbnailUrl
  );

  const updateThumbnail = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const obj: HoverData = {
      mousePosition: event.clientX,
      parentRect: event.currentTarget.parentElement?.getBoundingClientRect(),
      targetRect: event.currentTarget.getBoundingClientRect(),
      minValue: 0,
      maxValue: streamDuration,
    };

    setThumbnailData(obj);
  };

  useEffect(() => {
    setStreamDuration(player?.liveSeekableRange?.end || duration);

    if (player?.mediaInfo?.contentId !== currentMedia?.contentId) {
      setThumbnailUrl(
        (player?.mediaInfo?.customData as any)?.stream?.thumbnailUrl
      );
    }
    setCurrentMedia(player?.mediaInfo);
  }, [player?.mediaInfo]);

  useEffect(() => {
    setStreamDuration(player?.liveSeekableRange?.end || duration);
    let interval;

    if (player?.mediaInfo?.streamType === "LIVE") {
      interval = setInterval(() => {
        setStreamDuration((streamDuration) => streamDuration + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [player?.liveSeekableRange?.end]);

  const seekEventHandler = (e) => {
    const value = e?.target?.value;

    if (value) {
      seek(value);
    }
  };

  return (
    <Row>
      {thumbnailData && (
        <SeekThumbnail data={thumbnailData} thumbnailUrl={thumbnailUrl} />
      )}
      <CurrentTimestamp>{getDurationString(currentTime)}</CurrentTimestamp>
      <Range
        type="range"
        className="slider"
        min={0}
        max={streamDuration}
        value={currentTime}
        valuePercent={(currentTime / streamDuration) * 100 || 0}
        onChange={seekEventHandler}
        isLive={currentMedia?.streamType === "LIVE" ? "true" : undefined}
        onMouseMove={(event) => updateThumbnail(event)}
        onMouseOut={() => setThumbnailData(undefined)}
        onBlur={() => setThumbnailData(undefined)}
      />
      <Duration>{getDurationString(streamDuration)}</Duration>
    </Row>
  );
}
