import {
  LOGIN_RETURN_PATHNAME,
  LOGIN_SILENT_PATHNAME,
  LOGOUT_RETURN_PATHNAME,
} from "auth/constants";
import debug from "auth/debug";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  AuthHeaders,
  PartyId,
  RedirectSearchParams,
  ScopeProps,
  StrategyType,
  UserWithScopes,
} from "auth/types";
import { STRATEGY_KEY } from "components/auth/AuthProvider";
import {
  ErrorResponse,
  Log,
  User,
  UserManager,
  WebStorageStateStore,
} from "oidc-client-ts";
import { CustomerData, UserAsset } from "types/types";
import { getSelectedCustomerId } from "utils/customerId";
import { getOidcConfig } from "utils/Options";
import sleep from "utils/sleep";
import { getRedirectParams, setRedirectParams } from "./utils";

const CLIENT_ID = "telia.no.tv.web";

const SESSION_NOT_ACTIVE = "Session not active";

enum CiamScopes {
  CUSTOMER_DATA = "urn:teliacompany:dis:ciam:icx:no:1.0:customer_id",
  CUSTOMER_MANAGER = "urn:teliacompany:dis:ciam:icx:no:1.0:customer_id_manager",
  PARTY_ID = "urn:teliacompany:dis:ciam:1.0:party_id",
}

type InternalScopeProps = Readonly<{
  /**
   * If loadUserInfo is true and mergeClaims is false, oidc-client-ts will
   * return pair of CustomerData instead of a single object, if the claim is
   * present in both the id token response and user info response.
   */
  [CiamScopes.CUSTOMER_DATA]?: CustomerData | [CustomerData, CustomerData];
  [CiamScopes.CUSTOMER_MANAGER]?: CustomerData | [CustomerData, CustomerData];
  [CiamScopes.PARTY_ID]?: PartyId;
}>;

if (__DEBUG_OIDC__) {
  Log.setLogger(console);
  Log.setLevel(Log.DEBUG);
}

function getPartyId(profile: InternalScopeProps): PartyId | undefined {
  return profile[CiamScopes.PARTY_ID];
}

function getUserAssets(
  data?: CustomerData | [CustomerData, CustomerData]
): UserAsset[] {
  if (!data) {
    return [];
  }

  if (Array.isArray(data)) {
    // The second value is the one loaded from the user profile
    return data[1]?.userAssets ?? [];
  }

  return data?.userAssets ?? [];
}

function getCustomerData(
  profile: InternalScopeProps
): CustomerData | undefined {
  const customerId = profile[CiamScopes.CUSTOMER_DATA];
  const customerManagerId = profile[CiamScopes.CUSTOMER_MANAGER];

  const userAssets: UserAsset[] = [
    ...new Set([
      ...getUserAssets(customerId),
      ...getUserAssets(customerManagerId),
    ]),
  ];

  return {
    userAssets,
  };
}

/**
 * Adds {@link ScopeProps} to the {@link User.profile} property by utilizing the
 * prototype chain.
 */
function addScopePropsToUser(user: User | null): UserWithScopes | null {
  if (user === null) {
    return null;
  }

  // NOTE: Object.create is not type-checked
  return Object.create(user, {
    profile: {
      value: Object.create(user.profile, {
        customerData: {
          get() {
            return getCustomerData(this);
          },
        },
        partyId: {
          get() {
            return getPartyId(this);
          },
        },
      }),
    },
  });
}

// TODO: Use redux instead?
let cachedUser: UserWithScopes | null | undefined;
let suppressUserUpdates = false;

const events = (() => {
  const target = new EventTarget();
  const updateType = "update";

  return {
    updateUser(user?: UserWithScopes | null): void {
      if (suppressUserUpdates) {
        debug("Suppressing user updated event");
        return;
      }
      debug("Dispatching user updated event");
      target.dispatchEvent(
        new CustomEvent<UserWithScopes | null>(updateType, {
          detail: user ?? cachedUser,
        })
      );
    },

    addUserObserver(
      observer: (user: UserWithScopes | null) => void
    ): () => void {
      debug("Adding user observer");
      const listener: EventListener = (event) => {
        debug("Notifying user observer");
        observer((event as CustomEvent<UserWithScopes | null>).detail);
      };
      target.addEventListener(updateType, listener);
      return () => {
        debug("Removing user observer");
        target.removeEventListener(updateType, listener);
      };
    },
  };
})();

function cacheUser(user: User | null): void {
  if (
    (cachedUser !== null || user !== null) &&
    !(cachedUser && Object.getPrototypeOf(cachedUser) === user)
  ) {
    cachedUser = addScopePropsToUser(user);
    debug(`Cached user updated: ${cachedUser?.profile.name ?? cachedUser}`);
    events.updateUser(cachedUser);
  } else {
    debug("Cached used not changed");
  }
}

const userManager = new UserManager({
  authority: getOidcConfig().identityAuthority,
  client_id: CLIENT_ID,
  redirect_uri: `${window.location.origin}${LOGIN_RETURN_PATHNAME}`,
  silent_redirect_uri: `${window.location.origin}${LOGIN_SILENT_PATHNAME}`,
  post_logout_redirect_uri: `${window.location.origin}${LOGOUT_RETURN_PATHNAME}`,
  response_type: "code",
  scope: [
    "openid",
    "phone",
    "email",
    "profile",
    ...Object.values(CiamScopes),
  ].join(" "),
  filterProtocolClaims: false,
  loadUserInfo: false,
  mergeClaims: false,
  automaticSilentRenew: false,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});

export function getUser(): UserWithScopes | null {
  return cachedUser ?? null;
}

const init = (async () => {
  if (window.top !== window.self) {
    debug("Not running in main window, skipping initialization");
    return;
  }
  debug("Initializing");

  // Yield until next tick
  await sleep();

  if (LOGIN_RETURN_PATHNAME !== window.location.pathname) {
    debug("Loading user");
    try {
      const user = await userManager.getUser();
      const strategy =
        window.localStorage.getItem(STRATEGY_KEY) ?? StrategyType.OIDC;

      if (strategy === StrategyType.OIDC && (user === null || user?.expired)) {
        debug("Access token expired");
        cacheUser(await signinSilentInternal());
      } else {
        cacheUser(user);
      }
    } catch (error) {
      cacheUser(null);
    }
  } else {
    debug("User just signed in, skip loading user until callback is processed");
  }

  userManager.events.addUserLoaded((user) => {
    debug(`event: User loaded: ${user.profile.name}`);
    cacheUser(user);
  });

  userManager.events.addUserUnloaded(() => {
    debug("event: User unloaded");
    cacheUser(null);
  });

  userManager.events.addSilentRenewError((e) => {
    debug("event: Silent renew error:", e);
    if (e.message === SESSION_NOT_ACTIVE) {
      userManager.removeUser();
      userManager.revokeTokens(["refresh_token", "access_token"]);
    }
  });

  userManager.events.addAccessTokenExpired(() => {
    debug("event: Access token expired");
  });

  debug("Starting silent renew service");
  userManager.startSilentRenew();

  debug("Initialization complete");
})();

export async function waitForOidc(): Promise<void> {
  await init;
}

export function isAuthenticated(): boolean | undefined {
  if (cachedUser === undefined) {
    debug("Status: Initializing");
    return undefined;
  }
  if (cachedUser === null) {
    debug("Status: No user loaded");
    return false;
  }
  debug(`Status: User ${cachedUser.profile.name} is loaded`);
  debug(`Status: Access token is ${cachedUser.expired ? "expired" : "valid"}`);
  return !cachedUser.expired;
}

export function addUserObserver(
  update: (user: UserWithScopes | null) => void
): () => void {
  const removeUserObserver = events.addUserObserver(update);
  update(getUser());
  return removeUserObserver;
}

let renewal: Promise<void> | undefined;

export function getAuthHeaders(): AuthHeaders {
  const user = getUser();
  if (user === null) {
    return {};
  }

  if (user.expired) {
    if (renewal === undefined) {
      debug(
        "Found expired access token while getting authorization headers, " +
          "attempting last-ditch renewal"
      );
      renewal = (async () => {
        try {
          await sleep();
          await signinSilentInternal();
          renewal = undefined;
        } catch (error) {
          throw new Error("Last-ditch renewal unsuccessful", {
            cause: error instanceof Error ? error : undefined,
          });
        }
      })();
    }
    throw new Error("Access token is expired");
  }

  const { customerData } = user.profile;
  if (customerData === undefined) {
    throw new Error("Customer ID missing from profile");
  }

  const { partyId } = user.profile;
  if (partyId === undefined) {
    throw new Error("Party ID missing from profile");
  }

  const selectedCustomerId = getSelectedCustomerId();
  const customerId = (
    selectedCustomerId === null
      ? customerData.userAssets?.at(0)
      : customerData.userAssets?.find(
          (u) => parseInt(u.id, 10) === selectedCustomerId
        )
  )?.id;

  if (customerId === undefined) {
    return {
      Authorization: `Bearer ${user.access_token}`,
    };
  }

  return {
    Authorization: `Bearer ${user.access_token}`,
    "X-Customer-Id": customerId,
  };
}

export async function signinRedirect(
  params: RedirectSearchParams = { returnPath: window.location.pathname }
): Promise<void> {
  await init;
  debug(`Initiating signin. Return path: ${params.returnPath}`);
  suppressUserUpdates = true;
  return userManager.signinRedirect({
    redirect_uri: setRedirectParams(
      new URL(userManager.settings.redirect_uri),
      params
    ).href,
  });
}

export async function signinRedirectCallback(): Promise<RedirectSearchParams> {
  await init;
  debug("Processing signin response");
  const params = getRedirectParams();
  debug(`Previous path retrieved from query parameter: ${params.returnPath}`);
  const user = await userManager.signinRedirectCallback();
  debug(`Signin process complete, user: ${user.profile.name}`);
  await userManager.clearStaleState();
  return params;
}

async function signinSilentInternal(): Promise<User> {
  debug("Initiating silent signin/renewal");
  try {
    const user = await userManager.signinSilent();
    if (user === null) {
      throw new Error("UserManager.signinSilent returned null");
    }
    debug(`Silent signin/renewal process complete, user: ${user.profile.name}`);
    return user;
  } catch (error) {
    const msg = error instanceof Error ? `: ${error.message}` : "";
    debug(`Silent signin/renewal unsuccessful${msg}`);
    throw error;
  }
}

export async function signinSilent(): Promise<void> {
  await init;
  try {
    await signinSilentInternal();
  } catch (error) {
    if (!(error instanceof ErrorResponse)) {
      throw error;
    }
    // TODO: Should we call userManager.removeUser()?
  }
}

export async function signinSilentCallback(): Promise<void> {
  await init;
  debug("Processing silent signin/renewal response");
  return userManager.signinSilentCallback();
}

export async function signoutRedirect(
  params?: RedirectSearchParams
): Promise<void> {
  await init;
  debug("Initiating signout");
  suppressUserUpdates = true;
  return userManager.signoutRedirect({
    ...(userManager.settings.post_logout_redirect_uri &&
      params && {
        post_logout_redirect_uri: setRedirectParams(
          new URL(userManager.settings.post_logout_redirect_uri),
          params
        ).href,
      }),
    extraQueryParams: {
      client_id: CLIENT_ID,
    },
  });
}

export async function signoutRedirectCallback(): Promise<RedirectSearchParams> {
  await init;
  debug("Processing signout response");
  const params = getRedirectParams();
  const response = await userManager.signoutRedirectCallback();
  debug(`Signout process complete, state: ${response.state}`);
  await userManager.clearStaleState();
  return params;
}
