import QuotaBanner from "components/banner/QuotaBanner";
import CastControlbar from "components/cast-controlbar/CastControlbar";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import Spinner from "components/spinner/Spinner";
import UseScrollRestoration from "hooks/useScrollRestoration";
import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";

const IntroHeader = lazy(() => import("components/header/IntroHeader"));
const PleaseLogin = lazy(() => import("components/notfound/PleaseLogin"));
const PublicCollectionPage = lazy(
  () => import("pages/public/PublicCollectionPage")
);
const PublicDiscoverKids = lazy(
  () => import("pages/public/PublicDiscoverKids")
);
const PublicDiscoverMovies = lazy(
  () => import("pages/public/PublicDiscoverMovies")
);
const PublicDiscoverPayedMovies = lazy(
  () => import("pages/public/PublicDiscoverPayedMovies")
);
const PublicDiscoverSeries = lazy(
  () => import("pages/public/PublicDiscoverSeries")
);
const PublicEpisode = lazy(() => import("pages/public/PublicEpisode"));
const PublicHome = lazy(() => import("pages/public/PublicHome"));
const PublicKidsOverview = lazy(
  () => import("pages/public/PublicKidsOverview")
);
const PublicMovie = lazy(() => import("pages/public/PublicMovie"));
const PublicMoviesOverview = lazy(
  () => import("pages/public/PublicMoviesOverview")
);
const PublicPayedMoviesOverview = lazy(
  () => import("pages/public/PublicPayedMoviesOverview")
);
const PublicProgram = lazy(() => import("pages/public/PublicProgram"));
const PublicProvider = lazy(() => import("pages/public/PublicProvider"));
const PublicProviderOverview = lazy(
  () => import("pages/public/PublicProviderOverview")
);
const PublicSeries = lazy(() => import("pages/public/PublicSeries"));
const PublicSeriesOverview = lazy(
  () => import("pages/public/PublicSeriesOverview")
);

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default function PublicRoutes(): JSX.Element {
  return (
    <Suspense fallback={<Spinner fullPage />}>
      <UseScrollRestoration />
      <Switch>
        <Redirect exact path="/" to="/hjem" />
        <Route exact path="/hjem">
          <div className="public">
            <IntroHeader />
            <PublicHome />
          </div>
        </Route>
        <Route path="/">
          <div className="main">
            <Header loggedIn={false} />
            <QuotaBanner />
            <Wrapper>
              <Suspense fallback={<Spinner fullPage />}>
                <Switch>
                  <Route
                    path="/filmer/:contentId/:assetId?"
                    component={PublicMovie}
                  />
                  <Route
                    exact
                    path="/filmer"
                    component={PublicDiscoverMovies}
                  />
                  <Route
                    exact
                    path="/filmbutikken"
                    component={PublicDiscoverPayedMovies}
                  />
                  <Redirect exact path="/betaltfilm" to="/filmbutikken" />
                  <Route
                    exact
                    path="/filmbutikkenoversikt"
                    component={PublicPayedMoviesOverview}
                  />
                  <Route
                    exact
                    path="/filmoversikt"
                    component={PublicMoviesOverview}
                  />
                  <Route
                    exact
                    path="/serier"
                    component={PublicDiscoverSeries}
                  />
                  <Route
                    exact
                    path="/program/:contentId/:assetId?"
                    component={PublicProgram}
                  />
                  <Route exact path="/barn" component={PublicDiscoverKids} />
                  <Route
                    exact
                    path="/barnoversikt"
                    component={PublicKidsOverview}
                  />
                  <Route
                    exact
                    path="/serier/:seriesId/:seasonId?"
                    component={PublicSeries}
                  />
                  <Route
                    exact
                    path="/serieoversikt"
                    component={PublicSeriesOverview}
                  />
                  <Route
                    path="/episode/:seriesId/:assetId"
                    component={PublicEpisode}
                  />
                  <Route
                    exact
                    path="/tilbyder/:simpleName/:pageType?"
                    component={PublicProvider}
                  />
                  <Route
                    exact
                    path="/utforsk"
                    component={PublicProviderOverview}
                  />
                  <Route
                    exact
                    path="/samling/:collectionId"
                    component={PublicCollectionPage}
                  />
                  <Route path="/404" component={PleaseLogin} />
                  <Route path="/" component={PleaseLogin} />
                </Switch>
              </Suspense>
            </Wrapper>
            <Footer />
          </div>
        </Route>
      </Switch>
      <CastControlbar />
    </Suspense>
  );
}
