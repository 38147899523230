/* eslint-disable import/prefer-default-export */

/**
 * Check if the browser supports the WebP image format. This test is not
 * foolproof and can have false negatives, but no false positives. Works on
 * modern browsers.
 */
export function supportsWebp(): boolean {
  // Credit to https://stackoverflow.com/a/27232658
  const canvas = document.createElement("canvas");
  return (
    canvas.getContext("2d") !== null &&
    canvas.toDataURL("image/webp").startsWith("data:image/webp")
  );
}

let webpSupported: boolean | undefined;

function addWebpParamToUrlIfSupported(url: URL): {
  modified: boolean;
} {
  if (webpSupported === undefined) {
    webpSupported = supportsWebp();
  }

  const { searchParams, hostname, pathname } = url;

  if (
    webpSupported &&
    hostname.match(/^image(\.qa)?\.getvideo\.cloud$/i) !== null &&
    pathname.match(/.(jpe?g|png)$/) !== null &&
    !searchParams.has("webp")
  ) {
    searchParams.append("webp", "true");
    return { modified: true };
  }
  return { modified: false };
}

let base: URL | undefined;

function addWebpParamToStringIfSupported(url: string): string {
  if (base === undefined) {
    base = new URL(document.baseURI);
  }
  const urlObject = new URL(url, base);
  const { modified } = addWebpParamToUrlIfSupported(urlObject);
  if (!modified) return url;
  let newUrl = urlObject.toString();
  if (base.origin === urlObject.origin) {
    newUrl = newUrl.substring(base.origin.length);
  }
  return newUrl;
}

export function addWebpParamIfSupported(url: string): string;

export function addWebpParamIfSupported(
  url: string | undefined
): string | undefined;

export function addWebpParamIfSupported(url: URL): void;

export function addWebpParamIfSupported(
  url: string | URL | undefined
): string | undefined | void {
  if (url === undefined) {
    return undefined;
  }
  if (typeof url === "string") {
    return addWebpParamToStringIfSupported(url);
  }
  if (typeof url === "object" && url instanceof URL) {
    addWebpParamToUrlIfSupported(url);
  }
}
