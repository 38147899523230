import Icon from "@teliaplay/icon";
import PlayerButton from "components/video-player/ui/PlayerButton";
import useCast from "hooks/cast/useCast";
import useCastPlayer from "hooks/cast/useCastPlayer";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import theme from "styles/theme";

const sliderThumbStyles = css`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background: white;
`;

const Range = styled.input.attrs((props: { value: number }) => ({
  style: {
    background: `linear-gradient(to right, white ${props.value}%,
    ${theme.color.grey500} ${props.value}%, ${theme.color.grey500} 100%)`,
  },
}))`
  height: 0.2rem;
  width: 8rem;
  @media ${theme.bp.medium} {
    width: 12rem;
  }
  padding: 0;
  opacity: 0.8;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active {
    outline: none;
    opacity: 1;
  }

  /* stylelint-disable */
  -webkit-appearance: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    ${sliderThumbStyles}
  }
  &::-moz-range-thumb {
    -moz-appearance: none;
    border: none;
    ${sliderThumbStyles}
  }
  &::-moz-focus-outer {
    border: 0;
  }
  /* stylelint-enable */
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default function AudioController(): JSX.Element {
  const { player } = useCast();
  const { setVolume, isMuted, toggleMute } = useCastPlayer();
  const [volumeState, setVolumeState] = useState(
    (player?.volumeLevel || 1) * 100
  );
  const [muted, setMuted] = useState(isMuted || false);

  const toggleMuted = () => {
    toggleMute();
    if (isMuted) {
      setVolumeState((player?.volumeLevel || 1) * 100);
    } else {
      setVolumeState(0);
    }
  };

  const ChangeVolume = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "0") {
      setVolume(0);
      setVolumeState(parseInt(event.target.value, 10));
      setMuted(true);
    } else {
      setMuted(false);
      setVolumeState(parseInt(event.target.value, 10));
      setVolume(parseInt(event.target.value, 10) / 100);
    }
  };

  useEffect(() => {
    setVolumeState((player?.volumeLevel || 1) * 100);
    setMuted(isMuted);
  }, [isMuted, player?.volumeLevel]);

  return (
    <Wrapper>
      <PlayerButton
        onClick={toggleMuted}
        text={muted ? "Skru på lyd" : "Skru av lyd"}
      >
        <Icon name={muted ? "mute" : "volume"} />
      </PlayerButton>
      <Range
        type="range"
        min="0"
        max="100"
        value={volumeState}
        onChange={ChangeVolume}
      />
    </Wrapper>
  );
}
