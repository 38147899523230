import * as oidcAuth from "auth/oidcAuth";
import { IAuthContext, OIDCUser, StrategyType } from "auth/types";
import { AuthReactContext } from "components/auth/AuthProvider";
import { useContext, useEffect, useState } from "react";
import { UserData, UserDataAsync } from "types/types";
import { useReduxSelector } from "utils/ReactComponent";

export function useAuthContext(): IAuthContext {
  return useContext(AuthReactContext);
}

export function useLegacyUser(): UserData | undefined {
  return useReduxSelector(
    (state) => (state?.userDataAsync as UserDataAsync)?.data
  );
}

export function useOIDCUser(): OIDCUser | null {
  const [user, setUser] = useState<OIDCUser | null>(oidcAuth.getUser);
  useEffect(() => oidcAuth.addUserObserver(setUser), []);
  return user;
}

export function useAuthentication(): boolean | undefined {
  const { strategy } = useAuthContext();
  const legacyState = useReduxSelector(
    (state) =>
      (state?.userDataAsync as UserDataAsync)?.data?.isAuthenticated ?? false
  );

  const [oidcState, setOidcState] = useState(oidcAuth.isAuthenticated);

  useEffect(() => {
    if (strategy === StrategyType.OIDC) {
      return oidcAuth.addUserObserver(() =>
        setOidcState(oidcAuth.isAuthenticated)
      );
    }
  }, [strategy]);

  switch (strategy) {
    case StrategyType.LEGACY:
      return legacyState;
    case StrategyType.OIDC:
      return oidcState;
    default:
      return false;
  }
}
