import { CastControllerType, enableCasting } from "ducks/castController";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isChromecastPreqa } from "utils/Cookie";
import getEnvironment from "utils/Options";
import ChromecastProvider from "./ChromecastProvider";

declare global {
  interface Window {
    CAID: string;
  }
}

const getReceiverId = (): string => {
  const CHROMECAST_PREQA = isChromecastPreqa();

  const PREQA_CAID = "61DCA22C";
  const PROD_CAID = "27EC2C1E";
  const QA_CAID = "2423F684";

  if (CHROMECAST_PREQA) {
    return PREQA_CAID;
  }
  if (getEnvironment() === "qa" || getEnvironment() === "development") {
    return QA_CAID;
  }
  return PROD_CAID;
};

type RootState = {
  castController: CastControllerType;
};

type Props = {
  children: JSX.Element | JSX.Element[];
};

export default function CastProvider({ children }: Props): JSX.Element {
  const castCompatible: boolean = useSelector(
    (state: RootState) => state.castController.castEnabledDevice
  );
  const dispatch = useDispatch();

  const enableChromecast = () => {
    dispatch(enableCasting());
  };

  useEffect(() => {
    window.__onGCastApiAvailable = function (isAvailable) {
      if (isAvailable) {
        enableChromecast();
      }
    };
  }, []);

  if (!castCompatible) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  const CAID = getReceiverId();

  window.CAID = CAID;

  return (
    <ChromecastProvider
      receiverApplicationId={CAID}
      autoJoinPolicy={chrome?.cast?.AutoJoinPolicy?.PAGE_SCOPED} /* Disables
      reconnecting on refresh since it does not currently work properly.
      https://gitlab.getas.net/no.teliaplay/play-client-web/issues/2235 */
    >
      {children}
    </ChromecastProvider>
  );
}
