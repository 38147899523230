import Icon from "@teliaplay/icon";
import PlayerButton from "components/video-player/ui/PlayerButton";
import useCast from "hooks/cast/useCast";
import { useEffect, useState } from "react";
import { TriggerActionType } from "types/tracking";
import { updateTriggerAction } from "utils/tracking";

const showNextButton = () => {
  const castContext = cast.framework.CastContext;
  const castSession =
    castContext && castContext.getInstance().getCurrentSession();
  const mediaSession = castSession && castSession.getMediaSession();

  if (!mediaSession || !mediaSession.items) {
    return false;
  }

  const { currentItemId } = mediaSession;
  const lastItemId = mediaSession.items[mediaSession.items.length - 1].itemId;
  const isLastItem = currentItemId === lastItemId;

  return !isLastItem;
};

export default function NextContent(): JSX.Element | null {
  const [showButton, setShowButton] = useState(showNextButton());
  const { playerController } = useCast();

  const updateShowButton = () => {
    setShowButton(showNextButton());
  };

  useEffect(() => {
    playerController?.addEventListener(
      cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
      updateShowButton
    );

    return () => {
      playerController?.removeEventListener(
        cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
        updateShowButton
      );
    };
  });

  if (!showButton) {
    return null;
  }

  const onClick = () => {
    updateTriggerAction({
      triggerAction: TriggerActionType.NEXT_EPISODE_BUTTON,
    });
    cast.framework.CastContext?.getInstance()
      ?.getCurrentSession()
      ?.getMediaSession()
      ?.queueNext(
        () => null,
        () => null
      );
  };

  return (
    <PlayerButton onClick={onClick} text="Neste episode">
      <Icon name="skip" />
    </PlayerButton>
  );
}
