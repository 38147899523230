import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { bindActionCreators, Dispatch } from "redux";

// eslint-disable-next-line @typescript-eslint/ban-types
type NotFunction<T> = T extends Function ? never : T;

const isFunction = (
  valueOrFun: unknown | (() => unknown)
): valueOrFun is () => unknown => typeof valueOrFun === "function";

// useState cannot take functions as values as it will assume it's a factory and call it immediately. A factory
// returning a function is fine though (probably).
export type Initializer<T extends Record<string, unknown>> = {
  readonly [P in keyof T]-?: NotFunction<T[P]> | (() => NonNullable<T[P]>);
};

const __marker__ = Symbol(
  "indicates that the object uses react state-backed properties"
);

/**
 * Takes a object whose *own properties* (see {@link Object.getOwnPropertyNames}) contains a mix of initial values,
 * initializer functions, and other objects returned from this function, and returns a new object with a null prototype
 * and properties with identical keys but using getters and setters delegating to the value and {@link React.Dispatch}
 * function, respectively, returned from {@link React.useState}. To facilitate nesting are objects returned from this
 * function transferred to the new object as-is.
 *
 * NOTE: Any function values will be interpreted by {@link React.useState} as a value initializer function.
 *
 * ```
 * const state = useStateBackedProperties({
 *   a: 13,
 *   b: () => 37,
 *   c: useStateBackedProperties({
 *     d: "Hello",
 *   }),
 * });
 * ```
 * is conceptually equivalent to
 * ```
 * const state = (() => {
 *   const t = {
 *     a: useState(13),
 *     b: useState(() => 37),
 *     d: useState("Hello"),
 *   };
 *
 *   return {
 *     __proto__: null,
 *     get a() {
 *       return t.a[0];
 *     },
 *     set a(v) {
 *       t.a[1]((t.a[0] = v));
 *     },
 *     get b() {
 *       return t.b[0];
 *     },
 *     set b(v) {
 *       t.b[1]((t.b[0] = v));
 *     },
 *     c: {
 *       __proto__: null,
 *       get d() {
 *         return t.d[0];
 *       },
 *       set d(v) {
 *         t.d[1]((t.d[0] = v));
 *       },
 *     },
 *   };
 * })();
 * ```
 */
export function useStateBackedProperties<T extends Record<string, unknown>>(
  initialState: Initializer<T>
): T {
  const propNames = Object.getOwnPropertyNames(initialState);
  const props: PropertyDescriptorMap = Object.create(null);
  const baseProp: PropertyDescriptor = Object.create(null);
  baseProp.configurable = false;
  baseProp.enumerable = true;
  for (let i = 0; i < propNames.length; ++i) {
    const prop: PropertyDescriptor = Object.create(baseProp);
    const propName = propNames[i];
    const propState = initialState[propName];
    if (propState[__marker__] === true) {
      prop.writable = false;
      prop.value = propState;
    } else {
      // The properties of initialState should be the same on every call, so using useState inside this loop should be
      // fine. Wrap the value in an object to make the property name visible in the React Developer Tools.
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [originalValue, set] = useState(() => ({
        [propName]: isFunction(propState) ? propState() : propState,
      }));
      let value = originalValue;
      prop.get = () => value[propName];
      prop.set = (newValue) => {
        if (Object.is(value[propName], newValue)) {
          // Don't create a new object if the new value is the same, but let React do it's thing.
          set(value);
        } else {
          set((value = { [propName]: newValue }));
        }
      };
    }
    props[propName] = prop;
  }
  const prop: PropertyDescriptor = Object.create(baseProp);
  prop.enumerable = false;
  prop.writable = false;
  prop.value = true;
  props[__marker__] = prop;
  return Object.seal(Object.defineProperties(Object.create(null), props)) as T;
}

export type Previous<T> = Readonly<T> | undefined;

/**
 * Semi-official hack to keep around a value of a variable from the previous component render. The value is rotated with
 * an {@link useEffect} callback, i.e. after every completed render. Any changes made inside the value after using this
 * hook will remain. Pass a copy of the value to prevent this if desired.
 */
export function usePrevious<T>(value: T): Previous<T> {
  const ref = useRef<Previous<T>>(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Hook to keep track of any mutable value in a function component, similar to how one would use a instance field in a
 * class. Simple wrapper for {@link React.useRef}.
 */
export function useValue<T>(value: T): T {
  return useRef<T>(value).current;
}

/**
 * Hook replacement for withRouter, returning the same properties in a separate object.
 */
export function useRouter<
  T extends Record<string, string>
>(): RouteComponentProps<T> {
  return {
    history: useHistory(),
    location: useLocation(),
    match: useRouteMatch(),
  };
}

type AnyFunction = (...args: any) => any;

type FunctionMap = Record<string, AnyFunction>;

/**
 * Hook to access the redux dispatch function as an argument to the provided function which should return a map between
 * property names and dispatch functions, similar to connect's mapDispatchToProps parameter; or a single dispatch
 * function. See {@link useDispatch}.
 */
export function useReduxDispatch<T extends FunctionMap | AnyFunction>(
  fun: (dispatch: Dispatch<any>) => T
): T {
  return fun(useDispatch());
}

type OmitFirstArg<T> = T extends (x: any, ...args: infer U) => infer R
  ? (...args: U) => R
  : never;

/**
 * Hook to access the redux dispatch function as the first argument to the provided function. Returns a new function
 * with the rest of the arguments unbound. See also {@link useDispatch}.
 */
export function useBindReduxDispatch<
  U extends (dispatch: Dispatch<any>, ...args: any) => any
>(fun: U): OmitFirstArg<U> {
  const dispatch = useDispatch();
  return ((...args: any) => fun(dispatch, ...args)) as OmitFirstArg<U>;
}

type Action = { type: string | symbol };

type Thunk<T> = (
  dispatch: Dispatch<any>,
  getState?: any,
  extraArgument?: any
) => T; // Issue #2542

type ActionOrThunkCreator = (...args: any) => Thunk<unknown> | Action;

type ActionOrThunkCreatorFromFunction<T extends AnyFunction> = (
  ...args: Parameters<T>
) => Thunk<ReturnType<T>> | (ReturnType<T> extends void ? Action : never);

type ActionOrThunkCreatorMap = Record<string, ActionOrThunkCreator>;

type ActionOrThunkCreatorMapFromFunctionMap<T extends FunctionMap> = {
  [P in keyof T]: ActionOrThunkCreatorFromFunction<T[P]>;
};

type BoundActionOrThunk<T extends ActionOrThunkCreator> = (
  ...args: Parameters<T>
) => ReturnType<T> extends Thunk<unknown> ? ReturnType<ReturnType<T>> : void;

type BoundActionOrThunkMap<T extends ActionOrThunkCreatorMap> = {
  [P in keyof T]: BoundActionOrThunk<T[P]>;
};

/**
 * Hook to access the redux dispatch function and use it to bind the provided action creators. See
 * {@link  bindActionCreators}.
 */
export function useReduxActions<T extends ActionOrThunkCreator>(
  actionCreators: T
): BoundActionOrThunk<T>;

export function useReduxActions<T extends ActionOrThunkCreatorMap>(
  actionCreators: T
): BoundActionOrThunkMap<T>;

export function useReduxActions<T extends AnyFunction>(
  actionCreators: ActionOrThunkCreatorFromFunction<T>
): T;

export function useReduxActions<T extends FunctionMap>(
  actionCreators: ActionOrThunkCreatorMapFromFunctionMap<T>
): T;

export function useReduxActions(
  actionCreators: ActionOrThunkCreator | ActionOrThunkCreatorMap
): AnyFunction | FunctionMap {
  const dispatch = useDispatch();
  return useMemo(
    () => bindActionCreators<any, any>(actionCreators, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
}

/**
 * Hook to access the redux store's state, without having to specify the root state type. See {@link useSelector}.
 */
export function useReduxSelector<T = unknown>(
  selector: (state: any) => T, // Issue #2542
  equalityFn: (left: T, right: T) => boolean = shallowEqual
): T {
  return useSelector(selector, equalityFn);
}

/**
 * Hook to get a "forceUpdate" replacement.
 *
 * Use this to trigger an update in a functional component (which doesn't have a forceUpdate equivalent built-in). It
 * does so by modifying a dummy state on the functional component. Do not use this for class components.
 *
 * @returns A function used to trigger the update. Usable only once.
 */
export function useUpdateTrigger(): () => void {
  // Dummy state to trigger update
  let triggered = false;
  const [dummy, setDummy] = useState<number>(0);
  return () => {
    if (triggered) return;
    triggered = true;
    setDummy(dummy + 1);
  };
}

/**
 * Hook to use the value of a promise, initially returning undefined or a specified value while the promise is pending,
 * and then dispatching a state update when the promise is fulfilled.
 */
export function useValuePromise<T>(getValue: () => Promise<T>): T | undefined;

export function useValuePromise<T>(
  getValue: () => Promise<T>,
  initialValue: T | (() => T),
  deps?: React.DependencyList
): T;

export function useValuePromise<T>(
  getValue: () => Promise<T>,
  initialValue?: T | (() => T),
  deps: React.DependencyList = []
): T | undefined {
  const [value, setValue] = useState<T | undefined>(initialValue);

  useEffect(() => {
    getValue().then(setValue);
  }, deps);

  return value;
}

/**
 * Convenience function to use a string backed by local storage. Essentially it combines {@link useState} with
 * {@link window.localStorage.getItem getItem} and {@link window.localStorage.setItem setItem}.
 */
export function useLocalStorage(
  key: string,
  initialState: string | (() => string)
): [string, React.Dispatch<string>];

export function useLocalStorage(
  key: string,
  initialState?: null | (() => string | null)
): [string | null, React.Dispatch<string>];

export function useLocalStorage(
  key: string,
  initialState: string | null | (() => string | null) = null
): [string | null, React.Dispatch<string>] {
  const [value, setValue] = useState(
    () =>
      window.localStorage.getItem(key) ??
      (isFunction(initialState) ? initialState() : initialState)
  );
  return [
    value,
    useCallback(
      (newValue) => {
        window.localStorage.setItem(key, newValue);
        setValue(newValue);
      },
      [key, setValue]
    ),
  ];
}
