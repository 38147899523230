import CenteredMessage from "components/helpers/CenteredMessage";
import { BodyText, TitleText } from "components/helpers/Typography";

type Props = {
  title?: string;
  description?: string;
};

function GenericError({ title, description }: Props): JSX.Element {
  return (
    <CenteredMessage>
      <TitleText>{title || "Beklager!"}</TitleText>
      <BodyText>
        {description ||
          "Nå fungerer ikke ting som de skal. Vi jobber iherdig med å rette opp feilen."}
      </BodyText>
    </CenteredMessage>
  );
}

export default GenericError;
