import { waitForOidc } from "auth/oidcAuth";
import { StrategyType } from "auth/types";
import { getAuthContext } from "components/auth/AuthProvider";
import state from "../store";
import { isBetaUser } from "./UserUtil";

export default async function apiSWRfetcher(url: string): Promise<any> {
  await waitForOidc();
  const authContext = getAuthContext();
  const authHeaders = authContext.getAuthHeaders();

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set(
    "X-IS-BETA",
    isBetaUser(state.userDataAsync) ? "true" : "false"
  );

  Object.entries(authHeaders).forEach(([key, value]) => {
    requestHeaders.set(key, value);
  });

  return fetch(url, {
    headers: requestHeaders,
  }).then(async (response) => {
    const body = await response.json();

    if (
      response.status === 401 &&
      authContext.strategy === StrategyType.LEGACY
    ) {
      authContext.signinRedirect();
    }

    if (!response.ok) {
      body.error.status = response.status;
      throw body.error;
    }
    return body;
  });
}
