import useSignout from "auth/hooks/useSignout";
import TeliaLogo from "components/header/images/TeliaLogo";
import CenteredMessage from "components/helpers/CenteredMessage";
import { BodyText, SmallText, TitleText } from "components/helpers/Typography";
import styled from "styled-components";
import theme from "styles/theme";
import getEnvironment from "utils/Options";
import { TeliaNoButton, WhiteStyle } from "./LoginError";

const SELF_ACCESS_RECOVERY_REDIRECT_URL =
  getEnvironment() === "production"
    ? "https://www.telia.no/min-side/kontogjenoppretting"
    : "https://stage.telia.no/min-side/kontogjenoppretting";

const CUSTOMER_SERVICE_URL = "https://www.telia.no/kundeservice/kontakt-oss/";

const Divider = styled.div<{ margin?: string }>`
  margin: ${(props) => props.margin ?? "6rem"} 0;
`;

const ButtonRowHeader = styled.h2`
  font-weight: bold;
  font-size: 1.5rem;

  @media ${theme.bp.large} {
    font-size: 2.5rem;
  }

  @media ${theme.bp.huge} {
    font-size: 2.9rem;
  }
`;

const ButtonRow = styled.div`
  margin: 3rem 0;
`;

const ButtonWrap = styled.div`
  margin: 0 15px;
  display: inline flow-root;
`;

function WrappedButton(props: any): JSX.Element {
  return (
    <ButtonWrap>
      <TeliaNoButton {...props}>
        <SmallText>{props.text}</SmallText>
      </TeliaNoButton>
    </ButtonWrap>
  );
}

function SubscriptionError(): JSX.Element {
  const signout = useSignout();
  const redirectToSelfRecovery = () => {
    const url = new URL(SELF_ACCESS_RECOVERY_REDIRECT_URL);
    window.location.href = url.href;
  };

  const redirectToCustomerService = () => {
    const url = new URL(CUSTOMER_SERVICE_URL);
    window.location.href = url.href;
  };

  return (
    <WhiteStyle>
      <CenteredMessage>
        <TeliaLogo width={64} height={64} />
        <Divider margin="3rem" />
        <TitleText>Ingen abonnement funnet</TitleText>
        <BodyText>Vi fant desverre ingen abonnement på din konto.</BodyText>
        <Divider />
        <ButtonRowHeader>Har du et eksisterende TV abonnement?</ButtonRowHeader>
        <ButtonRow>
          <WrappedButton onClick={() => redirectToSelfRecovery()} text="Ja" />
          <WrappedButton
            onClick={() => redirectToCustomerService()}
            text="Nei"
          />
          <WrappedButton onClick={signout} text="Logg ut" />
        </ButtonRow>
      </CenteredMessage>
    </WhiteStyle>
  );
}

export default SubscriptionError;
