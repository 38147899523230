import { SERIES_DETAILS as FETCH } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import { createSelector } from "reselect";
import { mutate } from "swr";
import { Episode, Series, UserDataAsync } from "types/types";
import apiFetch from "utils/apiFetch";
import {
  addScheduledSingleRecordingId,
  removeNpvrAvailability,
  removeScheduledSingleRecordingId,
} from "utils/AssetUtil";
import formatCard from "utils/formatOldCard";

export type SeriesDetailsAsyncType = {
  status: string;
  fixedData?: Series;
  userDataAsync?: UserDataAsync;
  error?: Error;
};
type Action = {
  type: any; // FETCH;
  payload?: Series;
  recordingIds?: number[];
  scheduledSingleRecordingId?: number;
  assetId?: number;
  error?: Error;
};
type GetState = () => SeriesDetailsAsyncType;
type PromiseAction = Promise<Action>;
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Action[]
) => any;

const initialState = {
  status: REQ.INIT,
  fixedData: undefined,
};

const removeNpvrAsset = (data, id) => {
  if (data && data.seasons) {
    data.seasons = data.seasons.map((season) => {
      season.episodes &&
        season.episodes.map((episode) => removeNpvrAvailability(episode, id));
      return season;
    });
  } else if (data && data.episodes) {
    data.episodes = data.episodes.map(
      (episode) => removeNpvrAvailability(episode, id) || episode
    );
  }
  return data;
};

const removeNpvrAssets = (data, recordingIds) => {
  if (recordingIds && recordingIds.length === 1) {
    return removeNpvrAsset(data, recordingIds[0]);
  }
  let newData = data;

  recordingIds &&
    recordingIds.map((id) => (newData = removeNpvrAsset(newData, id)));

  return newData;
};

const addScheduledNpvrAssetToExistingData = (
  data,
  assetId,
  scheduledSingleRecordingId
) => {
  if (data && data.seasons) {
    data.seasons = data.seasons.map((season) => {
      season.episodes &&
        season.episodes.map((episode) =>
          addScheduledSingleRecordingId(
            episode,
            assetId,
            scheduledSingleRecordingId
          )
        );
      return season;
    });
  } else if (data && data.episodes) {
    data.episodes = data.episodes.map((episode) =>
      addScheduledSingleRecordingId(
        episode,
        assetId,
        scheduledSingleRecordingId
      )
    );
  }
  return data;
};

const removeScheduledNpvrAsset = (data, scheduledSingleRecordingId) => {
  if (data && data.seasons) {
    data.seasons = data.seasons.map((season) => {
      season.episodes &&
        season.episodes.map((episode) =>
          removeScheduledSingleRecordingId(episode, scheduledSingleRecordingId)
        );
      return season;
    });
  } else if (data && data.episodes) {
    data.episodes = data.episodes.map((episode) =>
      removeScheduledSingleRecordingId(episode, scheduledSingleRecordingId)
    );
  }
  return data;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state: SeriesDetailsAsyncType = initialState,
  action: Action
): SeriesDetailsAsyncType {
  const {
    type,
    payload,
    error,
    recordingIds,
    scheduledSingleRecordingId,
    assetId,
  } = action;

  switch (type) {
    case FETCH.REQUEST:
      return {
        ...state,
        status: REQ.PENDING,
      };

    case FETCH.SUCCESS:
      return {
        status: REQ.SUCCESS,
        fixedData: payload,
      };

    case FETCH.UPDATE:
      return {
        ...state,
        status: REQ.UPDATE,
      };

    case FETCH.ERROR:
      return {
        status: REQ.ERROR,
        error,
      };

    case "DELETE_NPVR":
      return {
        status: REQ.SUCCESS,
        fixedData: removeNpvrAssets(state.fixedData, recordingIds),
      };
    case "ADD_SCHEDULED_NPVR":
      return {
        status: REQ.SUCCESS,
        fixedData: addScheduledNpvrAssetToExistingData(
          state.fixedData,
          assetId,
          scheduledSingleRecordingId
        ),
      };
    case "DELETE_SCHEDULED_NPVR":
      return {
        status: REQ.SUCCESS,
        fixedData: removeScheduledNpvrAsset(
          state.fixedData,
          scheduledSingleRecordingId
        ),
      };

    case "FAVOURITE":
      return {
        status: REQ.SUCCESS,
        fixedData: { ...(state.fixedData as Series), favourite: true },
      };

    case "UNFAVOURITE":
      return {
        status: REQ.SUCCESS,
        fixedData: { ...(state.fixedData as Series), favourite: false },
      };

    default:
      return state;
  }
}

function addEpisodeInfo(episodes: Episode[], seasonIndex?: number): Episode[] {
  episodes.forEach((episode, index) => {
    episode.indexInStructure = index;
    episode.seasonIndex = seasonIndex;
    if (episode.asset.id !== undefined && episode.asset.assetId === undefined) {
      episode.asset.assetId = episode.asset.id; // TODO remove when the api returns assetId
    }
    if (episode.contentType === undefined) {
      episode.contentType = "episode";
    }
  });

  return episodes;
}

export function addSeasonInfo(content): Series {
  formatCard(content);
  if (content.flattenedStructure) {
    if (content.episodes) {
      content.episodes = addEpisodeInfo(content.episodes);
    }
    return content;
  }

  if (content.seasons) {
    content.seasons.forEach((season, seasonIndex) => {
      season.indexInStructure = seasonIndex;
      season.episodes = addEpisodeInfo(season.episodes, season.seasonIndex);
    });

    if (content.episodes && content.episodes.length > 0) {
      content.seasons.push({
        seasonIndex: "?",
        seasonLabel: "?",
        indexInStructure: content.seasons.length,
        episodes: content.episodes,
      });
    }
  }
  if (content.episodes) {
    content.episodes = addEpisodeInfo(content.episodes);
  }
  return content;
}

export function fetchSeriesDetails(
  seriesId: string,
  assetId?: string
): (dispatch: Dispatch) => void {
  return function (dispatch: Dispatch) {
    dispatch({
      type: FETCH.REQUEST,
    });

    const newBaseUrl = `api/contents/${seriesId}`;
    const url = assetId ? `${newBaseUrl}/assets/${assetId}` : newBaseUrl;

    apiFetch(url).then(
      (result) => {
        dispatch({
          type: FETCH.SUCCESS,
          payload: addSeasonInfo(result),
        });
      },
      (non2xxResponseError) => {
        dispatch({ type: FETCH.ERROR, error: non2xxResponseError });
      }
    );
  };
}

export function updateSeriesDetails(
  seriesId: number
): (dispatch: Dispatch) => void {
  return function (dispatch: Dispatch) {
    dispatch({
      type: FETCH.UPDATE,
    });

    const url = `api/contents/${seriesId.toString()}`;

    apiFetch(url).then(
      (result) => {
        dispatch({
          type: FETCH.SUCCESS,
          payload: addSeasonInfo(result),
        });
      },
      (non2xxResponseError) => {
        dispatch({ type: FETCH.ERROR, payload: non2xxResponseError });
      }
    );
  };
}

const getSeriesDetails = (state) =>
  state.seriesDetailsAsync.fixedData ? state.seriesDetailsAsync.fixedData : {};

export const getRecordingIds = createSelector(getSeriesDetails, (data) => {
  const recordingIds: number[] = [];

  if (data && data.seasons) {
    data.seasons.forEach((season) =>
      season.episodes.forEach((episode) => {
        [episode.asset].concat(episode.alternativeAssets).forEach((asset) => {
          if (asset?.recordingId) {
            recordingIds.push(asset.recordingId);
          }
        });
      })
    );
  } else if (data && data.episodes) {
    data.episodes.forEach((episode) => {
      [episode.asset].concat(episode.alternativeAssets).forEach((asset) => {
        if (asset?.recordingId) {
          recordingIds.push(asset.recordingId);
        }
      });
    });
  }
  return recordingIds;
});

export const deleteNpvrAsset = (recordId: number) =>
  function (dispatch: Dispatch) {
    dispatch({
      type: "DELETE_NPVR",
      recordingIds: [recordId],
    });
    mutate("/api/npvr/quota");
  };

export const deleteNpvrAssets = (recordIds: number[]) =>
  function (dispatch: Dispatch) {
    dispatch({
      type: "DELETE_NPVR",
      recordingIds: recordIds,
    });
    mutate("/api/npvr/quota");
  };

export const addScheduledNpvrAsset = (
  scheduledSingleRecordingId: number,
  assetId: number
) =>
  function (dispatch: Dispatch) {
    dispatch({
      type: "ADD_SCHEDULED_NPVR",
      scheduledSingleRecordingId,
      assetId,
    });
  };

export const deleteScheduledNpvrAsset = (scheduledSingleRecordingId: number) =>
  function (dispatch: Dispatch) {
    dispatch({
      type: "DELETE_SCHEDULED_NPVR",
      scheduledSingleRecordingId,
    });
  };

export const favouriteSeries = () =>
  function (dispatch: Dispatch) {
    dispatch({
      type: "FAVOURITE",
    });
  };

export const unfavouriteSeries = () =>
  function (dispatch: Dispatch) {
    dispatch({
      type: "UNFAVOURITE",
    });
  };
