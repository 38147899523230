import { useAuthentication } from "auth/hooks";
import useSignout from "auth/hooks/useSignout";
import Spinner from "components/spinner/Spinner";
import { useEffect } from "react";
import { useHistory } from "react-router";

export default function Logout(): JSX.Element {
  const signout = useSignout();
  const history = useHistory();
  const isAuthenticated = useAuthentication();

  useEffect(() => {
    if (isAuthenticated) {
      signout();
    } else if (isAuthenticated === false) {
      history.replace("/hjem");
    }
  }, [history, isAuthenticated, signout]);

  return <Spinner fullPage />;
}
