import { useContext } from "react";
import { createGlobalStyle, css } from "styled-components";
import TeliaSansBold from "styles/fonts/TTF/TeliaSans-Bold.ttf";
import TeliaSansRegular from "styles/fonts/TTF/TeliaSans-Regular.ttf";
import TeliaSansHeading from "styles/fonts/TTF/TeliaSansHeading-Heading.ttf";
import { SeoContext } from "utils/SEO";

type StyleProps = Readonly<{
  isCrawler: boolean;
}>;

const style = css`
  @font-face {
    font-family: "TeliaSans-Heading";
    src: url(${TeliaSansHeading}) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "TeliaSans";
    src: url(${TeliaSansBold}) format("truetype");
    font-weight: bold;
  }
  @font-face {
    font-family: "TeliaSans";
    src: url(${TeliaSansRegular}) format("truetype");
    font-weight: normal;
  }

  :root {
    /* Hack to silence @reach/modal style error */
    --reach-dialog: 1;
  }

  html {
    font-size: 62.5%;
  }

  body {
    background: #29003e;
    position: relative;
    font-family: "TeliaSans", Helvetica, Arial, sans-serif;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden; /* Hack to prevent overflow in TV-guide */
  }

  p,
  h1,
  h2,
  h3 {
    margin: 0;
  }

  input {
    border: none;
  }

  a:focus {
    outline: none;
  }

  ::-moz-focus-inner {
    border: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  #app {
    color: white;
  }
  .main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .public {
    background: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .btn-group {
    margin-bottom: 10px;
    a,
    button {
      margin-top: 10px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  #_hj-f5b2a1eb-9b07_feedback ._hj-f5b2a1eb-9b07_hotjar_buddy {
    bottom: 40px !important;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .modal-dialog {
    background-color: #fff;
    border: 1px solid #f2f2f2;
    border-radius: 0.2rem;
    box-shadow: 0 18px 24px 1px rgb(0 0 0 / 50%);
    font-size: 16px;
    left: 0;
    margin: 15% auto;
    outline: none;
    padding: 1.5rem;
    right: 0;
    top: 0;
    width: 90%;
    z-index: 52;
  }

  .modal-dialog-overlay {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 51;
  }

  .modal-dialog__heading {
    font-family: TeliaSans, Helvetica, Arial, sans-serif;
    font-size: 20px;
    margin: 0;
    padding-bottom: 1rem;
  }

  .modal-dialog .button--cancel {
    margin-left: 2rem;
  }

  ${({ isCrawler }: StyleProps) =>
    !isCrawler && // Workaround for SEO
    css`
      #cookie-consent-form-container {
        display: unset;
      }
    `}
`;

// The style is extracted to a CSS-block since Prettier won't format the content of createGlobalStyle
const GlobalStyle = createGlobalStyle`${style}`;

export default function RootStyle(): JSX.Element {
  return <GlobalStyle isCrawler={useContext(SeoContext).isCrawler} />;
}
