import Button from "@teliaplay/button";
import Icon from "@teliaplay/icon";
import { useAuthContext } from "auth/hooks";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import theme from "styles/theme";
import { TriggerActionType } from "types/tracking";
import { updateTriggerAction } from "utils/tracking";
import { isBetaUser } from "utils/UserUtil";
import Logo from "./images/logo";
import MobileMenuItem, { MenuItemType } from "./MobileMenuItem";

const LogoStyled = styled(Logo)`
  height: 2.7rem;
  margin-left: 1.4rem;
`;

const HamburgerButton = styled.button`
  display: inline-block;
  border: none;
  background-color: transparent;
  color: white;
  margin-left: -6px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;

  @media ${theme.newbp.medium} {
    display: none;
  }

  :hover {
    color: rgba(255, 255, 255, 0.6);
  }

  svg {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

const Wrapper = styled.div<{ active: boolean }>`
  display: ${(props): string => (props.active ? "flex" : "none")};
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
`;

const InMenuHamburger = styled.li`
  display: flex;
  align-items: center;
  height: 5.8rem;
  padding-left: 4vw;
  padding-right: 4vw;
`;

const MenuContent = styled.div`
  position: relative;
  display: flex;
  min-width: 250px;
  flex-direction: column;
  background: ${theme.color.corePurple700};
`;

const ItemList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(26, 26, 26, 0.95);
  cursor: pointer;
`;

const Spacer = styled.div`
  height: 8.2rem;
`;

type Props = {
  menuItems: MenuItemType[];
  secondMenuItems: MenuItemType[];
  loggedIn?: boolean;
};

export default function MobileMenu({
  menuItems,
  secondMenuItems,
  loggedIn,
}: Props): JSX.Element {
  const [active, setActive] = useState(false);
  const { signinRedirect } = useAuthContext();
  return (
    <>
      <HamburgerButton
        onClick={(): void => setActive(!active)}
        type="button"
        data-cy="header_menu_button"
      >
        <Icon name="burgermenu" />
      </HamburgerButton>
      <Wrapper active={active}>
        <BackgroundOverlay onClick={(): void => setActive(false)} />
        <MenuContent>
          <ItemList>
            <InMenuHamburger>
              <HamburgerButton
                onClick={(): void => setActive(!active)}
                type="button"
              >
                <Icon name="burgermenu" />
              </HamburgerButton>
              <NavLink to="/hjem">
                <LogoStyled beta={isBetaUser()} />
              </NavLink>
            </InMenuHamburger>
            {menuItems.map((item: MenuItemType) => (
              <MobileMenuItem
                key={`m${item.title}`}
                item={item}
                onClick={(): void => {
                  setActive(false);
                  updateTriggerAction({
                    triggerAction: TriggerActionType.TOP_LEVEL_MENU,
                  });
                }}
                primary
              />
            ))}
            <Spacer />
            {secondMenuItems.map((item: MenuItemType) => (
              <MobileMenuItem
                key={`m${item.title}`}
                item={item}
                onClick={(): void => setActive(false)}
              />
            ))}
            {loggedIn ? (
              <MobileMenuItem
                item={{
                  title: "Min profil",
                  url: "/profil",
                  trackingId: "profil",
                  icon: "avatar",
                }}
                onClick={(): void => setActive(false)}
              />
            ) : (
              <Button
                onClick={() => signinRedirect()}
                use="ghost"
                icon="avatar"
                text="Logg inn"
              />
            )}
          </ItemList>
        </MenuContent>
      </Wrapper>
    </>
  );
}
