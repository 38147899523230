import { useAuthContext } from "auth/hooks";
import TeliaLogo from "components/header/images/TeliaLogo";
import CenteredMessage from "components/helpers/CenteredMessage";
import { BodyText, SmallText, TitleText } from "components/helpers/Typography";
import { useHistory } from "react-router";
import styled from "styled-components";

const CUSTOMER_SERVICE_URL = "https://www.telia.no/kundeservice/kontakt-oss/";

const Divider = styled.div<{ margin?: string }>`
  margin: ${(props) => props.margin ?? "6rem"} 0;
`;

const ButtonRow = styled.div`
  margin: 3rem 0;
`;

const ButtonWrap = styled.div`
  margin: 0 15px;
  display: inline flow-root;
`;

export const WhiteStyle = styled.div`
  background: white;
  color: black;
  position: absolute;
  height: 100vh;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const TeliaNoButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #990ae3;
  border-radius: 2rem;
  color: #990ae3;
  cursor: pointer;
  display: inline-block;
  font-family: "TeliaSans-Medium", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  line-height: 1;
  margin: 0 1rem 0 0;
  padding: 1rem 1.8rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.15s, color 0.15s;

  &:hover {
    background-color: #990ae3;
    color: white;
  }
`;

type Props = {
  error?: any;
};

function LoginError({ error }: Props): JSX.Element {
  const history = useHistory();
  const { signinRedirect } = useAuthContext();

  const redirectLink = (e) => {
    e.preventDefault();
    signinRedirect();
  };

  return (
    <WhiteStyle>
      <CenteredMessage>
        <TeliaLogo width={64} height={64} />
        <Divider margin="3rem" />
        <TitleText>Det har skjedd en feil med innloggingen!</TitleText>
        <BodyText>
          Det ser ut som det har skjedd en feilkobling. <br />
          <br />
          Vær oppmerksom på at ditt nummer og din BankID må være koblet til
          samme person. Du kan ikke bruke en annen person sin BankID for å logge
          inn.
          <br />
          <br />
          For å fikse dette problemet, logg inn på nytt ved å{" "}
          <a href={`${window.location.origin}/login`}>
            klikke på denne lenken
          </a>{" "}
          For mer hjelp, sjekk{" "}
          <a href={CUSTOMER_SERVICE_URL}>ofte stilte spørsmål</a>
        </BodyText>
        <ButtonRow>
          {/* <WrappedButton onClick={() => history.replace("/hjem")} text="Lukk" /> */}
          <ButtonWrap>
            <TeliaNoButton onClick={redirectLink}>
              <SmallText>Lukk</SmallText>
            </TeliaNoButton>
          </ButtonWrap>
        </ButtonRow>
      </CenteredMessage>
    </WhiteStyle>
  );
}

export default LoginError;
