import Img from "components/helpers/Img";
import styled, { css, keyframes } from "styled-components";
import theme from "styles/theme";

const fullPageStyle = css`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99;

  top: 5.8rem;
  @media ${theme.newbp.enormous} {
    height: 8rem;
  }
`;

const Container = styled.div<{ fullPage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.fullPage && fullPageStyle}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Icon = styled(Img)`
  width: 4rem;
  height: 4rem;
  animation: ${rotate} 1.1s linear infinite;
`;

type Props = {
  fullPage?: boolean;
  className?: string;
};

function Spinner({ fullPage = false, className }: Props): JSX.Element {
  return (
    <Container fullPage={fullPage} className={className}>
      <Icon src="https://image.getvideo.cloud/static/spinner/spinner-white-large.png" />
    </Container>
  );
}

export default Spinner;
