import { IconType } from "@teliaplay/icon";
import { RecordingQuotaType } from "components/recording-quota/RecordingQuota";
import { Card, Series, UserDataAsync } from "types/types";
import { getDurationInSeconds } from "utils/DateUtil";
import { isNPVRUser } from "utils/UserUtil";
import {
  awayFromHome,
  isAvailableLiveNow,
  linearAvailabilityNotEnded,
} from "./Availability";

function hasRecordingId(card: Card): boolean {
  return card?.asset?.recordingId !== undefined;
}

export function isRecording(card: Card): boolean {
  return hasRecordingId(card);
}

export function hasRecurringRecording(card: Card): boolean {
  return !!card?.hasRecurringRecording;
}

export function hasScheduledRecording(card: Card): boolean {
  return card?.asset?.scheduledSingleRecordingId !== undefined;
}

export function hasRecording(card: Card): boolean {
  if (card?.asset?.availabilities?.npvr) {
    return card.asset.availabilities.npvr.watchable;
  }
  return (
    card?.asset?.recordingId !== undefined || card?.recordDuration !== undefined
  );
}

export function willRecord(card: Card): boolean {
  if (hasRecording(card)) {
    return false;
  }
  if (!card?.asset?.availabilities?.npvr?.watchable && hasRecordingId(card)) {
    return true;
  }
  if (card?.recordStatus) {
    return true;
  }
  return !!(hasRecurringRecording(card) || hasScheduledRecording(card));
}

function isRecurringRecordingCandidate(card): boolean {
  return card?.asset?.isRecurringRecordingCandidate;
}

export function isLiveRecurringRecordingCandidate(
  card: Card,
  currentTime: Date
): boolean {
  return (
    !!card?.asset?.subscribing &&
    !!card?.asset?.availabilities?.linear?.watchable &&
    isAvailableLiveNow(card.asset, currentTime) &&
    isRecurringRecordingCandidate(card)
  );
}

export function showRecordingButton(card: Card, currentTime: Date): boolean {
  return (
    !!card?.asset?.recordable &&
    !!card?.asset?.subscribing &&
    !isRecurringRecordingCandidate(card) &&
    (!!card?.asset?.availabilities?.linear?.watchable || awayFromHome(card)) &&
    linearAvailabilityNotEnded(card?.asset, currentTime)
  );
}

export function showDeleteButton(
  currentTime: Date,
  card: Card,
  recordings?: number[]
): boolean {
  if (
    card?.asset &&
    hasRecordingId(card) &&
    !isAvailableLiveNow(card.asset, currentTime)
  ) {
    return true;
  }
  if (card?.seriesTop === true && recordings && recordings.length > 0) {
    return true;
  }
  return false;
}

export function overRecordingQuota(
  userDataAsync?: UserDataAsync,
  recordingQuota?: RecordingQuotaType
): boolean {
  return (
    isNPVRUser(userDataAsync) &&
    !!recordingQuota &&
    Object.hasOwnProperty.call(recordingQuota, "quotaUsed") &&
    Object.hasOwnProperty.call(recordingQuota, "quotaLimit") &&
    getDurationInSeconds(recordingQuota.quotaUsed) >=
      getDurationInSeconds(recordingQuota.quotaLimit)
  );
}

export function lowRecordingQuota(
  userDataAsync?: UserDataAsync,
  recordingQuota?: RecordingQuotaType
): boolean {
  return (
    isNPVRUser(userDataAsync) &&
    !!recordingQuota &&
    Object.hasOwnProperty.call(recordingQuota, "quotaUsed") &&
    Object.hasOwnProperty.call(recordingQuota, "quotaLimit") &&
    getDurationInSeconds(recordingQuota.quotaUsed) >=
      getDurationInSeconds(recordingQuota.quotaLimit) * 0.95
  );
}

export function getNPVRType(
  card: Card & { kind?: string }
): IconType | undefined {
  if (card?.kind === "episode" && willRecord(card)) {
    return "recording";
  }
  if (card?.asset?.isRecurringRecordingCandidate) {
    return "seriesrecording";
  }
  if (hasScheduledRecording(card) || willRecord(card)) {
    return "recording";
  }
  if (hasRecording(card)) {
    return "recorded";
  }

  return undefined;
}

export function getRentalLength(card: Card): string {
  const rentDuration = card.asset.availabilities.rent?.rentDuration;

  if (rentDuration === "PT17520H") {
    return "2 år";
  }
  return "48 timer";
}

export function getRecordingIds(card: Series): number[] {
  const recordingIds: number[] = [];

  if (card && card.seasons) {
    card.seasons.forEach((season) =>
      season.episodes.forEach((episode) => {
        [episode.asset].concat(episode?.alternativeAssets).forEach((asset) => {
          if (asset?.recordingId) {
            recordingIds.push(asset.recordingId);
          }
        });
      })
    );
  }
  if (card && card.episodes) {
    card.episodes.forEach((episode) => {
      [episode.asset].concat(episode?.alternativeAssets).forEach((asset) => {
        if (asset?.recordingId) {
          recordingIds.push(asset.recordingId);
        }
      });
    });
  }
  return recordingIds;
}
