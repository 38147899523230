export default function addFakeLocationToUrl(url: string): string {
  const { search } = window.location;

  if (search.indexOf("fakeLocation=") > 0) {
    url += `${url.indexOf("?") > 0 ? "&" : "?"}fakeLocation=${search.slice(
      search.lastIndexOf("fakeLocation=") + 13
    )}`;
  }
  if (!url.startsWith("/")) {
    return `/${url}`;
  }
  return url;
}
