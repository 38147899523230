import { addSeconds, differenceInSeconds } from "date-fns";
import { createSelector } from "reselect";
import apiFetch from "utils/apiFetch";

// Events
const TIMESTAMP = {
  FETCHED: Symbol("Fetched timestamp from server"),
  REFRESH: Symbol("Refresh local timestamp"),
};

declare let __DEBUG__: boolean;

type State = {
  server: Date;
  local: Date;
};

type Action = {
  type: any;
  payload?: {
    timestamp: string;
    timestampUTC: string;
  };
};

type GetState = () => State;
type PromiseAction = Promise<Action>;
/* eslint-disable no-use-before-define */
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Action[]
) => any;
/* eslint-enable no-use-before-define */

// Reducer
const initialState = {
  server: new Date(),
  local: new Date(),
};

export default function timestamp(state: State = initialState, action: Action) {
  const { type, payload } = action;

  switch (type) {
    case TIMESTAMP.FETCHED:
      return {
        server: payload && new Date(payload.timestampUTC),
        local: new Date(),
      };

    case TIMESTAMP.REFRESH:
      return {
        ...state,
      };

    default:
      return state;
  }
}

// Action creators
function refreshLocalTimestamp() {
  return {
    type: TIMESTAMP.REFRESH,
  };
}

export function syncTimestamp(retryDelay = 5000): (dispatch: Dispatch) => void {
  return (dispatch: Dispatch) => {
    apiFetch("/api/now").then(
      (result) => {
        dispatch({ type: TIMESTAMP.FETCHED, payload: result });
        setInterval(() => dispatch(refreshLocalTimestamp()), 30000);
      },
      (error) => {
        setTimeout(() => dispatch(syncTimestamp(retryDelay * 2)), retryDelay);

        if (__DEBUG__) {
          console.error(
            "Failed to fetch timestamp:",
            error
          ); /* eslint no-console: 0 */
        }
      }
    );
  };
}

export type apiTimestamp = { timestamp: { server: Date; local: Date } };
// Selector
const getTimestamp = (state: apiTimestamp) => state.timestamp;

export const getCurrentServerTime = createSelector(
  getTimestamp,
  (timestamp) => {
    const now = new Date();
    const elapsedSinceFetch = differenceInSeconds(now, timestamp.local);

    return addSeconds(timestamp.server, elapsedSinceFetch);
  }
);
