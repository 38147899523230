import { useCallback, useEffect, useMemo, useState } from "react";
import useCast from "./useCast";

export type CastPlayerType = {
  loadMedia: (
    request: chrome.cast.media.LoadRequest
  ) => Promise<chrome.cast.ErrorCode | undefined>;
  tracks: chrome.cast.media.Track[];
  editTracks: (
    activeTrackIds: number[],
    textTrackStyle?: chrome.cast.media.TextTrackStyle | undefined
  ) => Promise<unknown>;
  currentTime: number;
  duration: number;
  toggleMute: () => void;
  setVolume: (volume: any) => void;
  togglePlay: () => void;
  seek: (time: any) => void;
  isMediaLoaded: boolean;
  isPaused: boolean;
  isMuted: boolean;
  title: string;
  subTitle?: string;
};

const useCastPlayer = (): CastPlayerType => {
  const { connected, player, playerController } = useCast();
  const [tracks, setTracks] = useState<chrome.cast.media.Track[]>([]);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [isMediaLoaded, setIsMediaLoaded] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("No title");
  const [subTitle, setSubTitle] = useState<string | undefined>(undefined);

  function resetValues() {
    setTracks([]);
    setCurrentTime(0);
    setDuration(0);
    setIsMediaLoaded(false);
    setIsPaused(false);
    setIsMuted(false);
    setTitle("No title");
  }

  useEffect(() => {
    if (!connected) {
      resetValues();
    }
  }, [connected]);

  /*
   * CurrentTime Event Listener
   */
  useEffect(() => {
    function onCurrentTimeChange(
      data: cast.framework.RemotePlayerChangedEvent
    ) {
      setCurrentTime(data.value);
    }

    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
        onCurrentTimeChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
          onCurrentTimeChange
        );
      }
    };
  }, [playerController, setCurrentTime]);

  /*
   * Duration Event Listener
   */
  useEffect(() => {
    function onDurationChange(data: cast.framework.RemotePlayerChangedEvent) {
      setDuration(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.DURATION_CHANGED,
        onDurationChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.DURATION_CHANGED,
          onDurationChange
        );
      }
    };
  }, [playerController, setDuration]);

  /*
   * IsMediaLoaded Event Listener
   */
  useEffect(() => {
    function onMediaLoadedChange(
      data: cast.framework.RemotePlayerChangedEvent
    ) {
      setIsMediaLoaded(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
        onMediaLoadedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
          onMediaLoadedChange
        );
      }
    };
  }, [playerController, setIsMediaLoaded]);

  /*
   * isPaused Event Listener
   */
  useEffect(() => {
    function onIsPausedChange(data: cast.framework.RemotePlayerChangedEvent) {
      setIsPaused(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
        onIsPausedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
          onIsPausedChange
        );
      }
    };
  }, [playerController, setIsPaused]);
  /*
   * isMuted Event Listener
   */
  useEffect(() => {
    function onIsMutedChange(data: cast.framework.RemotePlayerChangedEvent) {
      setIsMuted(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
        onIsMutedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
          onIsMutedChange
        );
      }
    };
  }, [playerController, setIsMuted]);

  useEffect(() => {
    function onMediaInfoChanged(data: cast.framework.RemotePlayerChangedEvent) {
      const newTitle = data?.value?.metadata?.title || "No title";
      const newTracks = data?.value?.tracks || [];

      if (tracks.length !== newTracks.length) {
        setTracks(newTracks);
      }
      if (title !== newTitle) {
        setTitle(newTitle);
      }
      setSubTitle(data?.value?.metadata?.subtitle);
    }

    if (playerController) {
      playerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
        onMediaInfoChanged
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
          onMediaInfoChanged
        );
      }
    };
  }, [playerController, setTitle, title, setTracks, tracks]);

  const loadMedia = useCallback((request: chrome.cast.media.LoadRequest) => {
    const castSession =
      window.cast.framework.CastContext.getInstance().getCurrentSession();

    if (castSession) {
      return castSession.loadMedia(request);
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject("No CastSession has been created");
  }, []);

  const togglePlay = useCallback(() => {
    if (playerController) {
      playerController.playOrPause();
    }
  }, [playerController]);

  const toggleMute = useCallback(() => {
    if (playerController) {
      playerController.muteOrUnmute();
    }
  }, [playerController]);

  const seek = useCallback(
    (time) => {
      if (player && playerController) {
        player.currentTime = time;
        playerController.seek();
      }
    },
    [player, playerController]
  );

  const setVolume = useCallback(
    (volume) => {
      if (player && playerController) {
        player.volumeLevel = volume;
        playerController.setVolumeLevel();
      }
    },
    [player, playerController]
  );

  const editTracks = useCallback(
    (
      activeTrackIds: number[],
      textTrackStyle?: chrome.cast.media.TextTrackStyle
    ) => {
      const castSession =
        window.cast.framework.CastContext.getInstance().getCurrentSession();

      if (castSession) {
        const tracksInfoRequest =
          new window.chrome.cast.media.EditTracksInfoRequest(
            activeTrackIds,
            textTrackStyle
          );
        const media = castSession.getMediaSession();

        if (media) {
          return new Promise((resolve, reject) => {
            media.editTracksInfo(tracksInfoRequest, resolve, reject);
          });
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject("No active media");
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject("No active cast session");
    },
    []
  );

  const value = useMemo(
    () => ({
      loadMedia,
      tracks,
      editTracks,
      currentTime,
      duration,
      toggleMute,
      setVolume,
      togglePlay,
      seek,
      isMediaLoaded,
      isPaused,
      isMuted,
      title,
      subTitle,
    }),
    [
      loadMedia,
      tracks,
      editTracks,
      currentTime,
      duration,
      toggleMute,
      setVolume,
      togglePlay,
      seek,
      isMediaLoaded,
      isPaused,
      isMuted,
      title,
      subTitle,
    ]
  );

  return value;
};

export default useCastPlayer;
