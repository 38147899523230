import MenuIcon from "components/header/MenuIcon";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import theme from "styles/theme";
import { MenuItemType } from "./MobileMenuItem";

const PrimaryStyle = css`
  font-family: "TeliaSans-Heading", "TeliaSans", Helvetica, Arial, sans-serif;
  font-weight: normal;

  @media ${theme.bp.enormous} {
    font-size: 2rem;
  }
`;

const Item = styled.li<{ primary?: "true" }>`
  display: block;
  color: white;
  font-size: 1.6rem;
  font-weight: normal;
  margin-right: 1.6rem;

  @media ${theme.bp.enormous} {
    font-size: 1.8rem;
    margin-right: 4.2rem;
  }

  ${(props) => props.primary && PrimaryStyle}
`;

const Title = styled.span<{ primary?: "true" }>`
  display: ${(props) => (props.primary ? "inline" : "none")};
  margin-left: ${(props) => (props.primary ? "0" : "0.6rem")};

  @media ${theme.newbp.large} {
    display: inline;
  }
`;

const Link = styled(NavLink)<{ primary?: "true" }>`
  cursor: pointer;
  color: inherit;
  white-space: nowrap;
  display: block;
  font-weight: normal;
  text-decoration: none;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  &:hover,
  &:focus {
    color: ${theme.color.corePurple300};
  }

  &.active {
    font-weight: ${(props) => (props.primary ? "normal" : "bold")};
    color: ${theme.color.corePurple300};
    background-color: transparent;

    &:before {
      content: none;
      border-left: none;
    }
  }
`;

type Props = {
  item: MenuItemType;
  onClick?: () => void;
  primary?: boolean;
};

function MenuItem({ item, onClick, primary }: Props): JSX.Element {
  return (
    <Item
      className={item.className}
      onClick={onClick}
      primary={primary ? "true" : undefined}
    >
      <Link
        to={item.url}
        primary={primary ? "true" : undefined}
        activeClassName="active"
        exact={item.activeIfExact}
        data-cy={`header_${item.title.toLowerCase().replace(/\s+/g, "_")}`}
        target={item.target}
      >
        <MenuIcon name={item.icon} />
        <Title primary={primary ? "true" : undefined}>{item.title}</Title>
      </Link>
    </Item>
  );
}

export default MenuItem;
