import REQ from "enums/requestStatus";
import {
  getGenreParams,
  getSortParams,
  getSourceParams,
} from "utils/searchParamas";

export function getNextOffset(list, reset, numberOfElementsToGet) {
  if (list.currentParameters.offset !== undefined && !reset) {
    return list.currentParameters.offset + numberOfElementsToGet;
  }
  return 0;
}

export function removeUnvalidGenres(genres, list) {
  const { genres: genreSelections } = list.data;

  if (!genreSelections || !genres) {
    return genres;
  }

  return genres
    .split(",")
    .filter(
      (genre) => !!genreSelections.find((s) => s.queryParameter === genre)
    )
    .join();
}

export function setSourceId(source) {
  return source.sourceType === "provider"
    ? `p${source.sourceId}`
    : `c${source.sourceId}`;
}

export function getSourceQuery(sources) {
  if (!sources) {
    return "";
  }
  const sourceList = sources.split(",");
  const channels = sourceList.filter((source) => source[0] === "c");
  const providers = sourceList.filter((source) => source[0] === "p");
  let sourceQuery = "";

  if (channels.length) {
    sourceQuery += `&channelIds=${channels.join().replace(/c/g, "")}`;
  }

  if (providers.length) {
    sourceQuery += `&providerIds=${providers.join().replace(/p/g, "")}`;
  }

  return sourceQuery;
}

export function getGenresQuery(genres, list) {
  const validGenres = removeUnvalidGenres(genres, list);
  const genresString = validGenres ? `,${validGenres}` : "";

  return `&genres=!kids${genresString}`;
}

export function fetchIfNecessary(oldData, location, fetchFunction) {
  const newSorter = getSortParams(location);
  const newGenres = getGenreParams(location);
  const newSources = getSourceParams(location);

  if (oldData.status === REQ.INIT) {
    fetchFunction(newSorter, newGenres, newSources);
    return;
  }

  const { sorter, sources, genres } = oldData.currentParameters;

  if (sorter !== newSorter || sources !== newSources || genres !== newGenres) {
    fetchFunction(newSorter, newGenres, newSources);
  }
}
