import { useAuthContext, useAuthentication } from "auth/hooks";
import { StrategyType } from "auth/types";
import { getRedirectParams } from "auth/utils";
import Spinner from "components/spinner/Spinner";
import { useEffect } from "react";
import { useHistory } from "react-router";

export default function Login(): JSX.Element {
  const history = useHistory();
  const authContext = useAuthContext();
  const isAuthenticated = useAuthentication();
  const params = getRedirectParams();

  useEffect(() => {
    if (authContext.strategy !== StrategyType.OIDC) {
      authContext.strategy = StrategyType.OIDC;
    }
  }, [authContext]);

  if (isAuthenticated) {
    history.replace(params?.returnPath ?? "/hjem");
  }

  if (authContext.strategy === StrategyType.OIDC) {
    authContext.signinRedirect();
  }

  return <Spinner fullPage />;
}
