const initialState = {
  casting: false,
  restart: false,
  castEnabledDevice: false,
  position: { streamId: undefined, value: undefined },
};

export type CastControllerType = {
  casting: boolean;
  restart: boolean;
  castEnabledDevice: boolean;
  position: {
    streamId: string;
    value: number;
  };
};

export default function Player(state = initialState, action) {
  const { type, position, restart } = action;

  switch (type) {
    case "startCasting":
      return {
        ...state,
        casting: true,
      };
    case "stopCasting":
      return {
        ...state,
        casting: false,
      };
    case "restart":
      return {
        ...state,
        restart,
      };
    case "updatePosition":
      return {
        ...state,
        position,
      };
    case "enableCasting":
      return {
        ...state,
        castEnabledDevice: true,
      };
    default:
      return state;
  }
}

export function startCasting() {
  return function (dispatch) {
    dispatch({
      type: "startCasting",
    });
  };
}

export function stopCasting() {
  return function (dispatch) {
    dispatch({
      type: "stopCasting",
    });
  };
}

export function setRestart(restart) {
  return function (dispatch) {
    dispatch({
      type: "restart",
      restart,
    });
  };
}

export function updateWatchPosition(pos, streamId) {
  return function (dispatch) {
    dispatch({
      type: "updatePosition",
      position: { value: pos, streamId },
    });
  };
}

export function enableCasting() {
  return function (dispatch) {
    dispatch({
      type: "enableCasting",
    });
  };
}
