import Icon from "@teliaplay/icon";
import PlayerButton from "components/video-player/ui/PlayerButton";
import useCast from "hooks/cast/useCast";
import useCastPlayer from "hooks/cast/useCastPlayer";
import styled from "styled-components";
import theme from "styles/theme";

const Circle = styled.div<{ playing?: "true"; isLive?: "true" }>`
  width: 100%;
  height: 100%;
  border: 0.2rem solid white;
  border-radius: 100%;
  :hover {
    border-color: ${(props) =>
      props.isLive ? theme.color.red400 : theme.color.corePurple500};
    background-color: ${(props) =>
      props.isLive ? theme.color.red400 : theme.color.corePurple500};
  }
`;

const IconStyled = styled(Icon)<{ name: "pause" | "play" }>`
  width: 100%;
  height: 100%;
  padding: 0.4rem;
`;

export default function PlayButton(): JSX.Element {
  const { isPaused, togglePlay } = useCastPlayer();
  const { player } = useCast();

  const onClick = () => {
    if (!isPaused) {
      togglePlay();
    } else {
      togglePlay();
    }
  };

  return (
    <PlayerButton onClick={onClick} text={isPaused ? "Spill av" : "Pause"}>
      <Circle
        isLive={player?.mediaInfo?.streamType === "LIVE" ? "true" : undefined}
        playing={!isPaused ? "true" : undefined}
      >
        <IconStyled name={isPaused ? "play" : "pause"} />
      </Circle>
    </PlayerButton>
  );
}
