import apiFetch from "utils/apiFetch";

type Severity = "DEBUG" | "INFO" | "WARN" | "ERROR";

type Response = {
  error: {
    userMessage: string;
  };
};

function sendErrorToApi(error: string, severity: Severity): void {
  apiFetch("/api/log", {
    method: "POST",
    body: JSON.stringify({
      message: error,
      level: severity,
    }),
  });
}

export function trackError(error: string, severity: Severity = "INFO"): void {
  sendErrorToApi(error, severity);
}

export function getUserMessage(response: Response): string {
  if (response && response.error && response.error.userMessage) {
    return response.error.userMessage;
  }
  return "Oops! Noe gikk galt.";
}

// We override the api message because it should be different for the web client
export function getSubscribingError(
  subscribingError: string | undefined
): string | undefined {
  if (subscribingError && subscribingError.includes("kanal")) {
    return "Du abonnerer ikke på denne kanalen.";
  }
  if (subscribingError) {
    return "Du abonnerer ikke på denne tjenesten.";
  }
  return undefined;
}
