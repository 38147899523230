import TeliaPlay from "./TeliaPlay.svg";

type Props = {
  width?: number;
  height?: number;
};

export default function TeliaLogo({
  width = 32,
  height = 32,
}: Props): JSX.Element {
  return (
    <svg width={width} height={height}>
      <image xlinkHref={TeliaPlay} width={width} height={height} />
    </svg>
  );
}
