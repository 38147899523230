export const getSelectedCustomerId = (): number | null => {
  const selectedCustomerId = localStorage.getItem("selectedCustomerId");

  return selectedCustomerId ? parseInt(selectedCustomerId, 10) : null;
};

export const setSelectedCustomerId = (customerId: number): void => {
  localStorage.setItem("selectedCustomerId", customerId.toString());
};

export const removeSelectedCustomerId = (): void => {
  localStorage.removeItem("selectedCustomerId");
};
