import Icon, { IconType } from "@teliaplay/icon";
import styled from "styled-components";
import theme from "styles/theme";

const IconStyled = styled(Icon)`
  height: 2rem;
  width: 2rem;
  vertical-align: middle;

  @media ${theme.bp.large} {
    vertical-align: sub;
  }
`;

type Props = {
  name?: IconType;
};

function MenuIcon({ name }: Props): JSX.Element | null {
  if (name) {
    return <IconStyled name={name} />;
  }
  return null;
}

export default MenuIcon;
