const requestStatus = {
  INIT: "INIT",
  PENDING: "PENDING",
  UPDATING: "UPDATING",
  UPDATE: "UPDATE",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

export default requestStatus;
