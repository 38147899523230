import Button from "@teliaplay/button";
import { useAuthContext } from "auth/hooks";
import ProfileDropdown from "components/profile-dropdown/ProfileDropdown";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import theme from "styles/theme";
import { TriggerActionType } from "types/tracking";
import { MenuItem as MenuItemType } from "types/types";
import { isBetaUser } from "utils/UserUtil";
import { updateTriggerAction } from "utils/tracking";
import MenuItem from "./MenuItem";
import TeliaLogo from "./images/TeliaLogo";
import BetaLogo from "./images/beta";

const MenuItemWrapper = styled.ul`
  display: none;
  margin: 0;
  padding: 0;
  align-items: center;

  @media ${theme.newbp.medium} {
    display: flex;
  }

  &:last-child {
    flex: 1;
    justify-content: flex-end;
  }
`;

const BetaLogoStyled = styled(BetaLogo)`
  height: 2.7rem;
  margin-right: 2rem;

  @media ${theme.bp.enormous} {
    height: 4.2rem;
  }
`;

const TeliaLogoWrapper = styled.div`
  height: 2.7rem;
  margin-right: 2rem;

  @media ${theme.bp.enormous} {
    height: 4.2rem;
  }
`;

function LogoStyled() {
  return isBetaUser() ? (
    <BetaLogoStyled />
  ) : (
    <TeliaLogoWrapper>
      <TeliaLogo />
    </TeliaLogoWrapper>
  );
}

type Props = {
  menuItems: MenuItemType[];
  secondMenuItems: MenuItemType[];
  loggedIn: boolean;
};

function DesktopHeader({
  menuItems,
  secondMenuItems,
  loggedIn,
}: Props): JSX.Element {
  const { signinRedirect } = useAuthContext();
  return (
    <>
      <MenuItemWrapper>
        <li>
          <NavLink
            to="/hjem"
            className="logo-link"
            activeClassName="active"
            exact
            data-cy="header_logo"
          >
            <LogoStyled />
          </NavLink>
        </li>
        {menuItems.map((item: MenuItemType) => (
          <MenuItem
            key={`d${item.title}`}
            onClick={() =>
              updateTriggerAction({
                triggerAction: TriggerActionType.TOP_LEVEL_MENU,
              })
            }
            item={item}
            primary
          />
        ))}
      </MenuItemWrapper>
      <MenuItemWrapper>
        {secondMenuItems.map((item: MenuItemType) => (
          <MenuItem key={`d${item.title}`} item={item} />
        ))}
        {loggedIn ? (
          <ProfileDropdown />
        ) : (
          <Button
            onClick={() => signinRedirect()}
            use="ghost"
            icon="avatar"
            text="Logg inn"
          />
        )}
      </MenuItemWrapper>
    </>
  );
}

export default DesktopHeader;
