import { IconType } from "@teliaplay/icon";
import MenuIcon from "components/header/MenuIcon";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Item = styled.li<{ primary?: "true" }>`
  display: block;
  color: white;
  font-size: 1.6rem;
  /* stylelint-disable font-family-no-duplicate-names, font-family-no-missing-generic-family-keyword */
  font-family: ${(props) =>
    props.primary
      ? `TeliaSans-Heading, "TeliaSans", Helvetica, Arial, sans-serif`
      : `"TeliaSans", Helvetica, Arial, sans-serif`};
`;

const Link = styled(NavLink)`
  cursor: pointer;
  color: inherit;
  white-space: nowrap;
  display: block;
  font-weight: normal;
  line-height: 2.7;
  padding-left: 4vw;
  text-decoration: none;

  &:hover,
  &:focus {
    color: rgba(255, 255, 255, 0.6);
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.07);
    border-right: 0.4rem solid white;
  }
`;

const MenuTitle = styled.span<{ primary?: "true" }>`
  vertical-align: middle;
  display: inline-block;
  margin-left: ${(props) => (props.primary ? "0" : "10px")};
`;

export type MenuItemType = {
  className?: string;
  title: string;
  activeIfExact?: boolean;
  trackingId: string;
  url: string | any;
  icon?: IconType;
  target?: string;
};

type Props = {
  item: MenuItemType;
  onClick?: () => void;
  primary?: boolean;
};

function MobileMenuItem({ item, onClick, primary }: Props): JSX.Element {
  return (
    <Item onClick={onClick} primary={primary ? "true" : undefined}>
      <Link
        to={item.url}
        activeClassName="active"
        exact={item.activeIfExact}
        data-cy={`mobile_header_${item.title
          .toLowerCase()
          .replace(/\s+/g, "_")}`}
      >
        <MenuIcon name={item.icon} />
        <MenuTitle primary={primary ? "true" : undefined}>
          {item.title}
        </MenuTitle>
      </Link>
    </Item>
  );
}

export default MobileMenuItem;
