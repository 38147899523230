import { useContext } from "react";
import CastContext, { CastContextType } from "./CastContext";

const useCast = (): CastContextType => {
  const context = useContext(CastContext);

  return { ...context };
};

export default useCast;
