import {
  differenceInCalendarDays,
  differenceInSeconds,
  format,
  isBefore,
  isSameDay,
  isSameYear,
  subDays,
} from "date-fns";
import norwegian from "date-fns/locale/nb";
import { Card } from "types/types";

export function formatNorwegian(date: Date, formatStr: string): string {
  return format(date, formatStr, { locale: norwegian });
}

export function getDurationInSeconds(duration: string): number {
  if (!duration) {
    return 0;
  }
  const regex = /T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;
  const matched = duration.match(regex);
  const hours = (matched && matched[1]) || 0;
  const minutes = (matched && matched[2]) || 0;
  const seconds = (matched && matched[3]) || 0;

  const totalSecondsRemaining =
    Math.floor(Number(seconds)) +
    Number(minutes) * 60 +
    Number(hours) * 60 * 60;

  return totalSecondsRemaining || 0;
}

export function getWeekday(currentTime: Date, contentStart: Date): string {
  const differenceInDays = differenceInCalendarDays(contentStart, currentTime);

  switch (differenceInDays) {
    case -1:
      return "i går";
    case 0:
      return "i dag";
    case 1:
      return "i morgen";
    default:
      return formatNorwegian(contentStart, "EEEE");
  }
}

export function humanizeDuration(durationInSeconds: number): string {
  const durationInMinutes = Math.floor(durationInSeconds / 60);
  let string = "";

  if (durationInMinutes >= 60) {
    const hoursLeft = Math.floor(durationInMinutes / 60);
    const minutesLeft = durationInMinutes - 60 * hoursLeft;

    string += `${hoursLeft} t`;
    if (minutesLeft > 0) {
      string += ` ${minutesLeft} min`;
    }

    return string;
  }

  return `${Math.floor(durationInMinutes)} min`;
}

const getAvailability = (
  currentTime: Date,
  contentStart: Date,
  contentEnd?: Date,
  longDay?: boolean
) => {
  if (
    contentEnd !== undefined &&
    isBefore(contentStart, currentTime) &&
    isBefore(currentTime, contentEnd)
  ) {
    return "nå";
  }

  if (isSameYear(contentStart, currentTime)) {
    const formatter = {
      lastDay: longDay ? "'i går kl.' HH:mm" : "'i går' HH:mm",
      sameDay: longDay ? "'i dag kl.' HH:mm" : "'i dag' HH:mm",
      nextDay: longDay ? "'i morgen kl.' HH:mm" : "'i morgen' HH:mm",
      lastWeek: longDay ? "EEEE d. MMM 'kl.' HH:mm" : "EEEE d. MMM HH:mm",
      nextWeek: longDay ? "EEEE d. MMM 'kl.' HH:mm" : "EEEE HH:mm",
      sameElse: longDay ? "EEEE d. MMM 'kl.' HH:mm" : "d. MMM HH:mm",
    };

    const differenceInDays = differenceInCalendarDays(
      contentStart,
      currentTime
    );

    if (isSameDay(contentStart, currentTime)) {
      return formatNorwegian(contentStart, formatter.sameDay);
    }
    if (differenceInDays === -1) {
      return formatNorwegian(contentStart, formatter.lastDay);
    }
    if (differenceInDays === 1) {
      return formatNorwegian(contentStart, formatter.nextDay);
    }
    if (differenceInDays > -7 && differenceInDays < 0) {
      return formatNorwegian(contentStart, formatter.lastWeek);
    }
    if (differenceInDays < 7 && differenceInDays > 0) {
      return formatNorwegian(contentStart, formatter.nextWeek);
    }
    return formatNorwegian(contentStart, formatter.sameElse);
  }

  if (longDay) {
    return formatNorwegian(contentStart, "EEEE d. MMM yyyy 'kl.' HH:mm");
  }
  return formatNorwegian(contentStart, "d. MMM yyyy HH:mm");
};

export const getAvailabilityFrom = (
  card: Card,
  currentTime: Date,
  longDay?: boolean
): string | undefined => {
  if (currentTime !== undefined && card?.asset?.availabilities?.linear?.start) {
    return getAvailability(
      currentTime,
      new Date(card.asset.availabilities.linear.start),
      new Date(card.asset.availabilities.linear.end),
      longDay
    );
  }
  if (currentTime !== undefined && card?.asset?.availabilities?.buy?.start) {
    return getAvailability(
      currentTime,
      new Date(card.asset.availabilities.buy.start),
      new Date(card.asset.availabilities.buy.end),
      longDay
    );
  }
  if (currentTime !== undefined && card?.asset?.availabilities?.rent?.start) {
    return getAvailability(
      currentTime,
      new Date(card.asset.availabilities.rent.start),
      new Date(card.asset.availabilities.rent.end),
      longDay
    );
  }
  if (
    currentTime !== undefined &&
    card?.asset?.availabilities?.subscription?.start
  ) {
    return getAvailability(
      currentTime,
      new Date(card.asset.availabilities.subscription.start),
      new Date(card.asset.availabilities.subscription.end),
      longDay
    );
  }
  if (
    currentTime !== undefined &&
    card?.asset?.availabilities?.external?.start
  ) {
    return getAvailability(
      currentTime,
      new Date(card.asset.availabilities.external.start),
      new Date(card.asset.availabilities.external.end),
      longDay
    );
  }
  return undefined;
};

export const getAvailabilityTo = (
  card: Card,
  currentTime: Date,
  longDay?: boolean
): string => {
  if (
    card?.validUntil !== undefined &&
    isBefore(currentTime, new Date(card.validUntil))
  ) {
    return getAvailability(
      currentTime,
      new Date(card.validUntil),
      undefined,
      longDay
    );
  }
  if (currentTime !== undefined && card?.asset?.availabilities?.catchUp) {
    return getAvailability(
      currentTime,
      new Date(card.asset.availabilities.catchUp.end),
      undefined,
      longDay
    );
  }
  return "";
};

export const formatLongDay = (date: string, currentTime: Date): string =>
  getAvailability(currentTime, new Date(date), undefined, true);

export function timeRemaining(expiration: string, currentTime: Date): string {
  const expirationDate = new Date(expiration);

  if (isBefore(expirationDate, currentTime)) {
    return "Utløpt";
  }

  const remainingSeconds = differenceInSeconds(expirationDate, currentTime);

  const start = subDays(expirationDate, 2);

  if (remainingSeconds < 60) {
    return "1 minutt igjen";
  }
  const remainingMinutes = Math.floor(remainingSeconds / 60);

  if (remainingMinutes < 60) {
    return `${remainingMinutes} minutter igjen`;
  }
  const remainingHours = Math.floor(remainingMinutes / 60);

  if (remainingHours === 1) {
    return `${remainingHours} time igjen`;
  }
  if (remainingHours < 24) {
    return `${remainingHours} timer igjen`;
  }
  const daysRemaining = Math.floor(remainingHours / 24);

  if (daysRemaining < 2 && !isSameDay(start, currentTime)) {
    return `1 dag igjen`;
  }
  if (daysRemaining < 2 && isSameDay(start, currentTime)) {
    return `2 dager igjen`;
  }
  return `${daysRemaining} dager igjen`;
}

export const formatSecondsToTimestamp = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hh = h > 9 ? h : `0${h}`;
  const mm = m > 9 ? m : `0${m}`;
  const ss = s > 9 ? s : `0${s}`;

  return hh !== "00" ? `${hh}:${mm}:${ss}` : `${mm}:${ss}`;
};

export const secondsToIso8601Duration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  return `PT${hours}H${minutes}M${seconds}S`;
};
