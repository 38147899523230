import { useAuthContext } from "auth/hooks";
import { StrategyType } from "auth/types";
import { useCallback } from "react";
import { TrackingEventType, TriggerActionType } from "types/tracking";
import { resetUserMixpanel, track } from "utils/tracking";

export default function useSignout(): () => void {
  const authContext = useAuthContext();
  return useCallback(() => {
    resetUserMixpanel();
    track(TrackingEventType.LOGOUT, {
      triggerAction: TriggerActionType.LOGOUT_BUTTON,
    });
    authContext.strategy = StrategyType.OIDC;
    authContext.signoutRedirect();
  }, [authContext]);
}
