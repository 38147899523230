/**
 * Utility function to create a promise that resolves after a specified amount
 * of time. A value of 0 indicates that the promise should resolve the next
 * event cycle. This can be used to suspend an asynchronous operation
 * temporarily.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/setTimeout setTimeout} on MDN.
 *
 * @param timeout - Time in milliseconds until the promise resolves. Defaults to 0.
 */
export default async function sleep(timeout = 0): Promise<void> {
  return new Promise((ok) => {
    setTimeout(ok, timeout);
  });
}
