import { differenceInMinutes, differenceInSeconds, isBefore } from "date-fns";
import { getCurrentServerTime } from "ducks/currentTime";
import {
  getChannelIdByName,
  getSubscribedChannels,
} from "ducks/sources/channels";
import { CHANNELS, CHANNEL_EPG_UNTIL_DAYS } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import { createSelectorCreator } from "reselect";
import { getDurationInSeconds } from "utils/DateUtil";
import shallowEquals from "utils/shallowEquals";
import epgAsyncForChannel, { fetchEpgForChannelId } from "./epgAsyncForChannel";

// Constants

// Reducer

export default function epgAll(state = {}, action) {
  switch (action.type) {
    case CHANNELS.SUCCESS:
      return action.payload.reduce((obj, channel) => {
        obj[channel.id] = epgAsyncForChannel(undefined, {});

        return obj;
      }, {});

    case CHANNEL_EPG_UNTIL_DAYS.REQUEST:
    case CHANNEL_EPG_UNTIL_DAYS.SUCCESS:
    case CHANNEL_EPG_UNTIL_DAYS.ERROR:
      return {
        ...state,
        [action.channelId]: epgAsyncForChannel(state[action.channelId], action),
      };

    default:
      return state;
  }
}

// Selectors
const createSelectorForChannelId = createSelectorCreator((resultFunc) => {
  const memoAll = {};

  return (channelId, ...args) => {
    if (!memoAll[channelId]) {
      memoAll[channelId] = {};
    }

    const memo = memoAll[channelId];

    if (!shallowEquals(memo.lastArgs, args)) {
      memo.lastArgs = args;
      memo.lastResult = resultFunc(...args);
    }

    return memo.lastResult;
  };
});

const _getChannelIdAsMemoKey = (state, channelId) => channelId;
const getEpgAsyncForChannelId = (state, channelId) =>
  state.epgAll[channelId] || epgAsyncForChannel(undefined, {});

const getEpgStatusForChannelId = createSelectorForChannelId(
  _getChannelIdAsMemoKey,
  getEpgAsyncForChannelId,
  (epgAsyncForChannel) =>
    epgAsyncForChannel ? epgAsyncForChannel.status : undefined
);

const getCurrentProgramOnChannelId = createSelectorForChannelId(
  _getChannelIdAsMemoKey,
  getEpgAsyncForChannelId,
  getCurrentServerTime,
  (epgAsyncForChannel, now) => {
    if (!epgAsyncForChannel.data || epgAsyncForChannel.data.length === 0) {
      return;
    }

    // Find first program that that is not yet finished
    return epgAsyncForChannel.data.find((program) => {
      if (!program) {
        return false;
      }
      const endTime = new Date(program.asset.availabilities.linear.end);

      return isBefore(now, endTime);
    });
  }
);

const getUpcomingProgramsOnChannelId = createSelectorForChannelId(
  _getChannelIdAsMemoKey,
  getEpgAsyncForChannelId,
  getCurrentServerTime,
  (epgAsyncForChannel, now) => {
    if (!epgAsyncForChannel.data || epgAsyncForChannel.data.length === 0) {
      return;
    }

    return epgAsyncForChannel.data.filter((program) => {
      if (!program) {
        return false;
      }

      return isBefore(now, new Date(program.asset.availabilities.linear.start));
    });
  }
);

const getLifetimeForCurrentProgramOnChannelId = createSelectorForChannelId(
  _getChannelIdAsMemoKey,
  getCurrentProgramOnChannelId,
  (currentProgram) => {
    if (!currentProgram) {
      return;
    }
    return currentProgram.asset.availabilities.linear;
  }
);

const getMinutesRemainingForCurrentProgramOnChannelId =
  createSelectorForChannelId(
    _getChannelIdAsMemoKey,
    getLifetimeForCurrentProgramOnChannelId,
    getCurrentServerTime,
    (lifetime, now) => {
      if (!lifetime) {
        return;
      }

      const endTime = new Date(lifetime.end);
      const remainingMinutes = differenceInMinutes(endTime, now);

      return Math.max(0, Math.round(remainingMinutes));
    }
  );

const getPercentageCompleteForCurrentProgramOnChannelId =
  createSelectorForChannelId(
    _getChannelIdAsMemoKey,
    getLifetimeForCurrentProgramOnChannelId,
    getCurrentServerTime,
    (lifetime, now) => {
      if (!lifetime) {
        return;
      }

      const elapsed = differenceInSeconds(now, new Date(lifetime.start));
      const precentageComplete =
        (100 * elapsed) / getDurationInSeconds(lifetime.duration);

      return Math.min(100, precentageComplete);
    }
  );

export const EpgSelectors = {
  forChannelId: {
    getEpgAsync: (state, channelId) =>
      getEpgAsyncForChannelId(state, channelId),
    getEpgStatus: (state, channelId) =>
      getEpgStatusForChannelId(state, channelId),
    getCurrentProgram: (state, channelId) =>
      getCurrentProgramOnChannelId(state, channelId),
    getUpcomingPrograms: (state, channelId) =>
      getUpcomingProgramsOnChannelId(state, channelId),
    getLifetimeForCurrentProgram: (state, channelId) =>
      getLifetimeForCurrentProgramOnChannelId(state, channelId),
    getMinutesRemainingForCurrentProgram: (state, channelId) =>
      getMinutesRemainingForCurrentProgramOnChannelId(state, channelId),
    getPercentageCompleteForCurrentProgram: (state, channelId) =>
      getPercentageCompleteForCurrentProgramOnChannelId(state, channelId),
  },
  forChannelName: {
    getEpgAsync: (state, channelName) =>
      getEpgAsyncForChannelId(state, getChannelIdByName(state, channelName)),
    getEpgStatus: (state, channelName) =>
      getEpgStatusForChannelId(state, getChannelIdByName(state, channelName)),
    getCurrentProgram: (state, channelName) =>
      getCurrentProgramOnChannelId(
        state,
        getChannelIdByName(state, channelName)
      ),
    getUpcomingPrograms: (state, channelName) =>
      getUpcomingProgramsOnChannelId(
        state,
        getChannelIdByName(state, channelName)
      ),
    getLifetimeForCurrentProgram: (state, channelName) =>
      getLifetimeForCurrentProgramOnChannelId(
        state,
        getChannelIdByName(state, channelName)
      ),
    getMinutesRemainingForCurrentProgram: (state, channelName) =>
      getMinutesRemainingForCurrentProgramOnChannelId(
        state,
        getChannelIdByName(state, channelName)
      ),
    getPercentageCompleteForCurrentProgram: (state, channelName) =>
      getPercentageCompleteForCurrentProgramOnChannelId(
        state,
        getChannelIdByName(state, channelName)
      ),
  },
};

// Action creators
export { fetchEpgForChannelId } from "./epgAsyncForChannel";

export function fetchEpgForChannelsIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    const subscribedChannels = getSubscribedChannels(state);

    return Promise.all(
      subscribedChannels.map((channel) => {
        const status = EpgSelectors.forChannelId.getEpgStatus(
          state,
          channel.id
        );

        if (status === REQ.INIT) {
          return dispatch(fetchEpgForChannelId(channel.id));
        }
        return null;
      })
    );
  };
}
