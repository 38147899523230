import styled from "styled-components";
import theme from "styles/theme";

const TeliaLinkStyled = styled.a`
  color: ${theme.color.blue400};
  cursor: pointer;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

type Props = {
  className?: string;
  href?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

export default function TeliaLink({
  className,
  href,
  children,
  onClick,
}: Props): JSX.Element {
  return (
    <TeliaLinkStyled href={href} className={className} onClick={onClick}>
      {children}
    </TeliaLinkStyled>
  );
}
