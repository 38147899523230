import RowType from "enums/RowTypes";
import mixpanel from "mixpanel-browser";
import { Card } from "types/card";
import {
  TrackingEventType,
  TrackingParameters,
  TriggerAction,
} from "types/tracking";
import getEnvironment from "utils/Options";
import apiFetch from "./apiFetch";
import { getAssetId } from "./Card";
import { getAnonymousUserId, isBetaUser } from "./UserUtil";

const superProperties = () => ({
  beta: isBetaUser(),
  $project_version: 4.2,
  $play_client: "Web",
});

declare type TrackAction = "play" | "play-external" | "view";

function getMixpanelAccesKey(): string {
  switch (getEnvironment()) {
    case "production":
      return "5418085e04c5811a50605e6f0e9858cf"; // Telia Play in mixpanel
    case "qa":
      return "7a4d634d41232e8d9d68efa317936a48"; // QA in mixpanel
    case "development":
    case "test":
    default:
      return "f174bdaaf48a1ce06445c1704ed0c4c8"; // Robin in mixpanel
  }
}

function serializeTrackingParameters(data: TrackingParameters): {
  [key: string]: string;
} {
  return Object.entries(data).reduce(
    (result, [key, val]) => ({
      ...result,
      [key]: val instanceof Array ? `[${val.sort()}]` : `${val}`,
    }),
    {}
  );
}

class MixpanelTracker {
  static instance: MixpanelTracker;

  private triggerAction: TriggerAction;

  constructor() {
    mixpanel.init(getMixpanelAccesKey());
    this.triggerAction = {};
  }

  public static getInstance(): MixpanelTracker {
    if (!MixpanelTracker.instance) {
      MixpanelTracker.instance = new MixpanelTracker();
    }
    return MixpanelTracker.instance;
  }

  public identifyUser(): void {
    mixpanel.identify(getAnonymousUserId());
  }

  public resetUser(): void {
    mixpanel.reset();
  }

  public setTriggerAction(newTriggerAction: TriggerAction): void {
    this.triggerAction = { triggerType: "UI", ...newTriggerAction };
  }

  public track(event: TrackingEventType, props?: TrackingParameters): void {
    mixpanel.track(event, {
      ...this.triggerAction,
      ...serializeTrackingParameters(props ?? {}),
      ...superProperties(),
    } as TrackingParameters);
    this.triggerAction = {};
  }
}

export function track(
  event: TrackingEventType | string,
  props?: TrackingParameters
): void {
  if (!Object.values(TrackingEventType).includes(event as TrackingEventType)) {
    return;
  }
  MixpanelTracker.getInstance().track(event as TrackingEventType, props);
}

export function updateTriggerAction(newTriggerAction: TriggerAction): void {
  MixpanelTracker.getInstance().setTriggerAction(newTriggerAction);
}

export function getTrackingContext(rowType?: RowType, title?: string): string {
  if (rowType) {
    return rowType;
  }
  if (title) {
    return title;
  }
  return "Missing context";
}

export function trackPopularContent(
  contentId: number,
  action: TrackAction,
  context?: RowType | string,
  card?: Card
): void {
  if ((action === "play" || action === "play-external") && card) {
    apiFetch("/api/clicks", {
      method: "POST",
      body: JSON.stringify({
        contentId,
        context,
        action,
        assetId: getAssetId(card),
      }),
    });
  } else if (action === "view") {
    apiFetch("/api/clicks", {
      method: "POST",
      body: JSON.stringify({
        contentId,
        context,
        action: "view",
      }),
    });
  }
}

export function identifyUserMixpanel(): void {
  MixpanelTracker.getInstance().identifyUser();
}

export function resetUserMixpanel(): void {
  MixpanelTracker.getInstance().resetUser();
}
