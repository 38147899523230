import { createContext } from "react";

export type CastContextType = {
  initialized: boolean;
  connected: boolean;
  deviceName: string;
  player: cast.framework.RemotePlayer | null;
  playerController: cast.framework.RemotePlayerController | null;
};

const CastContext = createContext<CastContextType>({
  initialized: false,
  connected: false,
  deviceName: "",
  player: null,
  playerController: null,
});
CastContext.displayName = "CastContext";

export default CastContext;
