import { CurrentParamaters } from "components/filter/Filters";
import { KIDS as FETCH } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import {
  Card,
  FilterGenreType,
  FilterSource,
  RequestStatus,
} from "types/types";
import apiFetch from "utils/apiFetch";
import {
  getNextOffset,
  getSourceQuery,
  removeUnvalidGenres,
} from "utils/Pagination";

const NUMBER_OF_ELEMENTS_TO_GET = 100;

export type KidsListAsync = {
  status: RequestStatus;
  data: {
    kids?: Card[];
    sources?: FilterSource[];
    genres?: FilterGenreType[];
  };
  currentParameters: CurrentParamaters;
};

const initialState = {
  status: REQ.INIT,
  data: {
    kids: [],
  },
  currentParameters: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, data, currentParameters } = action;

  switch (type) {
    case FETCH.REQUEST:
      return {
        ...state,
        status: REQ.PENDING,
        currentParameters,
      };
    case FETCH.SUCCESS:
      return {
        ...state,
        status: REQ.SUCCESS,
        data: {
          ...data,
          kids: currentParameters.resetOffset
            ? data.kids
            : state.data.kids.concat(data.kids),
        },
        currentParameters,
      };
    case FETCH.ERROR:
      return {
        status: REQ.ERROR,
        data: [],
      };
    default:
      return state;
  }
}

function getGenresKids(genres, list) {
  const validGenres = removeUnvalidGenres(genres, list);
  const genresString = validGenres ? `,${validGenres}` : "";

  return `&genres=${genresString}`;
}

export type FetchKids = (
  sortBy: string,
  genres: string,
  source: string,
  usePublicApi: boolean
) => void;
export function fetchKids(
  sorting = "popularity-desc",
  genres,
  sources,
  usePublicApi = false
) {
  return (dispatch, getState) => {
    const state = getState();
    const sourceQueryParams = getSourceQuery(sources);
    const genreQueryParams = getGenresKids(genres, state.kidsListAsync);
    const { currentParameters } = state.kidsListAsync;
    const {
      sorting: currentSorting,
      genres: currentGenres,
      sources: currentSources,
    } = currentParameters;
    const resetOffset =
      sorting !== currentSorting ||
      sources !== currentSources ||
      genres !== currentGenres;
    const nextOffset = getNextOffset(
      state.kidsListAsync,
      resetOffset,
      NUMBER_OF_ELEMENTS_TO_GET
    );

    dispatch({
      type: FETCH.REQUEST,
      currentParameters: {
        sorting,
        genres,
        sources,
        offset: nextOffset,
      },
    });

    apiFetch(
      usePublicApi
        ? `/api/public/contents/list?contentTypes=movie,series&availabilities=subscription,external,rent,buy&selections=genres,sources&limit=${NUMBER_OF_ELEMENTS_TO_GET}&offset=${nextOffset}&tags=kids&sort=${sorting}${genreQueryParams}${sourceQueryParams}`
        : `/api/contents/kids?availabilities=subscription,external,npvr,catchUp&selections=genres,sources&limit=${NUMBER_OF_ELEMENTS_TO_GET}&offset=${nextOffset}&sort=${sorting}${genreQueryParams}${sourceQueryParams}`
    ).then(
      (result) => {
        const { contents, genreSelections, sourceSelections, totalCount } =
          result;

        const kids = usePublicApi
          ? contents?.map((content) => ({ ...content, isPublic: true }))
          : contents;

        dispatch({
          type: FETCH.SUCCESS,
          data: {
            kids,
            genres: genreSelections,
            sources: sourceSelections,
          },
          currentParameters: {
            sorting,
            genres,
            sources,
            totalCount,
            offset: nextOffset,
            resetOffset,
          },
        });
      },
      (non2xxResponseError) => {
        dispatch({ type: FETCH.ERROR, error: non2xxResponseError });
      }
    );
  };
}
