import useCast from "hooks/cast/useCast";
import styled from "styled-components";

const CastButton = styled.div`
  margin-right: 0.4rem;
  width: 34px;
  height: 32px;
  display: inline-block;

  #castbutton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    --connected-color: white;
    opacity: 0.8;

    &:hover {
      opacity: 1;
      --disconnected-color: white;
      --connected-color: white;
    }
  }
`;

const CastDeviceName = styled.p`
  margin: 0;
  display: inline-block;
`;

const CastDeviceInfoWrapper = styled.div`
  display: flex;
`;

export default function CastDeviceInfo(): JSX.Element {
  const { deviceName } = useCast();

  return (
    <CastDeviceInfoWrapper>
      <CastButton>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button type="button" id="castbutton" is="google-cast-button" />
      </CastButton>
      <CastDeviceName>{deviceName}</CastDeviceName>
    </CastDeviceInfoWrapper>
  );
}
