import { RecordingQuotaType } from "components/recording-quota/RecordingQuota";
import Store from "store";
import { UserData, UserDataAsync } from "types/types";

export function isBetaUser(user?: UserDataAsync): boolean {
  if (user) {
    return !!user?.data?.betaAccess;
  }

  const userFromState: UserData = Store && Store.getState().userDataAsync.data;

  return userFromState?.betaAccess;
}

export function isNPVRUser(user?: UserDataAsync): boolean {
  return !!user?.data?.npvrAccess;
}

export function isTVODUser(user?: UserDataAsync): boolean {
  return !!user?.data?.tvodAccess;
}

export function notNPVRorNoRecordings(
  isNPVRUser: boolean,
  recordingQuota?: RecordingQuotaType
): boolean {
  if (!isNPVRUser) {
    return true;
  }
  if (isNPVRUser && recordingQuota?.quotaUsed === "PT0S") {
    return true;
  }
  return false;
}

export function getAnonymousUserId(user?: UserData): string {
  if (user) {
    return user.anonymousUserId;
  }
  const userFromState: UserData = Store && Store.getState().userDataAsync.data;

  return userFromState && userFromState.anonymousUserId;
}

export function getVariantGroup(user?: UserDataAsync): number | undefined {
  if (user) {
    return user?.data?.variantGroup;
  }

  const userFromState: UserData = Store && Store.getState().userDataAsync.data;

  return userFromState && userFromState.variantGroup;
}

export function isNewTVPageUser(user?: UserDataAsync): boolean {
  return isBetaUser(user);
}
