export const MINUTES_IN_DAY = 1440;

export const MINUTES_TO_START_DAY = 8 * 60;

export const NUMBER_OF_DAYS_TO_SHOW = 9;

export const SCROLL_PERIOD = 60;

export const TIME_LINE_PERIOD = 30;

export const PIXELS_PER_MINUTE = 10;

export const TIME_SLOT_WIDTH = TIME_LINE_PERIOD * PIXELS_PER_MINUTE;

export const BACKWARDS_EPG_DAYS_COUNT = 1;

export const TODAY_INDEX = BACKWARDS_EPG_DAYS_COUNT;

export const THREE_HOURS_IN_MINUTES = 180;
