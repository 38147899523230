import { useState } from "react";
import styled from "styled-components";
import theme from "styles/theme";
import { TrackingEventType } from "types/tracking";
import apiFetch from "utils/apiFetch";
import { getSelectedCustomerId, setSelectedCustomerId } from "utils/customerId";
import { track } from "utils/tracking";

const ProfileItemStyled = styled.div<{
  selected?: "true";
  selectable?: "true";
  multipleProfiles?: "true";
}>`
  display: flex;
  justify-content: space-between;
  border: 3px solid white;
  border-color: ${(props) =>
    props.selected && props.multipleProfiles
      ? theme.color.corePurple500
      : "white"};
  border-radius: 8px;
  padding: 1.5rem 1.6rem;
  margin-left: 4px;
  margin-right: 4px;

  :hover {
    color: ${(props) =>
      props.selected && props.multipleProfiles && props.selectable
        ? theme.color.corePurple400
        : "white"};
  }
`;

type Props = Readonly<{
  multipleProfiles: boolean;
  selectable: boolean;
  children?: React.ReactNode;
}>;

type LegacyProps = Props &
  Readonly<{
    userKey: string;
    selected: boolean;
  }>;

type OIDCProps = Props &
  Readonly<{
    customerIndex: number;
    customerId: number;
  }>;

export function LegacyProfileItem(props: LegacyProps): JSX.Element {
  const changeUser = (): void => {
    const { userKey, selected, multipleProfiles } = props;

    if (selected || !multipleProfiles) {
      return;
    }

    setSelectedCustomerId(Number.parseInt(userKey, 10));
    track(TrackingEventType.PROFILE_CHANGE, {});
    apiFetch("/api/user-profile/switch", {
      method: "POST",
      body: JSON.stringify(userKey),
    }).then(() => window?.top?.location.reload());
  };

  const { children, selected, multipleProfiles, selectable } = props;

  return (
    <ProfileItem
      changeUser={changeUser}
      selected={selected}
      multipleProfiles={multipleProfiles}
      selectable={selectable}
    >
      {children}
    </ProfileItem>
  );
}

export function OIDCProfileItem({
  customerId,
  customerIndex,
  selectable,
  multipleProfiles,
  children,
}: OIDCProps): JSX.Element {
  const selectedCustomerId = getSelectedCustomerId();
  const [selected, setSelected] = useState(selectedCustomerId === customerId);

  const changeUser = () => {
    if (selected || !multipleProfiles || !selectable) {
      return;
    }

    setSelected(false);
    setSelectedCustomerId(customerId);
    window?.top?.location.reload();
  };

  return (
    <ProfileItem
      changeUser={changeUser}
      selected={selected}
      multipleProfiles={multipleProfiles}
      selectable={selectable}
    >
      {children}
    </ProfileItem>
  );
}

function ProfileItem({
  changeUser,
  selected,
  multipleProfiles,
  selectable,
  children,
}: {
  changeUser: () => void;
  selected: boolean;
  multipleProfiles: boolean;
  selectable: boolean;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <ProfileItemStyled
      onClick={selectable ? changeUser : undefined}
      selected={selected ? "true" : undefined}
      multipleProfiles={multipleProfiles ? "true" : undefined}
      selectable={selectable ? "true" : undefined}
    >
      {children}
    </ProfileItemStyled>
  );
}
