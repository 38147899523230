import { addWebpParamIfSupported } from "utils/featureDetect";

export default function Img({
  src,
  srcSet,
  ...other
}: JSX.IntrinsicElements["img"]): JSX.Element {
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      src={addWebpParamIfSupported(src)}
      srcSet={srcSet
        ?.split(",")
        .map((s) =>
          s
            .trim()
            .split(/\s+/)
            .map((s, i) => (i === 0 ? addWebpParamIfSupported(s) : s))
            .join(" ")
        )
        .join(", ")}
      {...other}
    />
  );
}
