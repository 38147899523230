import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "styles/theme";

const FooterStyled = styled.footer`
  color: rgba(255, 255, 255, 0.7);
  height: 6.4rem;
  line-height: 6.4rem;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: center;
  background-color: ${theme.color.grey1000};

  a {
    display: block;
    color: rgba(255, 255, 255, 0.75);
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    text-decoration: none;

    @media ${theme.bp.medium} {
      display: inline-block;
    }

    &:hover,
    &:focus {
      color: white;
    }
  }
`;

const Divider = styled.span`
  display: none;
  @media ${theme.bp.medium} {
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export default function Footer(): JSX.Element {
  return (
    <FooterStyled role="contentinfo">
      <Link to="/om">Om Telia Play</Link>
      <Divider> | </Divider>
      <Link to="/hjelp">Hjelp</Link>
      <Divider> | </Divider>
      <a href="https://www.telia.no/">Telia.no</a>
      <Divider> | </Divider>
      <a href="https://www.telia.no/personvern/">
        Informasjonskapsler og personvern
      </a>
      {/* {getEnvironment() === "qa" && (
        <>
          <Divider> | </Divider>
          <a
            href={`https://gitlab.getas.net/no.teliaplay/play-client-web/merge_requests/${process.env.CI_MERGE_REQUEST_IID}`}
          >
            {process.env.CI_MERGE_REQUEST_SOURCE_BRANCH_NAME}
          </a>
        </>
      )} */}
    </FooterStyled>
  );
}
