import { CurrentParamaters } from "components/filter/Filters";
import { MOVIES as FETCH } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import {
  Card,
  FilterGenreType,
  FilterSource,
  RequestStatus,
} from "types/types";
import apiFetch from "utils/apiFetch";
import {
  getNextOffset,
  getSourceQuery,
  removeUnvalidGenres,
} from "utils/Pagination";

const NUMBER_OF_ELEMENTS_TO_GET = 100;

export type MoviesListAsync = {
  status: RequestStatus;
  data: {
    movies?: Card[];
    sources?: FilterSource[];
    genres?: FilterGenreType[];
  };
  currentParameters: CurrentParamaters;
};

const initialState = {
  status: REQ.INIT,
  data: {
    movies: [],
  },
  currentParameters: {
    sorting: "popularity-desc",
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, data, currentParameters } = action;

  switch (type) {
    case FETCH.REQUEST:
      return {
        ...state,
        status: REQ.PENDING,
        currentParameters,
      };
    case FETCH.SUCCESS:
      return {
        ...state,
        status: REQ.SUCCESS,
        data: {
          ...data,
          movies: currentParameters.resetOffset
            ? data.movies
            : state.data.movies.concat(data.movies),
        },
        currentParameters,
      };
    case FETCH.ERROR:
      return {
        status: REQ.ERROR,
        data: {
          movies: [],
        },
      };
    default:
      return state;
  }
}

function getGenresQueryWithoutKids(genres, list) {
  const validGenres = removeUnvalidGenres(genres, list);
  const genresString = validGenres ? `,${validGenres}` : "";

  return `&genres=!Barn${genresString}`;
}

export type FetchMovies = (
  sortBy: string,
  genres: string,
  source: string
) => void;
export function fetchMovies(
  sorting = "popularity-desc",
  usePublicApi = false,
  genres: string,
  sources: string
) {
  return (dispatch, getState) => {
    const state = getState();
    const sourceQueryParams = getSourceQuery(sources);
    const genreQueryParams = getGenresQueryWithoutKids(
      genres,
      state.moviesListAsync
    );
    const { currentParameters } = state.moviesListAsync;
    const {
      sorting: currentSorting,
      genres: currentGenres,
      sources: currentSources,
    } = currentParameters;
    const resetOffset =
      sorting !== currentSorting ||
      sources !== currentSources ||
      genres !== currentGenres;
    const nextOffset = getNextOffset(
      state.moviesListAsync,
      resetOffset,
      NUMBER_OF_ELEMENTS_TO_GET
    );

    dispatch({
      type: FETCH.REQUEST,
      currentParameters: {
        offset: nextOffset,
        sorting,
        genres,
        sources,
      },
    });

    apiFetch(
      usePublicApi
        ? `/api/public/contents/list?contentTypes=movie&availabilities=subscription,external&selections=genres,sources&limit=${NUMBER_OF_ELEMENTS_TO_GET}&offset=${nextOffset}&sort=${sorting}${genreQueryParams}${sourceQueryParams}`
        : `/api/contents/movies?availabilities=subscription,npvr,external&selections=genres,sources&limit=${NUMBER_OF_ELEMENTS_TO_GET}&offset=${nextOffset}&sort=${sorting}${genreQueryParams}${sourceQueryParams}`
    ).then(
      (result) => {
        const { contents, genreSelections, sourceSelections, totalCount } =
          result;

        const movies = usePublicApi
          ? contents?.map((content) => ({ ...content, isPublic: true }))
          : contents;

        dispatch({
          type: FETCH.SUCCESS,
          data: {
            movies,
            genres: genreSelections,
            sources: sourceSelections,
          },
          currentParameters: {
            sorting,
            genres,
            sources,
            totalCount,
            offset: nextOffset,
            resetOffset,
          },
        });
      },
      (non2xxResponseError) => {
        dispatch({ type: FETCH.ERROR, error: non2xxResponseError });
      }
    );
  };
}
