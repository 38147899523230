const { head } = document;
const scripts = head.getElementsByTagName("script");
const reference =
  scripts.length > 0 ? scripts.item(scripts.length - 1)?.nextSibling : null;

const scriptAttributes: Partial<HTMLScriptElement>[] = [
  ...(window.location.host === "qa.teliaplay.no"
    ? [
        {
          // BlueConic tracking
          src: "https://telia-no.sb.blueconic.net/script.js",
          async: true,
        },
      ]
    : []),
];

scriptAttributes.forEach((attr) => {
  const script = Object.assign(document.createElement("script"), attr);
  if (reference) {
    head.insertBefore(script, reference);
  } else {
    head.appendChild(script);
  }
});

export {};
