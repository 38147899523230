const AssetSorting = [
  {
    id: "popularitet",
    displayName: "Popularitet",
    selectedName: "sortert etter popularitet",
  },
  {
    id: "dato",
    displayName: "Sist lagt til",
    selectedName: "sortert etter sist lagt til",
  },
  {
    id: "produksjonsdato",
    displayName: "År",
    selectedName: "sortert etter år",
  },
  {
    id: "alfabetisk",
    displayName: "Alfabetisk",
    selectedName: "sortert alfabetisk",
  },
  {
    id: "imdb",
    displayName: "IMDb vurdering",
    selectedName: "sortert etter IMDb vurdering",
  },
].map((sortBy) => ({
  ...sortBy,
  selected: false,
  disabled: false,
}));

export default AssetSorting;

export function mapClientToServerSort(sortingType: string | null): string {
  if (sortingType === "dato" || sortingType === "lagt-til-synkende") {
    return "added-desc";
  }
  if (sortingType === "lagt-til-stigende") {
    return "added-asc";
  }
  if (sortingType === "alfabetisk" || sortingType === "alfabetisk-stigende") {
    return "title-asc";
  }
  if (sortingType === "alfabetisk-synkende") {
    return "title-desc";
  }
  if (
    sortingType === "produksjonsdato" ||
    sortingType === "produksjonsdato-synkende"
  ) {
    return "productionDate-desc";
  }
  if (sortingType === "produksjonsdato-stigende") {
    return "productionDate-asc";
  }
  if (sortingType === "imdb" || sortingType === "imdb-synkende") {
    return "imdb-rating-desc";
  }
  if (sortingType === "imdb-stigende") {
    return "imdb-rating-asc";
  }
  if (sortingType === "popularitet-stigende") {
    return "popularity-asc";
  }
  return "popularity-desc";
}

export function mapServerToClientSort(sortingType: string): string {
  if (sortingType === "added-desc") {
    return "lagt-til-synkende";
  }
  if (sortingType === "added-asc") {
    return "lagt-til-stigende";
  }
  if (sortingType === "title-asc") {
    return "alfabetisk-stigende";
  }
  if (sortingType === "title-desc") {
    return "alfabetisk-synkende";
  }
  if (sortingType === "productionDate-desc") {
    return "produksjonsdato-synkende";
  }
  if (sortingType === "productionDate-asc") {
    return "produksjonsdato-stigende";
  }
  if (sortingType === "imdb-rating-desc") {
    return "imdb-synkende";
  }
  if (sortingType === "imdb-rating-asc") {
    return "imdb-stigende";
  }
  if (sortingType === "popularity-asc") {
    return "popularitet-stigende";
  }
  return "popularitet-synkende";
}
