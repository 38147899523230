import { StrategyType } from "auth/types";
import { getAuthContext } from "components/auth/AuthProvider";
import { FetchError } from "types/types";
import store from "../store";
import addFakeLocationToUrl from "./addFakeLocationToUrl";
import Non2xxResponseError from "./errors";
import { isBetaUser } from "./UserUtil";

type RequestOptions = any; /* {
  headers: any;
  doNotLogErrorIfRequestFails: any;
  method?: string;
  body?: any;
}; */
const isJson = (response: any) => {
  const contentType = response.headers.get("content-type");

  return contentType && contentType.indexOf("application/json") !== -1;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default async function (
  href: string,
  requestOptions: RequestOptions = {
    headers: "",
    doNotLogErrorIfRequestFails: "",
  }
): Promise<any> {
  const { headers: requestHeaders = {} } = requestOptions;

  const authContext = getAuthContext();

  const options = {
    credentials: "same-origin",
    ...requestOptions,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(authContext.strategy === StrategyType.LEGACY && {
        "X-IS-BETA": isBetaUser(store.getState().userDataAsync),
      }),
      ...requestHeaders,
    },
  };

  const authHeaders = authContext.getAuthHeaders();
  Object.assign(options.headers, authHeaders);

  try {
    const response = await fetch(addFakeLocationToUrl(href), options);

    switch (response.status) {
      case 204:
        return;
      case 401:
        if (authContext.strategy === StrategyType.LEGACY)
          authContext.signinRedirect();
        return;
      default:
        break;
    }

    if (response.ok) {
      return response.json();
    }

    const non2xxResponseContent = isJson(response)
      ? await response.json()
      : await response.text();

    const message = `Failed to fetch ${response.url} (${response.status} ${
      response.statusText
    }). Response content: ${JSON.stringify(non2xxResponseContent)}`;

    const err: FetchError = new Non2xxResponseError(
      non2xxResponseContent,
      response.status,
      message
    );

    throw err;
  } catch (err: any) {
    if (err.name !== "AbortError") {
      throw err;
    }
  }
}
