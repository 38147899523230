import styled from "styled-components";
import theme from "styles/theme";

const Label = styled.label`
  display: none;
  font-size: 1.6rem;
  position: absolute;
  bottom: -1.2rem;
  white-space: nowrap;
  @media ${theme.bp.enormous} {
    font-size: 2rem;
    bottom: -1.6rem;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  overflow: visible;
  :hover {
    ${Label} {
      display: block;
    }
  }
`;

type ButtonProps = {
  active?: boolean;
};

const Button = styled.button<ButtonProps>`
  background: none;
  outline: none;
  border: none;
  width: 4rem;
  height: 4rem;
  padding: 0.6rem;
  font-weight: normal;
  font-size: 2rem;
  @media ${theme.bp.medium} {
    margin: 0.6rem;
  }
  @media ${theme.bp.enormous} {
    width: 4.8rem;
    height: 4.8rem;
    font-size: 2.8rem;
  }
  color: ${(props) =>
    props.active ? `${theme.color.corePurple500}` : "white"};
  transition: opacity 100ms;
  opacity: 0.8;
  display: inline-block;

  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;

type Props = {
  children: React.ReactNode;
  text: string;
  onClick: () => void;
  className?: string;
  active?: boolean;
};

export default function PlayerButton({
  active,
  children,
  className,
  onClick,
  text,
}: Props): JSX.Element {
  return (
    <Wrapper>
      <Button
        className={className}
        onClick={onClick}
        aria-label={text}
        active={active}
        id={`player-button-${text}`}
      >
        {children}
      </Button>
      <Label htmlFor={`player-button-${text}`}>{text}</Label>
    </Wrapper>
  );
}
