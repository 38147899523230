import { compareAsc, differenceInMinutes, startOfDay, subDays } from "date-fns";
import { TV_GUIDE_EPG as FETCH } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import { createSelector } from "reselect";
import apiFetch from "utils/apiFetch";
import formatCard from "utils/formatOldCard";

import { BACKWARDS_EPG_DAYS_COUNT } from "../../pages/tvguide/constants";
import { getCurrentServerTime } from "../currentTime";

const initialState = {
  status: REQ.INIT,
  data: {
    channelsEpg: [],
    daysLoaded: new Set(),
  },
};

function addChannelToProgramAssets(payload) {
  return payload.map((channelEpg) => {
    const { channel, programs } = channelEpg;

    programs.forEach((program) => {
      formatCard(program);
      program.asset.channel = channel;
    });
    return channelEpg;
  });
}

function addDatesToAvailabilities(channelEpg) {
  const { channel, programs } = channelEpg;

  programs.forEach((program) => {
    Object.keys(program.asset.availabilities).forEach((mediaForm) => {
      const availability = program.asset.availabilities[mediaForm];

      availability.startAsDate = new Date(availability.start);
      availability.endAsDate = new Date(availability.end);
      availability.durationInMinutes = differenceInMinutes(
        availability.endAsDate,
        availability.startAsDate
      );
    });
  });

  return { channel, programs };
}

function mergeWithOldChannelEpgs(channelEpg, oldChannelsEpg) {
  const { channel, programs } = channelEpg;

  const oldChannel = oldChannelsEpg.find(
    ({ channel: oldChannel }) => oldChannel.id === channel.id
  );

  if (!oldChannel) {
    return {
      channel,
      programs,
    };
  }

  const filteredNewPrograms = programs.filter(
    (p) =>
      oldChannel.programs.findIndex(
        (x) => x.asset.assetId === p.asset.assetId
      ) === -1
  );
  const allPrograms = oldChannel.programs.concat(filteredNewPrograms);

  allPrograms.sort((a, b) =>
    compareAsc(
      a.asset.availabilities.linear.startAsDate,
      b.asset.availabilities.linear.startAsDate
    )
  );

  return {
    channel,
    programs: allPrograms,
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH.REQUEST:
      return {
        ...state,
        status: REQ.PENDING,
      };

    case FETCH.SUCCESS:
      return {
        status: REQ.SUCCESS,
        data: {
          channelsEpg: payload.channelsEpg.map((channelEpg) =>
            mergeWithOldChannelEpgs(
              addDatesToAvailabilities(channelEpg),
              state.data.channelsEpg
            )
          ),
          daysLoaded: new Set([...state.data.daysLoaded, payload.day]),
        },
      };

    case FETCH.ERROR:
      return {
        status: REQ.ERROR,
      };

    default:
      return state;
  }
}

export function fetchChannelsEpg(day) {
  return function (dispatch) {
    dispatch({
      type: FETCH.REQUEST,
    });

    return apiFetch(`api/tv-guide/daily/${day}`).then(
      (result) =>
        dispatch({
          type: FETCH.SUCCESS,
          payload: {
            channelsEpg: addChannelToProgramAssets(result.content),
            day,
          },
        }),
      (non2xxResponseError) => {
        dispatch({ type: FETCH.ERROR, payload: non2xxResponseError });
      }
    );
  };
}

const epgChannels = (state) => state.tvGuideEpg.data.channelsEpg;

const getCurrentWindow = (state) => state.tvGuide.window;

export const getSlidingWindowEpg = createSelector(
  [epgChannels, getCurrentWindow, getCurrentServerTime],
  (epgChannels, window, currentTime) => {
    const startOfEpg = startOfDay(
      subDays(currentTime, BACKWARDS_EPG_DAYS_COUNT)
    );
    const startOffset = window.begin;

    const endOffset = window.end + 180;
    const filteredEpgChannels = [];

    epgChannels.forEach((item) => {
      const filteredCards = item.programs.filter((card) => {
        const startTimeOfAsset = differenceInMinutes(
          card.asset.availabilities.linear.startAsDate,
          startOfEpg
        );
        const endTimeOfAsset = differenceInMinutes(
          card.asset.availabilities.linear.endAsDate,
          startOfEpg
        );

        const assetEntirelyWithinWindow =
          startTimeOfAsset > startOffset && startTimeOfAsset < endOffset;
        const assetEndingWithinWindow =
          endTimeOfAsset < endOffset && endTimeOfAsset > startOffset;

        return assetEntirelyWithinWindow || assetEndingWithinWindow;
      });

      filteredEpgChannels.push({
        channel: item.channel,
        cards: filteredCards,
      });
    });

    return filteredEpgChannels;
  }
);
