import platform from "platform";
import getEnvironment from "utils/Options";

function ieCompatibilityMode(): boolean {
  const trident = window.navigator.userAgent.match(/Trident\/(\d+)/);

  type Value = {
    isIE: boolean;
    trueVersion: number;
    actingVersion: number;
    compatibilityMode: boolean;
  };
  const value: Value = {
    isIE: false,
    trueVersion: 0,
    actingVersion: 0,
    compatibilityMode: false,
  };

  if (trident) {
    value.isIE = true;
    value.trueVersion = parseInt(trident[1], 10) + 4;
  }

  const msie = window.navigator.userAgent.match(/MSIE (\d+)/);

  if (msie) {
    value.isIE = true;
    value.actingVersion = parseInt(msie[1], 10);
  } else {
    value.actingVersion = value.trueVersion;
  }

  if (value.isIE && value.trueVersion > 0 && value.actingVersion > 0) {
    value.compatibilityMode = value.trueVersion !== value.actingVersion;
  }

  return value.compatibilityMode;
}

function browserHasUnsupportedVersion(): boolean {
  if (!platform.name || !platform.version) {
    return false;
  }

  const { name, version, os } = platform;
  const browserName = name.toLowerCase();
  const browserVersion = parseInt(version, 10);

  if (
    os?.family === "Windows" &&
    ((browserName === "chrome" && browserVersion <= 52) ||
      (browserName === "microsoft edge" && browserVersion <= 13))
  ) {
    return true;
  }

  return (
    (browserName === "chrome" && browserVersion < 30) ||
    (browserName === "firefox" && browserVersion < 42) ||
    (browserName === "safari" && browserVersion < 8) ||
    (browserName === "opera" && browserVersion < 40) ||
    browserName === "ie" ||
    ieCompatibilityMode()
  );
}

function EMESupport(): boolean {
  return (
    "MediaKeys" in window ||
    "WebKitMediaKeys" in window ||
    "MSMediaKeys" in window
  );
}

function isIos(): boolean {
  const osFamily = platform.os?.family;

  return (
    (!!osFamily && osFamily.toLowerCase() === "ios") ||
    /iPad|iPhone|iPod/.test(window.navigator.userAgent)
  );
}

function isAndroid(): boolean {
  const osFamily = platform.os?.family;

  return !!osFamily && osFamily.toLowerCase() === "android";
}

function isMobile(): boolean {
  return isAndroid() || isIos();
}

function isChrome(): boolean {
  const { name } = platform;

  return !!name && name.toLowerCase() === "chrome";
}
function isBrowserNotSupported(): boolean {
  if (getEnvironment() === "test") {
    return false;
  }
  return isMobile() || browserHasUnsupportedVersion() || !EMESupport();
}

function isTizen(): boolean {
  return window.navigator.userAgent.includes("Tizen");
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  browser: platform,
  mobile: isMobile(),
  unsupportedBrowser: isBrowserNotSupported(),
  androidBrowser: isAndroid(),
  iOSBrowser: isIos(),
  isChrome: isChrome(),
  isTizen: isTizen(),
};
