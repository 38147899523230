import styled from "styled-components";
import theme from "styles/theme";

const PebbleBottom = styled.path`
  fill: ${theme.color.red300};
`;

const PebbleMiddle = styled.path`
  fill: ${theme.color.corePurple400};
`;

const PebbleTop = styled.path`
  fill: ${theme.color.corePurple300};
`;

const SVGStyled = styled.svg`
  transition: fill 100ms;

  &:hover
    ${/* sc-selector */ PebbleBottom},
    &:focus
    ${/* sc-selector */ PebbleBottom} {
    /* stylelint-disable-next-line  color-no-hex */
    fill: #d7628b;
  }

  &:hover
    ${/* sc-selector */ PebbleMiddle},
    &:focus
    ${/* sc-selector */ PebbleMiddle} {
    /* stylelint-disable-next-line  color-no-hex */
    fill: #9c3dc6;
  }

  &:hover
    ${/* sc-selector */ PebbleTop},
    &:focus
    ${/* sc-selector */ PebbleTop} {
    /* stylelint-disable-next-line  color-no-hex */
    fill: #ae65d2;
  }
`;

type Props = {
  className?: string;
};

export default function BetaLogo({ className }: Props): JSX.Element {
  return (
    <SVGStyled
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 187 196"
      aria-label="Telia logo"
      className={className}
    >
      <PebbleBottom
        id="Fill-1"
        style={{ fillRule: "evenodd", clipRule: "evenodd" }}
        d="M179.7,99.9c0.6-2.2,1.2-4.3,1.7-6.5c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.3,0.1-0.4,0.3
	c-5.5,11.6-13,24.2-31.1,38.1c-29.2,22.5-64,37-88.7,37c-10.7,0-19.5-2.6-25.9-7.8c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1
	c0,0,0,0.1-0.1,0.1c1.3,1.5,2.7,3,4,4.4c0,0,0.1,0.1,0.1,0.1c2.1,2.3,4.3,4.4,6.5,6.5c0,0,0,0,0.1,0c0.5,0.5,1,0.9,1.5,1.3
	c0,0,0,0,0,0c0.2,0.2,0.3,0.3,0.5,0.5c0.3,0.3,0.7,0.6,1,0.9c1.5,1.4,3.1,2.7,4.7,3.9c0,0,0,0,0,0c1.3,1,2.6,2,3.9,3c0,0,0,0,0,0
	c2.7,2,5.4,3.8,8.2,5.4c0,0,0,0,0,0c2.9,1.7,5.8,3.2,8.6,4.5c0,0,0,0,0,0c2.3,1,4.6,1.8,6.8,2.5c0,0,0,0,0,0c4,1.2,7.9,1.8,11.7,1.8
	c15.5,0,34.1-10.3,50.7-28.4c0.1-0.1,0.1-0.1,0.2-0.2c4.9-5.3,9.6-11.3,14-17.8c0,0,0,0,0,0c1.4-2.1,2.8-4.3,4.2-6.6c0,0,0,0,0,0
	c3-5,5.9-10.3,8.5-15.9l0,0c0.3-0.7,0.7-1.5,1-2.2c0,0,0,0,0,0c1.6-3.5,3.1-7.1,4.5-10.8c0,0,0,0,0,0c0.7-1.9,1.4-3.9,2.1-5.8
	c0,0,0,0,0,0c0.9-2.6,1.7-5.3,2.5-8C179.7,100,179.7,99.9,179.7,99.9"
      />
      <defs>
        <filter
          id="Adobe_OpacityMaskFilter"
          filterUnits="userSpaceOnUse"
          x="0"
          y="7.7"
          width="187"
          height="161.7"
        >
          <feColorMatrix
            type="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
          />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x="0"
        y="7.7"
        width="187"
        height="161.7"
        id="mask-2_1_"
      >
        <g className="st1">
          <polygon
            id="path-1_1_"
            className="st2"
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#FFFFFF",
            }}
            points="0,7.7 187,7.7 187,169.4 0,169.4 		"
          />
        </g>
      </mask>
      <PebbleMiddle
        id="Fill-3"
        style={{ fillRule: "evenodd", clipRule: "evenodd" }}
        d="M186.9,52.4c0-1.6,0.1-3.2,0.1-4.8c0-2-0.1-3.9-0.3-5.8c0,0,0,0,0,0c-0.2-2.1-0.4-4.1-0.8-6
	c0,0,0,0-0.1,0c-0.5-2.5-1.2-4.8-2-7c0,0,0,0,0,0c-0.7-1.9-1.5-3.6-2.4-5.3c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.4-0.4-0.7
	c0,0,0,0,0,0c-0.9-1.6-1.9-3.1-3-4.6c0,0,0,0,0,0c-1-1.2-2-2.4-3.1-3.5c0,0,0,0,0,0c-1.4-1.4-2.9-2.7-4.5-3.9c0,0,0,0,0,0
	c-1.5-1.1-3.1-2.1-4.7-3c0,0-0.1,0-0.1,0c-0.1,0.2-0.2,0.4,0,0.5c0.1,0.2,0.3,0.4,0.4,0.6c1.6,2.7,1.9,6,0.7,9.8
	c-4.7,15.4-31.9,37.6-66.2,53.9C69.9,87.1,40.7,95.8,22.2,95.8c-10.7,0-17.4-2.8-20.1-8.3c-0.7-1.3-1-3.1-0.9-5.2
	c0-0.2-0.2-0.4-0.4-0.4h0c0,0-0.1,0-0.1,0c-0.3,2.3-0.6,4.6-0.6,7c0,0.1,0,0.3,0,0.4c0,2.6,0.3,5.4,0.7,8.3c0,0,0,0,0,0
	c0.2,1.5,0.5,3,0.9,4.5c0,0,0,0,0,0c0.7,3.2,1.7,6.5,3,9.9c0,0,0,0,0,0c0.3,0.8,0.6,1.6,0.9,2.4c0,0,0,0,0,0c1,2.6,2.2,5.3,3.4,8
	c0,0,0,0,0,0c0.6,1.3,1.2,2.5,1.8,3.8l0,0c0,0,0,0.1,0.1,0.1c0.2,0.3,0.3,0.6,0.5,0.9c0,0,0,0,0,0c0.9,1.8,1.9,3.6,3,5.4
	c0,0,0,0,0,0c0.2,0.3,0.3,0.6,0.5,0.8c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0.4,0.7,0.8,1.3,1.2,2c0,0,0,0,0,0c1.2,2,2.5,4.1,3.9,6.1
	c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0.2,0.3,0.5,0.7,0.7,1c0,0,0,0,0,0c0.6,0.9,1.2,1.8,1.8,2.7c0,0,0,0,0,0c0.2,0.3,0.5,0.6,0.7,1
	c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c1.4,1.9,2.8,3.8,4.3,5.7c0,0,0,0,0.1,0c1.1,1.4,2.3,2.9,3.4,4.3c0,0,0,0,0,0
	c1.4,1.7,2.9,3.5,4.4,5.1c6.5,4.9,15,7.3,25.4,7.3c24.8,0,59.8-14.6,89.2-37.2c17.6-13.5,25.3-25.9,30.8-37.2
	c0.8-3.2,1.5-6.4,2.2-9.6c0,0,0-0.1,0-0.1c0.4-2,0.8-4,1.1-6.1c0,0,0,0,0,0c0.4-2.6,0.8-5.2,1.2-7.8c0,0,0,0,0,0
	c0.2-1.8,0.4-3.7,0.6-5.6c0,0,0,0,0,0c0.3-3.3,0.6-6.5,0.7-9.9c0,0,0,0,0,0C186.8,54.7,186.8,53.5,186.9,52.4
	C186.9,52.4,186.9,52.4,186.9,52.4"
      />
      <defs>
        <filter
          id="Adobe_OpacityMaskFilter_1_"
          filterUnits="userSpaceOnUse"
          x="0.4"
          y="0.3"
          width="167.8"
          height="96.3"
        >
          <feColorMatrix
            type="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
          />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x="0.4"
        y="0.3"
        width="167.8"
        height="96.3"
        id="mask-4_1_"
      >
        <g className="st4">
          <polygon
            id="path-3_1_"
            className="st2"
            points="0.4,0.3 168.2,0.3 168.2,96.7 0.4,96.7 		"
          />
        </g>
      </mask>
      <PebbleTop
        id="Fill-6"
        style={{ fillRule: "evenodd", clipRule: "evenodd" }}
        d="M166.6,8.4c-0.1-0.1-0.2-0.2-0.2-0.3c-2.6-1.5-5.5-2.8-8.5-3.8c-0.1,0-0.1-0.1-0.2-0.1
	c-3.1-1.1-6.3-1.9-9.6-2.5c0,0,0,0,0,0c-4.2-0.8-8.6-1.2-13-1.3c-0.8,0-1.5-0.1-2.3-0.1c-5.3,0-10.7,0.3-16.2,0.9c0,0,0,0,0,0
	c-3.7,0.4-7.4,0.9-11.1,1.6c-0.1,0-0.1,0-0.2,0c-6.6,1.2-13.1,2.8-19.6,4.7c0,0,0,0-0.1,0c-17.9,5.3-35,13.4-49.2,23.6c0,0,0,0,0,0
	c-0.4,0.3-0.7,0.5-1.1,0.8c0,0,0,0-0.1,0.1c-4.5,3.3-8.8,6.8-12.6,10.5c0,0-0.1,0.1-0.1,0.1c-3,2.9-5.7,5.9-8.2,9.1c0,0,0,0,0,0
	c-0.3,0.4-0.7,0.9-1,1.3c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-1.6,2.2-3.2,4.4-4.5,6.7c0,0,0,0,0,0c-1.5,2.5-2.8,5-3.9,7.5c0,0,0,0,0,0
	c0,0,0,0,0,0c-1.2,2.6-2.1,5.3-2.9,8c0,0,0,0.1,0,0.1c-0.7,2.7-1.3,5.4-1.6,8.2c0.1,1.6,0.4,3.1,0.9,4.3c2.8,5.8,9.9,8.8,20.9,8.8
	c18.6,0,48-8.7,78.7-23.3c34.5-16.4,61.9-38.8,66.6-54.4C168.7,14.9,168.4,11.3,166.6,8.4"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M40.7,117c-1.8,0-3.5-3-3.5-4V85.2c0-1.6,2.5-3.2,5-3.2h8.9c3.9,0,7.1,1.8,8.8,4.7c0.9,1.5,1.2,3,1.2,4.7
	c0,2.6-1.2,5-3.6,7c2.2,1,3.9,2.8,4.8,4.9c0.4,1,0.6,2.2,0.6,3.2c0,6.1-4.6,10.5-11,10.5H40.7z M44,96h6.9c2.1,0,3.7-1.9,3.7-4.1
	c0-2-1.6-3.7-3.6-3.7H44V96z M44,110.8h7.7c2.5,0,4.4-2,4.4-4.4c0-2.7-1.8-4.5-4.4-4.5H44V110.8z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M71.1,117c-1.8,0-3.5-3-3.5-4V85.2c0-1.6,2.5-3.2,5-3.2h16.5c1.2,0,1.9,0.6,1.9,1.8c0,1.8-0.8,3.6-1.8,4.5
	c-0.5,0.5-1,0.7-1.5,0.7h-13V96h12.3c1.1,0,1.8,0.5,1.8,1.7c0,2.5-1.6,4.8-3.2,4.8H74.6v7.5h15c1.2,0,1.9,0.6,1.9,1.8
	c0,1.8-0.8,3.6-1.8,4.5c-0.5,0.5-1.1,0.7-1.6,0.7H71.1z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M104.2,89.2h-6.8c-1.8,0-3.5-3-3.5-4c0-1.6,2.6-3.2,5-3.2h20.5c1.3,0,2,0.6,2,1.8c0,2.8-1.8,5.5-3.5,5.5h-6.7
	v22.6c0,2.8-1.8,5.5-3.5,5.5c-1.8,0-3.5-3-3.5-4V89.2z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M141.8,110h-14.3l-1.5,4.2c-0.6,1.8-1.9,3-3,3c-1.8,0-3.5-3-3.5-4c0-0.2,0.1-0.5,0.2-0.9L130,84.6
	c0.3-0.9,1.5-1.8,2.8-2.2c0.7-0.2,1.4-0.3,2-0.3h3.2c1,0,1.6,0.3,1.9,1.1l10,27.8c0.1,0.3,0.2,0.7,0.2,1c0,2.8-1.8,5.5-3.5,5.5
	c-0.8,0-1.8-0.6-2.6-2c-0.4-0.5-0.6-1-0.8-1.5L141.8,110z M139.8,104l-5-14.5l-5.1,14.5H139.8z"
      />
    </SVGStyled>
  );
}
