import useSWR from "swr";

type CustomerData = {
  fullName: string;
  usersWithCustomerId: Customer[];
};

type Customer = {
  customerId: number;
  street: string;
  city: string;
  hasTvProducts: boolean;
};

export default function useCustomerData(
  isAuthenticated: boolean
): Customer[] | undefined {
  const { data } = useSWR<CustomerData>(
    isAuthenticated ? `/api/customers` : null
  );

  return data?.usersWithCustomerId;
}
