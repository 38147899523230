import crawlerUserAgents from "crawler-user-agents";
import { createContext } from "react";

export function isCrawler(userAgent: string = navigator.userAgent): boolean {
  return crawlerUserAgents
    .map(({ pattern }) => RegExp(pattern).test(userAgent))
    .reduce((a, b) => a || b);
}

export const SeoContext = createContext(
  (() => {
    let value: boolean | undefined;
    return {
      get isCrawler() {
        if (value === undefined) {
          value = isCrawler();
        }
        return value;
      },
    };
  })()
);
SeoContext.displayName = "SeoContext";
