import styled from "styled-components";

const Wrapper = styled.div`
  padding-left: 4vw;
  padding-right: 4vw;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
`;

type Props = {
  children: JSX.Element;
};
export default function BannerWrapper({ children }: Props): JSX.Element {
  return <Wrapper>{children}</Wrapper>;
}
