const SET_EPG_POSITION = Symbol(`Set TV Guide EPG position`);

const SET_EPG_CURRENT_WINDOW = Symbol(`Set TV Guide EPG window`);

const initialState = {
  window: {
    begin: null,
    end: null,
  },
  position: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_EPG_POSITION:
      return {
        ...state,
        position: payload.position,
      };
    case SET_EPG_CURRENT_WINDOW:
      return {
        ...state,
        window: payload.window,
      };
    default:
      return state;
  }
}

export const setCurrentWindow = (window) => ({
  type: SET_EPG_CURRENT_WINDOW,
  payload: {
    window,
  },
});

export const setPosition = (position) => ({
  type: SET_EPG_POSITION,
  payload: {
    position,
  },
});
