import { RedirectSearchParams } from "auth/types";
import { setRedirectParams } from "./utils";

function redirect(subpath: string, params?: [string, string][]): void {
  const url = new URL("/api/redirects", window.location.origin);
  url.pathname += `/${subpath}`;
  params?.forEach(([name, value]) => url.searchParams.append(name, value));
  window.location.href = url.href;
}

function redirectUri(params?: RedirectSearchParams): [string, string] {
  return [
    "redirectUri",
    params?.returnPath
      ? setRedirectParams(new URL(params.returnPath, window.location.origin), {
          ...params,
          returnPath: undefined,
        }).href
      : new URL("/hjem", window.location.origin).href,
  ];
}

const theme: [string, string] = ["theme", "cas-theme-telia"];

export function signinRedirect(params?: RedirectSearchParams): Promise<void> {
  redirect("login", [theme, redirectUri(params)]);
  return Promise.resolve();
}

export function signoutRedirect(): Promise<void> {
  redirect("logout");
  return Promise.resolve();
}

export function ssoCheckRedirect(): Promise<void> {
  redirect("ssocheck", [theme, redirectUri()]);
  return Promise.resolve();
}
