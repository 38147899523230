/* eslint-disable */
export default function (x, y) {
  if (x === y) {
    return true;
  }
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) {
    return false;
  }
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (const p in x) {
    if (!Object.prototype.hasOwnProperty.call(x, p)) {
      continue;
    }
    // other properties were tested using x.constructor === y.constructor

    if (!Object.prototype.hasOwnProperty.call(y, p)) {
      return false;
    }
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] !== y[p]) {
      return false;
    }
    // require values to be strictly equal
  }

  for (const p in y) {
    if (
      Object.prototype.hasOwnProperty.call(y, p) &&
      !Object.prototype.hasOwnProperty.call(x, p)
    ) {
      return false;
    }
    // allows x[ p ] to be set to undefined
  }
  return true;
}
