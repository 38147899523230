/* eslint-disable react/no-multi-comp */
import { ElementType } from "react";

import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import theme from "../../styles/theme";

type Props = {
  children: React.ReactNode;
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  as?: ElementType;
};

type LinkProps = {
  children: React.ReactNode;
  className?: string;
  to: string;
};

// See responsive guide in Zeplin https://zpl.io/2yz70AG

// Used on Genre, Progress bar or other text over the row of buttons
const SmallTextStyled = styled.p`
  font-size: 16px;
  font-weight: normal;

  @media ${theme.bp.xlarge} {
    font-size: 18px;
  }

  @media ${theme.bp.enormous} {
    font-size: 20px;
  }
`;

export function SmallText(props: Props): JSX.Element {
  return <SmallTextStyled {...props}>{props.children}</SmallTextStyled>;
}

const MetaDescriptionStyled = styled.p`
  font-size: 14px;
  font-weight: normal;

  @media ${theme.bp.xlarge} {
    font-size: 16px;
  }

  @media ${theme.bp.enormous} {
    font-size: 18px;
  }
`;

export function MetaDescription(props: Props): JSX.Element {
  return (
    <MetaDescriptionStyled {...props}>{props.children}</MetaDescriptionStyled>
  );
}

// Used on upcoming programs on channel page
const MediumTextStyled = styled.p`
  font-size: 20px;
  font-weight: normal;
  line-height: 0.9;

  @media ${theme.bp.huge} {
    font-size: 24px;
  }
`;

export function MediumText(props: Props): JSX.Element {
  return <MediumTextStyled {...props}>{props.children}</MediumTextStyled>;
}

// Used on Eyebrow title
const TextStyled = styled.p`
  font-weight: normal;
  font-size: 18px;

  @media ${theme.bp.medium} {
    font-size: 20px;
  }

  @media ${theme.bp.xlarge} {
    font-size: 22px;
  }

  @media ${theme.bp.huge} {
    font-size: 24px;
  }
`;

export function Text(props: Props): JSX.Element {
  return <TextStyled {...props}>{props.children}</TextStyled>;
}

const NoHitsStyled = styled.h2`
  text-align: center;
  font-size: 30px;
  font-weight: normal;
  margin-top: 50px;
  margin-bottom: 100px;
`;

export function NoHits(props: Props): JSX.Element {
  return <NoHitsStyled {...props}>{props.children}</NoHitsStyled>;
}

// New Typography
const BodyStyle = styled.p`
  line-height: 1.3;
  font-size: 1.6rem;

  @media ${theme.bp.large} {
    font-size: 2rem;
  }

  @media ${theme.bp.huge} {
    font-size: 2.4rem;
  }
`;

export function BodyText(props: Props): JSX.Element {
  return <BodyStyle className={props.className}>{props.children}</BodyStyle>;
}

type AdditionalInformationStyleProps = {
  fw?: string;
};

const AdditionalInformationStyle = styled.p<AdditionalInformationStyleProps>`
  font-weight: ${(props): string =>
    (props.fw === "normal" && "normal") || "bold"};
  font-size: 1.4rem;

  @media ${theme.bp.large} {
    font-size: 1.5rem;
  }

  @media ${theme.bp.huge} {
    font-size: 1.6rem;
  }
`;

export function AdditionalInformationText({
  className,
  fw,
  children,
}: {
  children: React.ReactNode;
  className?: string;
  fw?: string;
}): JSX.Element {
  return (
    <AdditionalInformationStyle className={className} fw={fw}>
      {children}
    </AdditionalInformationStyle>
  );
}

const TitleStyle = css`
  font-weight: bold;
  font-size: 3.3rem;

  @media ${theme.bp.large} {
    font-size: 4.1rem;
  }

  @media ${theme.bp.huge} {
    font-size: 4.9rem;
  }
`;

const TitleTextStyle = styled.h1`
  ${TitleStyle}
`;

const TitleLinkStyle = styled(Link)`
  ${TitleStyle}
  display: block;
  width: fit-content;
  text-decoration: none;
  color: white;
  fill: white;
  cursor: pointer;

  :hover,
  :focus {
    color: ${theme.color.corePurple300};
    fill: ${theme.color.corePurple300};
  }
`;

export function TitleText(props: Props): JSX.Element {
  return (
    <TitleTextStyle className={props.className}>
      {props.children}
    </TitleTextStyle>
  );
}

export function TitleLink(props: LinkProps): JSX.Element {
  return (
    <TitleLinkStyle className={props.className} to={props.to}>
      {props.children}
    </TitleLinkStyle>
  );
}

const RowTitleStyle = css`
  font-weight: normal;
  font-size: 1.8rem;

  @media ${theme.bp.large} {
    font-size: 2.2rem;
  }

  @media ${theme.bp.huge} {
    font-size: 2.6rem;
  }
`;

const RowTitleTextStyle = styled.h2`
  ${RowTitleStyle}
`;

export function RowTitleText(props: Props): JSX.Element {
  return <RowTitleTextStyle {...props}>{props.children}</RowTitleTextStyle>;
}

const RowTitleLinkStyle = styled(Link)`
  ${RowTitleStyle}
  display: block;
  width: fit-content;
  text-decoration: none;
  color: white;
  fill: white;
  cursor: pointer;

  :hover,
  :focus {
    color: ${theme.color.corePurple300};
    fill: ${theme.color.corePurple300};
  }
`;

export function RowTitleLink(props: LinkProps): JSX.Element {
  return <RowTitleLinkStyle {...props}>{props.children}</RowTitleLinkStyle>;
}

const EyebrowStyle = styled.p`
  font-weight: normal;
  font-size: 1.7rem;

  @media ${theme.bp.large} {
    font-size: 2.1rem;
  }

  @media ${theme.bp.huge} {
    font-size: 2.5rem;
  }
`;

export function EyebrowText(props: Props): JSX.Element {
  return <EyebrowStyle {...props}>{props.children}</EyebrowStyle>;
}
