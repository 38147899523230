import { PROVIDERS as FETCH } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import { Provider } from "types/common";
import { RequestStatus } from "types/types";
import apiFetch from "utils/apiFetch";

export type ProvidersAsync = {
  status: RequestStatus;
  data: Provider[];
};

const initialState = {
  status: REQ.INIT,
  data: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH.REQUEST:
      return {
        ...state,
        status: REQ.PENDING,
      };
    case FETCH.SUCCESS:
      return {
        status: REQ.SUCCESS,
        data: payload,
      };
    case FETCH.ERROR:
      return {
        status: REQ.ERROR,
        error: payload,
        data: [],
      };
    default:
      return state;
  }
}

export function fetchProviders() {
  const url = "/api/providers/all";

  return (dispatch) => {
    dispatch({ type: FETCH.REQUEST });

    apiFetch(url).then(
      (result) => {
        dispatch({ type: FETCH.SUCCESS, payload: result });
      },
      (non2xxResponseError) => {
        dispatch({ type: FETCH.ERROR, payload: non2xxResponseError });
      }
    );
  };
}

export const getProviderFromState = (state, providerName: string) => {
  const providers = state.providersAsync.data;

  return (
    providers &&
    providers.find((provider: Provider) => provider.simpleName === providerName)
  );
};
