const theme = {
  color: {
    corePurple100: "#f9eefe",
    corePurple200: "#f2dbfd",
    corePurple300: "#c86af8",
    corePurple400: "#af3ce8",
    corePurple500: "#990ae3",
    corePurple600: "#7907b3",
    corePurple700: "#570580",
    corePurple800: "#380354",
    corePurple900: "#1f012f",
    grey100: "#fbfbfb",
    grey200: "#f2f2f2",
    grey300: "#cccccc",
    grey400: "#a0a0a0",
    grey500: "#6e6e6e",
    grey600: "#5e5e5e",
    grey700: "#4e4e4e",
    grey800: "#303030",
    grey900: "#222222",
    grey1000: "#1a1a1a",
    green100: "#f3fff9",
    green200: "#d9ffeb",
    green300: "#40ff9f",
    green400: "#00f37a",
    green500: "#00d066",
    green700: "#008944",
    red100: "#fff5f9",
    red200: "#ffe0eb",
    red300: "#ff6699",
    red400: "#ff3377",
    red500: "#e4175c",
    red600: "#bf134d",
    recordRed: "#ff0400",
    orange100: "#fff7eb",
    orange200: "#ffedd0",
    orange300: "#ffd06b",
    orange400: "#ffb84d",
    orange500: "#ff9b00",
    orange600: "#dd8804",
    blue100: "#edf8ff",
    blue200: "#d4eeff",
    blue300: "#73c7ff",
    blue400: "#40b2ff",
    blue500: "#0099ff",
    blue600: "#0082d9",
    yellow400: "#ffed7f",
    yellow500: "#ffdc00",
    yellow600: "#ffbc00",
    pink400: "#ff64cd",
    pink500: "#ff00cd",
    pink600: "#d22db9",
  },
  bp: {
    small: "(min-width: 375px)",
    medium: "(min-width: 720px)",
    large: "(min-width: 980px)",
    xlarge: "(min-width: 1280px)",
    xxlarge: "(min-width: 1600px)",
    huge: "(min-width: 1900px)",
    enormous: "(min-width: 2400px)",
  },
  newbp: {
    small: "(min-width: 576px)",
    medium: "(min-width: 768px)",
    large: "(min-width: 992px)",
    huge: "(min-width: 1200px)",
    enormous: "(min-width: 1700px)",
  },
};

export default theme;
