export const zapperToggleUnderstoodCookie = "zapperToggleUnderstood";
export const npvrUnderstoodCookie = "npvrUnderstood";
export const quotaBannerHiddenCookie = "quotaBannerHidden";
export const chromecastPreqaCookie = "chromecast_preqa";
export const newIAInfoCookie = "newIAInfo";

export function getCookie(cookieName: string): string | null {
  const match = document.cookie.match(
    RegExp(`(?:^|;\\s*)${cookieName}=([^;]*)`)
  );

  return match ? match[1] : null;
}

function isCookieTrue(cookieName: string) {
  return getCookie(cookieName) === "true";
}

function setCookie(name: string) {
  document.cookie = `${name}=true;max-age=31536000;path=/`;
}

// Zapper Modal
export function persistZapperModalUnderstood(): void {
  setCookie(zapperToggleUnderstoodCookie);
}

export function showZapperInfo(): boolean {
  return !isCookieTrue(zapperToggleUnderstoodCookie);
}

// NPVR Modal
export function persistNpvrModalUnderstood(): void {
  setCookie(npvrUnderstoodCookie);
}

export function showNPVRInfo(): boolean {
  return !isCookieTrue(npvrUnderstoodCookie);
}

// Quota Banner
export function persistQuotaBannerHidden(): void {
  setCookie(quotaBannerHiddenCookie);
}

export function isQuotaBannerHidden(): boolean {
  return isCookieTrue(quotaBannerHiddenCookie);
}

// Chromecast Preqa
export function presistChromecastPreqa(): void {
  setCookie(chromecastPreqaCookie);
}

export function isChromecastPreqa(): boolean {
  return isCookieTrue(chromecastPreqaCookie);
}

// New IA Info
export function persistNewIAInfoModalUnderstood(): void {
  setCookie(newIAInfoCookie);
}

export function showNewIAInfo(): boolean {
  return !isCookieTrue(newIAInfoCookie);
}
