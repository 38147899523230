import getEnvironment from "utils/Options";

export function channelPickerPath(target: string): string {
  return `/api/redirects/channel-picker?returnUrl=${window.location.href}${target}`;
}
export const orderPremiumChannelPath = `/api/redirects/order/premiumchannel/all`;

function getTeliaBaseUrl(): string {
  if (getEnvironment() === "qa" || getEnvironment() === "development") {
    return "https://stage.telia.no";
  }
  return "https://www.telia.no";
}

export const editProfileLink = `${getTeliaBaseUrl()}/minside/getid`;

export const profileOverviewLink = `/api/redirects/myget/overview`;

export const customerServiceLink = `${getTeliaBaseUrl()}/kundeservice/kontakt-oss/`;

export const teliaApiUrl = `${getTeliaBaseUrl()}/api/teliaplayapi/`;
