/* eslint-disable @typescript-eslint/no-var-requires */
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import reducers from "ducks";

// /* globals __DEBUG__:false */
const __DEBUG__ = false;

const middlewares = [thunkMiddleware];

if (__DEBUG__) {
  middlewares.push(
    createLogger({
      actionTransformer: (action) => ({
        ...action,
        type: String(action.type),
      }),
    })
  );
}

const composeEnhancers =
  __DEBUG__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: {
          options: {
            symbol: true,
          },
        },
      })
    : compose;

const store = createStore(
  combineReducers(reducers),
  undefined,
  composeEnhancers(applyMiddleware(...middlewares))
);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept("ducks", () => {
    const nextReducer = require("ducks");

    store.replaceReducer(nextReducer);
  });
}

export default store;
