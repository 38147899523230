import Img from "components/helpers/Img";
import { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "styles/theme";
import { formatSecondsToTimestamp } from "utils/DateUtil";
import VTTParser from "utils/VTTParser";

const Timestamp = styled.span`
  font-size: 1.4rem;
  padding: 0.8rem;
  color: black;
  @media ${theme.bp.enormous} {
    font-size: 1.8rem;
  }
`;

const ThumbnailImage = styled(Img)<{ pos?: number }>`
  object-fit: cover;
  object-position: -${(props) => props.pos}px 0;

  width: 32rem;
  height: 18rem !important;
`;

const Wrapper = styled.div.attrs((props: { pos: number }) => ({
  style: { left: `${props.pos}px` },
}))<{ pos: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 0.8rem;
  overflow: hidden;
  bottom: 100%;
  min-width: 10rem;
`;

export type HoverData = {
  mousePosition: number;
  targetRect: DOMRect;
  parentRect?: DOMRect;
  minValue: number;
  maxValue: number;
};

type ThumbnailImage = {
  url: string;
  x: string;
};

type VttThumbnail = {
  start: number;
  end: number;
  text: string;
};

type Props = {
  data: HoverData;
  thumbnailUrl?: string;
};

export default function SeekThumbnail({
  data,
  thumbnailUrl,
}: Props): JSX.Element | null {
  const [humanReadableTimestamp, setHumanReadableTimestamp] = useState("00:00");
  const [thumbnail, setThumbnail] = useState<ThumbnailImage | undefined>();
  const [thumbnailArray, setThumbnailArray] = useState<VttThumbnail[]>([]);
  const [thumbnailBaseUrl, setThumbnailBaseUrl] = useState("");

  useEffect(() => {
    if (thumbnailUrl) {
      setThumbnailBaseUrl(thumbnailUrl.replace("thumbsprite.vtt", ""));
      fetch(thumbnailUrl).then((response) =>
        response
          .text()
          .then((value) => setThumbnailArray(VTTParser(value).cues))
      );
    }
  }, [thumbnailUrl]);

  const getThumbnail = (data) => {
    const positionOnProgressbar = data.mousePosition - data.targetRect.x;
    const progressbarWidth = data.targetRect.width;
    const percentagePosition = Math.min(
      Math.max(positionOnProgressbar / progressbarWidth, 0),
      1
    );
    const totalRange = data.maxValue - data.minValue;
    const currentHoverValue = totalRange * percentagePosition;

    const currentThumnail = thumbnailArray[Math.floor(currentHoverValue / 5)];

    if (currentThumnail) {
      setThumbnail({
        url: thumbnailBaseUrl + currentThumnail.text,
        x: currentThumnail.text.split("#xywh=")[1].split(",")[0],
      });
    }
    setHumanReadableTimestamp(formatSecondsToTimestamp(currentHoverValue));
  };

  const getThumbnailPosition = () => {
    if (thumbnail?.url) {
      return Math.min(
        Math.max(data.mousePosition - (data.parentRect?.x || 0) - 160, 0),
        data.targetRect.width - 160
      );
    }
    return Math.min(
      Math.max(data.mousePosition - (data.parentRect?.x || 0) - 50, 0),
      data.targetRect.width
    );
  };

  useEffect(() => {
    getThumbnail(data);
  }, [data]);

  if (humanReadableTimestamp) {
    return (
      <Wrapper pos={getThumbnailPosition()}>
        {thumbnail?.url && !thumbnail.url.includes("undefined") && (
          <ThumbnailImage src={thumbnail.url} pos={parseInt(thumbnail.x, 10)} />
        )}
        {humanReadableTimestamp && (
          <Timestamp>{humanReadableTimestamp}</Timestamp>
        )}
      </Wrapper>
    );
  }
  return null;
}
