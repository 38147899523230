import {
  differenceInCalendarDays,
  differenceInSeconds,
  format,
  isAfter,
  isBefore,
} from "date-fns";
import RowType from "enums/RowTypes";
import {
  Asset,
  Card,
  CardType,
  Episode,
  Season,
  Series,
  UserDataAsync,
} from "types/types";
import { isSubscribing, pastContent } from "utils/AssetUtil";
import {
  activeTransaction,
  fractionToPercentage,
  getAnyStart,
  getLinearStart,
  isAvailableExternalInTheFuture,
  isAvailableLiveInTheFuture,
  isAvailableNow,
  isAvailableSubscriptionInTheFuture,
  isAvailableTVODInTheFuture,
  isRented as isRentedAsset,
} from "utils/Availability";
import {
  formatNorwegian,
  getAvailabilityFrom,
  getAvailabilityTo,
  getDurationInSeconds,
  humanizeDuration,
} from "utils/DateUtil";
import {
  hasRecording,
  isRecording,
  showDeleteButton,
  showRecordingButton,
  willRecord,
} from "utils/Recordings";
import { isTVODUser } from "utils/UserUtil";
import {
  getAvailability,
  getLinearEnd,
  isAvailableLiveNow,
  isAvailableNowFromCard,
  isAvailableToBuy,
  isAvailableToRent,
} from "./Availability";
import UserAgentUtil from "./UserAgentUtil";

export const filterAll = (): boolean => true;
export const filterSeries = (content: Card): boolean =>
  content.contentType === "series";
export const filterMovies = (content: Card): boolean =>
  content.contentType === "movie";
export const filterPrograms = (content: Card): boolean =>
  content.contentType === "program";
export const filterExpired = (content: Card): boolean =>
  content.labelType === "expired";
export const filterNotExpired = (content: Card): boolean =>
  content.labelType !== "expired";
export const filterKids = (content: Card): boolean =>
  !!content.caption?.includes("Barn");

export function getId(card: Card, index: number): number {
  if (card?.asset?.id) {
    return card.asset.id;
  }
  if (card?.asset?.assetId) {
    return card.asset.assetId;
  }
  if (card?.contentId) {
    return card.contentId;
  }
  return index;
}

export function isLive(card: Card, currentTime?: Date): boolean {
  return !!(
    card?.isLive ||
    card?.labelType === "live" ||
    (card?.asset && currentTime && isAvailableLiveNow(card.asset, currentTime))
  );
}

export function isAvailableInTheFuture(card: Card, currentTime: Date): boolean {
  return !!(
    card?.asset &&
    (isAvailableLiveInTheFuture(card.asset, currentTime) ||
      isAvailableTVODInTheFuture(card, currentTime) ||
      isAvailableSubscriptionInTheFuture(card, currentTime) ||
      isAvailableExternalInTheFuture(card, currentTime))
  );
}

export function isLinear(card: Card): boolean {
  return !!card?.asset?.availabilities?.linear;
}

export function isCatchup(card: Card): boolean {
  return !!card?.asset?.availabilities?.catchUp;
}
export function isNpvr(card: Card): boolean {
  return !!card?.asset?.availabilities?.npvr;
}

export function isSvod(card: Card): boolean {
  return !!card?.asset?.availabilities?.subscription;
}

export function isExternal(card: Card): boolean {
  return !!card?.asset?.availabilities?.external;
}

export function getRentPrice(card: Card | Episode): string {
  if (card?.asset?.availabilities?.rent?.priceInNOK !== undefined) {
    return card.asset.availabilities.rent.priceInNOK.toString();
  }
  if (card?.asset?.rentPrice !== undefined) {
    return card.asset.rentPrice.toString();
  }
  return "";
}

export function getBuyPrice(card: Card | Episode): string {
  if (card?.asset?.availabilities?.buy?.priceInNOK !== undefined) {
    return card.asset.availabilities.buy.priceInNOK.toString();
  }
  if (card?.asset?.buyPrice !== undefined) {
    return card.asset.buyPrice.toString();
  }
  return "";
}

export function isRent(card: Card): boolean {
  return getRentPrice(card) !== "";
}

export function isBuy(card: Card): boolean {
  return getBuyPrice(card) !== "";
}

export function isTVOD(card: Card): boolean {
  return isRent(card) || isBuy(card);
}

/**
 * Returns true if one of the assets has a valid rental periode
 * @param {Card} card
 * @returns {boolean}
 */
export function isRented(card: Card, currentTime: Date): boolean {
  return !!(card?.asset && isRentedAsset(card.asset, currentTime));
}

export function isBought(card: Card, currentTime: Date): boolean {
  return !!(
    (card?.asset?.availabilities?.buy?.transaction &&
      activeTransaction(
        card.asset.availabilities.buy.transaction,
        currentTime
      )) ||
    !!(
      card?.asset &&
      typeof card.asset.buyTimestamp === "string" &&
      isAfter(currentTime, new Date(card.asset.buyTimestamp))
    )
  );
}

/**
 * Returns true if the asset was rented but is now expired
 * @param {Card} card
 * @returns {boolean}
 */
export function transactionExpired(card: Card, currentTime: Date): boolean {
  return !!(
    card?.asset?.availabilities?.rent?.transaction?.licenceEnd &&
    isBefore(
      new Date(card.asset.availabilities.rent.transaction.licenceEnd),
      currentTime
    )
  );
}

/**
 * Returns true if the asset was rented but is expired
 * @param {Card} card
 * @returns {boolean}
 */
export function isRentalExpired(card: Card, currentTime: Date): boolean {
  return !!(
    card?.asset?.rentPeriodExpiry !== undefined &&
    isBefore(new Date(card.asset.rentPeriodExpiry), currentTime)
  );
}

/**
 * Returns true if the asset was rented but is expired within the last 48 hours
 * @param {Card} card
 * @returns {boolean}
 */
export function isExpiredRecently(card: Card, currentTime: Date): boolean {
  if (!card?.asset?.rentPeriodExpiry) {
    return false;
  }
  return (
    isRentalExpired(card, currentTime) &&
    differenceInCalendarDays(
      currentTime,
      new Date(card.asset.rentPeriodExpiry)
    ) < 2
  );
}

export function isRentable(
  card: Card,
  user: UserDataAsync,
  currentTime: Date
): boolean {
  return (
    (isTVODUser(user) &&
      !!card?.asset &&
      currentTime &&
      !!isAvailableToRent(card?.asset, currentTime)) ||
    !!(
      isTVODUser(user) &&
      card?.asset &&
      Object.hasOwnProperty.call(card?.asset, "rentPrice")
    )
  );
}

export function isBuyable(
  card: Card,
  user: UserDataAsync,
  currentTime: Date
): boolean {
  return (
    (isTVODUser(user) &&
      !!card?.asset &&
      currentTime &&
      !!isAvailableToBuy(card?.asset, currentTime)) ||
    !!(
      isTVODUser(user) &&
      card?.asset &&
      Object.hasOwnProperty.call(card?.asset, "buyPrice")
    )
  );
}

export function hasTrailer(card: Card): boolean {
  return !!card?.hasTrailer;
}

function hasAnyDuration(card: Card) {
  return (
    card?.recordDuration ||
    card?.asset?.availabilities?.linear ||
    card?.asset?.runningTime
  );
}

export function getDuration(card: Card): number {
  if (card?.recordDuration) {
    return getDurationInSeconds(card.recordDuration);
  }
  if (card?.asset?.runningTime) {
    return getDurationInSeconds(card.asset.runningTime);
  }
  if (card?.asset?.availabilities?.linear) {
    return differenceInSeconds(
      new Date(card.asset.availabilities.linear.end),
      new Date(card.asset.availabilities.linear.start)
    );
  }
  return 0;
}

export function getProductionYear(card: Card): string | null {
  if (card?.productionYear) {
    return card.productionYear;
  }
  if (
    card &&
    Object.hasOwnProperty.call(card, "productionDate") &&
    typeof card.productionDate === "string" &&
    card.productionDate.length >= 4
  ) {
    return card.productionDate.slice(0, 4);
  }

  return null;
}

export function hasProductionYear(card: Card): boolean {
  return getProductionYear(card) !== null;
}

export function getRentStartTime(card: Card): string | null {
  if (card?.asset?.availabilities?.rent?.transaction?.licenceStart) {
    return card.asset.availabilities.rent.transaction.licenceStart;
  }

  return null;
}

export function getRentEndTime(card: Card): string | null {
  if (card?.asset?.availabilities?.rent?.transaction?.licenceEnd) {
    return card.asset.availabilities.rent.transaction.licenceEnd;
  }

  if (card?.asset?.rentPeriodExpiry) {
    return card.asset.rentPeriodExpiry;
  }

  return null;
}

export function getBuyStartTime(card: Card): string | null {
  if (card?.asset?.availabilities?.buy?.transaction?.licenceStart) {
    return card.asset.availabilities.buy.transaction.licenceStart;
  }

  return null;
}

export function getCatchupEndTime(card: Card): string | null {
  if (card?.asset?.availabilities?.catchUp?.end) {
    return card.asset.availabilities.catchUp.end;
  }

  return null;
}

/**
 * Returns watch position in seconds for card
 * @param {Card} card
 * @returns {number} watchPosition
 */
export function getWatchPosition(card: Card): number {
  let watchPosition = 0;

  if (
    card?.asset?.previousWatchPosition &&
    card.asset.previousWatchPosition > watchPosition
  ) {
    watchPosition = card.asset.previousWatchPosition;
  }

  if (
    card?.asset?.availabilities?.rent?.previousWatchPosition &&
    card.asset.availabilities.rent.previousWatchPosition > watchPosition
  ) {
    watchPosition = card.asset.availabilities.rent.previousWatchPosition;
  }

  if (
    card?.asset?.availabilities?.npvr?.previousWatchPosition &&
    card.asset.availabilities.npvr.previousWatchPosition > watchPosition
  ) {
    watchPosition = card.asset.availabilities.npvr.previousWatchPosition;
  }

  if (
    card?.asset?.availabilities?.catchUp?.previousWatchPosition &&
    card.asset.availabilities.catchUp.previousWatchPosition > watchPosition
  ) {
    watchPosition = card.asset.availabilities.catchUp.previousWatchPosition;
  }

  if (
    card?.asset?.availabilities?.subscription?.previousWatchPosition &&
    card.asset.availabilities.subscription.previousWatchPosition > watchPosition
  ) {
    watchPosition =
      card.asset.availabilities.subscription.previousWatchPosition;
  }
  return watchPosition || 0;
}

/**
 * Returns fraction watched
 * @param {Card} card
 * @returns {number} fraction watched
 */
export function getFractionWatched(card: Card): number {
  if (card?.asset?.fractionPlayed && card.asset.fractionPlayed >= 0) {
    return card.asset.fractionPlayed;
  }

  if (!hasAnyDuration(card)) {
    return 0;
  }

  const duration = getDuration(card);

  if (duration !== 0) {
    return getWatchPosition(card) / duration;
  }
  return 0;
}

export function getRemainingSecondsToWatch(card: Card): number {
  return getDuration(card) - getWatchPosition(card);
}

export function getLinearRemainingDuration(
  card: Card,
  currentTime: Date
): number {
  const end = getLinearEnd(card);

  if (end !== null) {
    return differenceInSeconds(end, currentTime);
  }
  return 0;
}

export function isFavourite(card: Card): boolean {
  return !!card?.favourite;
}

/**
 * Returns true if the watch position is passed watched threshold
 * @param {Card} card
 * @returns {boolean}
 */
export function isContentWatched(card: Card): boolean {
  return getFractionWatched(card) >= 0.95;
}

/**
 * Returns true if watch position is passed watched threshold or content is watched
 * @param {Card} card
 * @returns {boolean}
 */
export function isContentTouched(card: Card): boolean {
  return !!(
    isContentWatched(card) ||
    getWatchPosition(card) > 0 ||
    (card?.asset?.fractionPlayed && card.asset.fractionPlayed > 0)
  );
}

function getLivePercentage(card: Card, currentTime: Date): string {
  const start = getLinearStart(card);

  if (start !== null) {
    const progressInSeconds = differenceInSeconds(currentTime, start);

    return fractionToPercentage(progressInSeconds / getDuration(card));
  }
  return "0%";
}

/**
 * Returns progress in persentage for both live progress and regular watch position
 * @param {Card} card
 * @returns {string} progress in percent eg. "50%"
 */
export function getProgress(card: Card, currentTime: Date): string {
  if (isLive(card, currentTime)) {
    return getLivePercentage(card, currentTime);
  }

  if (card?.asset?.fractionPlayed && card.asset.fractionPlayed >= 0) {
    return fractionToPercentage(card.asset.fractionPlayed);
  }

  if (isContentTouched(card)) {
    return fractionToPercentage(getFractionWatched(card));
  }
  return "0%";
}

/**
 * Returns episode number
 * @param {Card} card
 * @returns {string} episode number
 */
export function getEpisodeNumber(card: Card | Episode): string {
  let seasonPrefix = "S";

  if (
    card?.seasonIndex &&
    typeof card.seasonIndex === "number" &&
    card?.seasonIndex > 1000
  ) {
    seasonPrefix = "";
  }

  if (card?.seasonIndex !== undefined && card?.episodeIndex !== undefined) {
    return `${seasonPrefix}${card.seasonIndex} E${card.episodeIndex}`;
  }
  if (card?.episodeIndex !== undefined) {
    return `E${card.episodeIndex}`;
  }
  if (card?.seasonIndex !== undefined) {
    return `${seasonPrefix}${card.seasonIndex}`;
  }
  return "";
}

/**
 * Returns url of image cover or default image
 * @param {Card} card
 * @returns {string} url of image cover
 */
export function getImageCover(card: Card): string {
  if (card?.imageCover) {
    return `${card.imageCover}?w=576&h=864`;
  }
  return "https://image.getvideo.cloud/static/fallback/movie-card.jpg?w=576&h=864";
}

export function getImagePosterPathOrNull(
  card: Card | Episode | Series
): string | null {
  if (card?.images?.poster?.[0]?.path) {
    return card.images.poster[0].path;
  }
  if (card?.imagePoster) {
    return card.imagePoster;
  }
  return null;
}

export function getImagePosterPath(card: Card | Episode): string {
  return (
    getImagePosterPathOrNull(card) ||
    "https://image.getvideo.cloud/static/fallback/movie-card.jpg"
  );
}

export function getBackgroundImagePath(card: Card | Episode | Series): string {
  return (
    getImagePosterPathOrNull(card) ||
    "https://image.getvideo.cloud/static/fallback/background.jpg"
  );
}

/**
 * Gets spoken language
 * @param {Card} card
 * @returns {string} language spoken in content
 */
export function getLanguage(card: Card): string {
  if (card?.asset?.audio?.[0]?.audioLanguage) {
    return card.asset.audio[0].audioLanguage;
  }
  if (card?.asset?.audioLanguage) {
    return card.asset.audioLanguage;
  }
  return "";
}

export function getVideoQuality(card: Card): string | null {
  if (card?.asset?.videoResolution) {
    return card.asset.videoResolution;
  }
  return null;
}

export function getNormalSourceLogo(card: Card): string {
  if (card?.asset) {
    if (card?.asset?.channel?.logo) {
      return card.asset.channel.logo;
    }
    if (card?.asset?.provider?.logo) {
      return card.asset.provider.logo;
    }
    if (card.asset?.channel?.logos?.normalOnDark) {
      return card.asset.channel.logos.normalOnDark;
    }
    if (card?.asset?.channel?.logos?.onDark) {
      return card.asset.channel.logos.onDark;
    }
    if (card?.asset?.channel?.logos?.normal?.onDark) {
      return card.asset.channel.logos.normal.onDark;
    }
    if (card?.asset?.channel?.logos?.onDark) {
      return card.asset.channel.logos.onDark;
    }
    if (card?.asset?.provider?.logos?.normal?.onDark) {
      return card.asset.provider.logos.normal.onDark;
    }
    if (card?.asset?.logo) {
      return card.asset.logo;
    }
  }
  if (card?.newSources && card?.newSources[0]?.logos?.normal?.onDark) {
    return card.newSources[0].logos.normal.onDark;
  }
  if (card?.provider?.logo) {
    return card.provider.logo;
  }

  return "";
}

export function getFixedSourceLogo(card: Card): string {
  if (card?.asset) {
    if (card.asset?.channel?.logos?.fixed?.onDark) {
      return card.asset.channel.logos.fixed.onDark;
    }
    if (card?.asset?.channel?.logoFixed) {
      return card.asset.channel.logoFixed;
    }
    if (card?.asset?.provider?.logos?.fixed?.onDark) {
      return card.asset.provider.logos.fixed.onDark;
    }
  }
  if (card?.newSources && card?.newSources[0]?.logos?.fixed?.onDark) {
    return card.newSources[0].logos.fixed.onDark;
  }
  if (card?.logos?.fixed?.onDark) {
    return card.logos.fixed.onDark;
  }
  return "";
}

/**
 * Returns the constructed episode title
 * @param {Card} card
 * @returns {string} episodetitle
 */
export function getEpisodeTitle(card: Card | Episode): string {
  if (getEpisodeNumber(card) && card?.title) {
    return `${getEpisodeNumber(card)}: ${String(card.title)}`;
  }
  if (getEpisodeNumber(card)) {
    return getEpisodeNumber(card);
  }
  if (card?.title) {
    return card.title;
  }
  return "";
}

export function isBingeMode(card: Card): boolean {
  return (
    !!(card.contentType === "episode" && card.contentId) ||
    !!(card.contentType && card.contentType === "series")
  );
}

export function getContentId(card: Card): number {
  return card?.contentId;
}

export function getAssetId(card: Card | Episode): number {
  return card?.asset?.assetId;
}

export function getPlayUrl(card: Card): string {
  const assetId = getAssetId(card);
  const contentType = isBingeMode(card) ? "spilleliste" : "innhold";
  const contentId = getContentId(card);
  const shouldRestart = isContentWatched(card) ? "/restart" : "";

  return `/se/${contentType}/${contentId}/${assetId}${shouldRestart}`;
}

export function getRestartUrl(card: Card): string {
  const playUrl = getPlayUrl(card);

  if (playUrl.includes("restart")) {
    return playUrl;
  }
  return `${playUrl}/restart`;
}

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function injectCaption(card: Card, string: string): string {
  if (card.caption) {
    return `${card.caption} ${string}`;
  }
  return capitalizeFirstLetter(string);
}

function showScheduledRecordingInfoline(
  card: Card,
  availabilityFrom: string
): string {
  if (card.caption && card.contentType === "series" && availabilityFrom) {
    return `${card.caption} tas opp ${availabilityFrom}`;
  }
  return `Tas opp ${availabilityFrom}`;
}

export function getInfoline(card: Card, currentTime: Date): string {
  if (!card) {
    return "";
  }

  const availabilityFrom = getAvailabilityFrom(card, currentTime);

  if (willRecord(card) && !!availabilityFrom) {
    return showScheduledRecordingInfoline(card, availabilityFrom);
  }
  if (
    (isTVOD(card) || isSvod(card)) &&
    isAvailableInTheFuture(card, currentTime) &&
    !!availabilityFrom
  ) {
    return injectCaption(card, `kommer ${availabilityFrom}`);
  }
  if (isAvailableInTheFuture(card, currentTime) && !!availabilityFrom) {
    return injectCaption(card, `sendes ${availabilityFrom}`);
  }
  if (!Object.prototype.hasOwnProperty.call(card, "context") && card.caption) {
    return card.caption;
  }
  if (card.validUntil) {
    return `Tilgjengelig til ${getAvailabilityTo(card, currentTime)}`;
  }
  if (isRecording(card) && !!availabilityFrom) {
    return injectCaption(
      card,
      `tatt opp ${availabilityFrom}. ${humanizeDuration(getDuration(card))}`
    );
  }
  if (isCatchup(card) && !!availabilityFrom) {
    return injectCaption(card, `sendt ${availabilityFrom}`);
  }
  if (pastContent(card, currentTime) && !!availabilityFrom) {
    return injectCaption(card, `sendt ${availabilityFrom}`);
  }
  if (isLive(card, currentTime) && !!availabilityFrom) {
    return injectCaption(card, `sendes ${availabilityFrom}`);
  }
  if (card.caption) {
    return card.caption;
  }
  if (card.contentType === "series" && isSvod(card)) {
    if (
      getRemainingSecondsToWatch(card) !== getDuration(card) &&
      getRemainingSecondsToWatch(card) > 0 &&
      isContentTouched(card)
    ) {
      return `${humanizeDuration(getDuration(card))} · ${humanizeDuration(
        getRemainingSecondsToWatch(card)
      )} gjenstår`;
    }
    return humanizeDuration(getDuration(card));
  }
  return "";
}

export function isUnavailable(card: Card, currentTime: Date): boolean {
  if (
    card?.asset &&
    Object.prototype.hasOwnProperty.call(card.asset, "available")
  ) {
    return !card.asset.available;
  }
  if (
    card?.asset &&
    Object.prototype.hasOwnProperty.call(card.asset, "subscribing") &&
    card.asset.subscribing === false
  ) {
    return true;
  }

  return !isAvailableNowFromCard(card, currentTime);
}

export function isExpired(card: Card): boolean {
  return card?.labelType === "expired";
}

export function showPlayButton(card: Card, currentTime?: Date): boolean {
  if (UserAgentUtil.unsupportedBrowser) {
    return false;
  }
  if (currentTime && isRented(card, currentTime)) {
    return true;
  }
  if (currentTime && isBought(card, currentTime)) {
    return true;
  }
  if (isTVOD(card)) {
    return false;
  }
  if (
    card?.asset?.available !== undefined &&
    Object.prototype.hasOwnProperty.call(card.asset, "assetId")
  ) {
    return card.asset.available;
  }
  if (
    hasRecording(card) &&
    Object.prototype.hasOwnProperty.call(card.asset, "assetId")
  ) {
    return true;
  }

  const availability =
    card?.asset && currentTime && getAvailability(card.asset, currentTime);

  if (
    availability &&
    Object.prototype.hasOwnProperty.call(availability, "watchable") &&
    (availability as any).watchable === false
  ) {
    return false;
  }
  return !!availability && !!(card?.asset && isSubscribing(card.asset));
}

export function showActionRow(
  card: Card,
  currentTime: Date,
  rowType: RowType
): boolean {
  return (
    (rowType === RowType.CONTINUE_WATCHING ||
      !!card?.asset?.subscribingError ||
      showRecordingButton(card, currentTime) ||
      showDeleteButton(currentTime, card) ||
      showPlayButton(card, currentTime)) &&
    !UserAgentUtil.mobile &&
    !card.isPublic
  );
}

export function getTitle(card: Card): string {
  if (card?.cardTitle) {
    return card.cardTitle;
  }
  if (card?.title) {
    return card.title;
  }
  return "Ingen informasjon";
}

export function getCaption(card: Card): string {
  if (card?.caption) {
    return card.caption;
  }
  return "";
}

function createFakeAsset(incompleteCard: Card): Asset {
  if (incompleteCard?.asset) {
    const { asset } = incompleteCard;
    if (asset.id) {
      asset.assetId = asset.id;
    }
    if (asset.allowCatchup === undefined) {
      asset.allowCatchup = false;
    }
    if (asset.availabilities === undefined) {
      asset.availabilities = {};
    }
    if (asset.runningTime === undefined) {
      asset.runningTime = "";
    }

    return asset;
  }

  return {
    assetId: 0,
    available: false,
    allowCatchup: false,
    availabilities: {},
    runningTime: "",
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createFakeCard(incompleteCard: any): Card {
  return {
    fakeCard: true,
    contentId: incompleteCard?.contentId || 0,
    asset: createFakeAsset(incompleteCard),
    ...incompleteCard,
  };
}

export function getNewestEpisodeStartInSeason(
  season: Season | Series,
  currentTime?: Date
): Date {
  if (season && season.episodes) {
    return season.episodes.reduce(
      (newestTime: Date, currentEpisode: Episode) => {
        const episodeStart = new Date(getAnyStart(currentEpisode));

        if (
          episodeStart &&
          isBefore(newestTime, episodeStart) &&
          currentTime &&
          isAvailableNow(currentEpisode.asset, currentTime) &&
          currentEpisode &&
          !isAvailableLiveNow(currentEpisode.asset, currentTime)
        ) {
          return episodeStart;
        }
        return newestTime;
      },
      new Date(0)
    );
  }
  return new Date(0);
}

export function getNewestEpisode(card?: Series, currentTime?: Date): Date {
  if (card?.seasons) {
    return card.seasons.reduce((newestTime: Date, currentSeason) => {
      const newestEpisodeFromSeason = getNewestEpisodeStartInSeason(
        currentSeason,
        currentTime
      );

      if (
        newestEpisodeFromSeason &&
        isBefore(newestTime, newestEpisodeFromSeason)
      ) {
        return newestEpisodeFromSeason;
      }
      return newestTime;
    }, new Date(0));
  }
  if (card?.episodes) {
    return getNewestEpisodeStartInSeason(card, currentTime);
  }

  return new Date(0);
}

/**
 * This function takes in a card (with alternative assets) and a list of alternatives and returns an array of alternatives
 */
export function getAllAlternatives(card?: Card, alternatives?: Card[]): Card[] {
  let allAlternatives: Card[] = [];

  if (alternatives) {
    allAlternatives = allAlternatives.concat(alternatives);
  }
  if (card && card.alternativeAssets && card.alternativeAssets.length > 0) {
    card.alternativeAssets.forEach((asset) => {
      if (card.id && asset.id) {
        allAlternatives = allAlternatives.concat({
          id: card.id,
          contentId: card.id,
          contentType: card.contentType,
          imageCover: card.imageCover,
          imagePoster: card.imagePoster,
          title: card.title,
          caption: card.caption,
          asset: {
            ...asset,
            assetId: asset.assetId || asset.id,
          },
        });
      }
    });
  }
  return allAlternatives;
}

export function getTimespan(card: Card): string {
  const start = getLinearStart(card);
  const end = getLinearEnd(card);

  if (start && end) {
    return `${formatNorwegian(start, "HH:mm")} - ${formatNorwegian(
      end,
      "HH:mm"
    )}`;
  }
  if (start) {
    return formatNorwegian(start, "HH:mm");
  }
  return "";
}

export function getFullTimespan(card: Card, currentTime: Date): string {
  if (isLive(card, currentTime)) {
    return `Sendes nå: ${getTimespan(card)} · ${humanizeDuration(
      getLinearRemainingDuration(card, currentTime)
    )} gjenstår`;
  }
  return `Sendes ${getTimespan(card)}`;
}

/*
  Context is added to cards so we can determin what context they are being used in.
  So far these contexts are used:
    - futureContent - used in the row "Kommer snart på TV"
    - series - used on series/program page
*/
export function addContext(contents: Card[], context: string): Card[] {
  if (contents) {
    return contents.map((content) => {
      content.context = context;
      return content;
    });
  }
  return [];
}

export function getCardType(collection?: Card[]): CardType {
  if (collection && collection.every((card) => card.contentType === "movie")) {
    return "movieCard";
  }
  return "mediaCard";
}

export function getFormattedStart(card: Card): string {
  const start = getLinearStart(card);

  if (start !== null) {
    return format(start, "HH:mm");
  }
  return "";
}

export function getFormattedEnd(card: Card): string {
  const end = getLinearEnd(card);

  if (end !== null) {
    return format(end, "HH:mm");
  }
  return "";
}

type BadgeType = {
  age?: string;
  type?: string;
  imdbRating?: string;
  genres?: string;
};
export function getBadge(card: Card): BadgeType {
  return {
    type: card?.badges?.[0],
    imdbRating: card.imdb?.rating.toString(),
    genres: card?.genres?.join(", ").toUpperCase(),
  };
}

export function isUpcoming(card: Card): boolean {
  return card?.labelType === "upcoming";
}
