import styled from "styled-components";
import theme from "../../styles/theme";

const CenteredMessageStyled = styled.main`
  box-sizing: content-box;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 650px;
  padding-left: 4vw;
  padding-right: 4vw;
  text-align: center;
  flex: 1;

  @media ${theme.bp.large} {
    padding-top: 150px;
  }

  & h1 {
    margin-bottom: 40px;
  }
`;

type Props = {
  className?: string;
  children: React.ReactNode;
};

function CenteredMessage({ className, children }: Props): JSX.Element {
  return (
    <CenteredMessageStyled className={className}>
      {children}
    </CenteredMessageStyled>
  );
}

export default CenteredMessage;
