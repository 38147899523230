import { USER_DATA as FETCH } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import apiFetch from "utils/apiFetch";

const initialState = {
  status: REQ.INIT,
  data: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH.REQUEST:
      return { ...state, status: REQ.PENDING };

    case FETCH.SUCCESS:
      return { ...state, status: REQ.SUCCESS, data: payload.data };

    case FETCH.ERROR:
      return {
        ...state,
        status: REQ.ERROR,
        data: {
          isAuthenticated: false,
        },
      };

    default:
      return state;
  }
}

export function fetchUserData() {
  return function (dispatch) {
    dispatch({
      type: FETCH.REQUEST,
    });

    const url = "api/userdata";

    return apiFetch(url).then(
      (result) => {
        dispatch({
          type: FETCH.SUCCESS,
          payload: {
            data: result,
          },
        });
        return result;
      },
      (non2xxResponseError) => {
        dispatch({ type: FETCH.ERROR, payload: non2xxResponseError });
      }
    );
  };
}
