export enum TriggerActionType {
  // Button
  BUY_BUTTON = "Buy Button", // Done
  CHANGE_RECORDING_BUTTON = "Change Recording Button",
  CHANNEL_ARCHIVE_BUTTON = "Channel Archive Button",
  CONFIRM_BUTTON = "Confirm Button",
  CONTINUE_WATCHING_BUTTON = "Continue Watching Button", // done
  DELETE_RECORDING_BUTTON = "Delete Recording Button", // done
  EXTERNAL_BUTTON = "External Button", // done
  FAVORITE_BUTTON = "Favorite Button", // done
  EPG_BUTTON = "Full EPG Button",
  LOGOUT_BUTTON = "Logout Button", // done
  MISC_SETTINGS_BUTTON = "Misc Settings Button", // not in web
  MORE_BUTTON = "More Button", // not in web
  NEXT_EPISODE_BUTTON = "Next Episode Button", // done
  PREV_EPISODE_BUTTON = "Prev Episode Button", // done
  PURCHASE_BUTTON = "Purchase Button",
  RECORDING_BUTTON = "Recording Button", // done
  RENT_BUTTON = "Rent Button", // done
  TRAILER_BUTTON = "Trailer Button", // done
  SERIES_DETAILS_BUTTON = "Series Details Button", // not in web
  SUBTITLE_SETTINGS_BUTTON = "Subtitle Settings Button", // not in web
  SYNOPSIS_BUTTON = "View Synopsis Button", // not in web
  WATCH_BUTTON = "Watch Button", // done
  WATCH_FROM_START_BUTTON = "Watch From Beginning Button", // done
  WATCH_LIVE_BUTTON = "Watch Live Button", // done

  // Card
  CHANNEL_CARD = "Channel Card", // done
  COLLECTION_CARD = "Collection Card", // done
  GENRE_CARD = "Genre Card", // done
  LIVE_CARD = "Live Card", // done
  MATCH_CARD = "Match Card", // ?
  MEDIA_CARD = "Media Card", // done
  PROGRAM_CARD = "Program Card", // not in web?
  PLAYER_CARD = "Player Card", // remove
  PROMOTION_CARD = "Promotion Card", // done
  PROVIDER_CARD = "Provider Card", // done
  SHORTCUT_CARD = "Shortcut Card", // "Utforsk alle"
  SHOW_ALL_CARD = "Show All Card", // "Se alle"

  // Page
  EPG_PAGE = "EPG Page", // done
  MINI_EPG_PAGE = "Mini EPG Page", // not in web
  SPLASH_SCREEN_PAGE = "Splash Screen Page", // not in web

  // Menu
  TOP_LEVEL_MENU = "Top Level Menu", // done
  BETA_BUTTON = "Beta Button",
}

export enum TrackingEventType {
  // Confirm Events
  CHANGE_MULTIPLE_CHANNEL_RECORDING = "Confirm Change MC Recording", // done
  CHANGE_SINGLE_CHANNEL_RECORDING = "Confirm Change SC Recording", // done
  DELETE_MULTIPLE_RECORDING = "Confirm Delete Multiple Recording", // done
  DELETE_SINGLE_RECORDING = "Confirm Delete Single Recording", // done
  INITIAL_MULTIPLE_CHANNEL_RECORDING = "Confirm Initial MC Recording", // done
  INITIAL_SINGLE_CHANNEL_RECORDING = "Confirm Initial SC Recording", // done
  GO_TO_PLAY_STORE = "Confirm Go To Play Store", // not used in web
  LOGOUT = "Confirm Logout", // done
  GO_TO_UPCOMING_EPISODE = "Confirm Go To Upcoming Episode", // done
  MISC_SETTINGS = "Confirm Misc Settings", // not in web
  PLAYER_SETTINGS = "Confirm Player Settings", // not in web
  PURCHASE_ABORT = "Confirm Purchase Abort", // done
  PURCHASE_CONFIRM = "Confirm Purchase", // done
  REQUEST_PURCHASE_SMS = "Request Purchase SMS Code", // done
  REQUEST_NEW_PURCHASE_SMS = "Request New Purchase SMS Code", // done
  PROFILE_CHANGE = "Confirm Profile Change", // done
  SUBTITLE_SETTINGS = "Confirm Subtitle Settings", // not in web

  // Play Events
  PLAY_EXTERNAL = "Play External", // done
  PLAY_TRAILER = "Play Trailer", // done
  PLAY_VIDEO = "Play Video", // done
  PLAY_LIVE = "Play Live", // done

  // Toggle Events
  TOGGLE_FAVORITE = "Toggle Favorite", // done
  TOGGLE_RECORDING = "Toggle Recording", // done

  // View Events
  VIEW_CHANGE_MULTIPLE_CHANNEL_RECORDING_PAGE = "View Change MC Recording Dialog", // done
  VIEW_CHANGE_SINGLE_CHANNEL_RECORDING_PAGE = "View Change SC Recording Dialog", // done
  VIEW_DELETE_MULTIPLE_RECORDING_PAGE = "View Delete Multiple Recording Dialog", // done
  VIEW_DELETE_SINGLE_RECORDING_PAGE = "View Delete Single Recording Dialog", // done
  VIEW_INITIAL_MULTIPLE_CHANNEL_RECORDING_PAGE = "View Initial MC Recording Dialog", // done
  VIEW_INITIAL_SINGLE_CHANNEL_RECORDING_PAGE = "View Initial SC Recording Dialog", // done
  VIEW_COLLECTION_PAGE = "View Collection Page", // done
  VIEW_EPISODE_DETAILS_PAGE = "View Episode Details Page", // done
  VIEW_EPG_PAGE = "View EPG Page", // done
  VIEW_GENRES_PAGE = "View Genres Page", // not used in web? split to overview for web
  VIEW_HOME_PAGE = "View Home Page", // done
  VIEW_KIDS_PAGE = "View Kids Page", // done
  VIEW_MINI_EPG_PAGE = "View Mini EPG Page", // not used in web
  VIEW_MISC_SETTINGS_PAGE = "View Misc Settings Page", // not used in web
  VIEW_MOVIES_PAGE = "View Movies Page", // done
  VIEW_MOVIE_DETAILS_PAGE = "View Movie Details Page", // done
  VIEW_MY_CONTENT_PAGE = "View My Content Page", // done
  VIEW_PLAY_STORE_DIALOG = "View Play Store Dialog", // not used in web
  VIEW_PLAYER_ERROR = "View Player Error", // done
  VIEW_PLAYER_SETTINGS_PAGE = "View Player Settings Page", // done
  VIEW_PROVIDERS_PAGE = "View Providers Page", // done
  VIEW_PROVIDER_PAGE = "View Provider Page", // done
  VIEW_PURCHASE_START_PAGE = "View Purchase Start Page", // done
  VIEW_PURCHASE_INFO_PAGE = "View Purchase Info Page", // done
  VIEW_PURCHASE_SMS_PAGE = "View Purchase SMS Page", // done
  VIEW_PURCHASE_FINISHED_PAGE = "View Purchase Finished Page", // done
  VIEW_PURCHASE_ERROR_DIALOG = "View Purchase Error Dialog", // done
  VIEW_PURCHASE_ABORT_DIALOG = "View Purchase Abort Dialog", // not in web
  VIEW_SEARCH_PAGE = "View Search Page", // done
  VIEW_SERIES_DETAILS_PAGE = "View Series Details Page", // done
  VIEW_SERIES_PAGE = "View Series Page", // done
  VIEW_SETTINGS_PAGE = "View Settings Page", // not used in web
  VIEW_SPORT_PAGE = "View Sport Page", // done
  VIEW_SUBTITLE_SETTINGS_PAGE = "View Subtitle Settings Page", // not used in web
  VIEW_SYNOPSIS_PAGE = "View Synopsis Page", // not on web
  VIEW_TVOD_PAGE = "View Movie Store Page", // done
  VIEW_TV_ARCHIVE_PAGE = "View TV Archive Page", // done
  VIEW_TV_LIVE_PAGE = "View TV Live Page", // done
  VIEW_UPCOMING_EPISODE_DIALOG = "View Upcoming Episode Dialog", // done
  VIEW_UPDATE_APP_PAGE = "View Update App Page", // not on web

  // Web specific
  VIEW_SERIES_OVERVIEW_PAGE = "View Series Overview Page", // done
  VIEW_MOVIES_OVERVIEW_PAGE = "View Movies Overview Page", // done
  VIEW_ARCHIVE_OVERVIEW_PAGE = "View Archive Overview Page", // done
  VIEW_KIDS_OVERVIEW_PAGE = "View Kids Overview Page", // done

  BETA_BUTTON = "Open Beta",
}

export type TriggerAction = {
  triggerAction?: TriggerActionType;
  triggerSorting?: string;
  triggerSource?: string | string[];
  triggerTab?: string | string[];
  triggerType?: "UI" | "";
  triggerRowTitle?: string;
  triggerPageTitle?: string;
  triggerPromotionId?: string;
  triggerRowIndex?: string | number;
  triggerCardIndex?: string | number;
  triggerSearchData?: string;
};

export type TrackingParameters = TriggerAction & {
  assetId?: number;
  assetIsDefined?: boolean;
  binge?: boolean;
  channelId?: number | number[];
  channelName?: string | string[];
  collectionId?: string;
  collectionName?: string;
  contentId?: string | number | number[];
  contentName?: string;
  contentType?: string;
  favorite?: boolean;
  mediaForm?: string[];
  message?: string;
  offline?: boolean;
  shortcut?: boolean;
  page?: string;
  packageName?: string;
  providerId?: number;
  sourceId?: number | string | number[];
  sourceName?: string | string[];
  sourceType?: string;
  npvrAccess?: boolean;
  npvrQuota?: string;
  providerName?: string;
  recording?: boolean;
  recordingId?: number | number[];
  restarted?: string;
  title?: string;
  topContentId?: number | string;
  topContentName?: string;
  caption?: string | string[];
  error?: string;
  exception?: string;
  genres?: string[];
  imdbRating?: number;
  purchaseType?: string;
  purchaseErrorCode?: string;
  purchaseErrorMessage?: string;
  playerContext?: string;
  playerSessionId?: string;
  playerAudioSetting?: string;
  previewChannelSetting?: string;
  playerSubtitleSetting?: string;
  playerVideoSetting?: string;
  enabledSubtitleSetting?: string;
  subtitleOrderSetting?: string;
  preferClosedCaptionSetting?: string;
};
