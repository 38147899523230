import { CHANNEL_EPG_UNTIL_DAYS } from "enums/ActionTypes";
import REQ from "enums/requestStatus";
import apiFetch from "utils/apiFetch";
import { formatResult } from "utils/formatOldCard";

const initialState = {
  status: REQ.INIT,
};

export default function epgAsyncForChannel(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHANNEL_EPG_UNTIL_DAYS.REQUEST:
      return {
        status: REQ.PENDING,
        data: state.data,
      };
    case CHANNEL_EPG_UNTIL_DAYS.SUCCESS:
      return {
        status: REQ.SUCCESS,
        data: payload,
      };
    case CHANNEL_EPG_UNTIL_DAYS.ERROR:
      return {
        status: REQ.ERROR,
      };

    default:
      return state;
  }
}

export function fetchEpgForChannelId(channelId) {
  return function (dispatch) {
    dispatch({
      type: CHANNEL_EPG_UNTIL_DAYS.REQUEST,
      channelId,
    });

    const url = `/api/tv-guide/channel/${channelId}/now/detailed`;

    return apiFetch(url)
      .catch((error) => {
        dispatch({
          type: CHANNEL_EPG_UNTIL_DAYS.ERROR,
          channelId,
          payload: error,
        });

        throw error;
      })
      .then((result) => {
        const formattedResult = formatResult(result);

        dispatch({
          type: CHANNEL_EPG_UNTIL_DAYS.SUCCESS,
          channelId,
          payload: formattedResult,
        });

        return formattedResult;
      });
  };
}
