import castController from "ducks/castController";
import catchupAll from "ducks/catchup/catchupAll";
import timestamp from "ducks/currentTime";
import editorialPromotionsAsync from "ducks/editorial/editorialPromotions";
import epgAll from "ducks/epg/epgAll";
import kidsListAsync from "ducks/kidsList";
import moviesListAsync from "ducks/movies/moviesList";
import seriesDetailsAsync from "ducks/series/seriesDetails";
import seriesListAsync from "ducks/series/seriesList";
import channelsAsync from "ducks/sources/channels";
import providersAsync from "ducks/sources/providers";
import streamInformationAsync from "ducks/stream/streamInformation";
import tvGuide from "ducks/tvGuide/tvGuide";
import tvGuideEpg from "ducks/tvGuide/tvGuideEpg";
import tvodListAsync from "ducks/tvodList";
import subtitlePreference from "ducks/userSpecificData/subtitlePreference";
import userDataAsync from "ducks/userSpecificData/userData";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  timestamp,
  channelsAsync,
  epgAll,
  streamInformationAsync,
  seriesDetailsAsync,
  seriesListAsync,
  moviesListAsync,
  catchupAll,
  kidsListAsync,
  editorialPromotionsAsync,
  castController,
  providersAsync,
  subtitlePreference,
  userDataAsync,
  tvodListAsync,
  tvGuideEpg,
  tvGuide,
};
