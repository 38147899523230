import { compareAsc, isBefore } from "date-fns";
import { Asset, Availability, Card, Episode, Transaction } from "types/types";
import { isBought } from "./Card";

export function isAvailable(availability: Availability, now: Date): boolean {
  if (!availability || !availability.start || !availability.end) {
    return false;
  }

  const startTimeDate = new Date(availability.start);
  const endTimeDate = new Date(availability.end);

  return (
    (isBefore(startTimeDate, now) && isBefore(now, endTimeDate)) ||
    compareAsc(startTimeDate, now) === 0
  );
}

export const isAvailableLiveNow = (
  asset: Asset,
  currentTime: Date
): boolean => {
  if (!asset?.availabilities?.linear?.watchable) {
    return false;
  }
  return isAvailable(asset.availabilities.linear, currentTime);
};

export const isLive = (card: Card | Episode, currentTime: Date): boolean => {
  if (card?.asset) {
    return isAvailableLiveNow(card.asset, currentTime);
  }
  return false;
};

export const isAvailableLiveInTheFuture = (
  asset: Asset,
  now: Date
): boolean => {
  if (asset?.availabilities?.linear) {
    return isBefore(now, new Date(asset.availabilities.linear.start));
  }
  return false;
};

export const isAvailableTVODInTheFuture = (card: Card, now: Date): boolean => {
  if (card?.asset?.availabilities?.buy) {
    return isBefore(now, new Date(card.asset.availabilities.buy.start));
  }
  if (card?.asset?.availabilities?.rent) {
    return isBefore(now, new Date(card.asset.availabilities.rent.start));
  }
  return false;
};

export const isAvailableSubscriptionInTheFuture = (
  card: Card,
  now: Date
): boolean => {
  if (card?.asset?.availabilities?.subscription) {
    return isBefore(
      now,
      new Date(card.asset.availabilities.subscription.start)
    );
  }
  return false;
};

export const isAvailableExternalInTheFuture = (
  card: Card,
  now: Date
): boolean => {
  if (card?.asset?.availabilities?.external) {
    return isBefore(now, new Date(card.asset.availabilities.external.start));
  }
  return false;
};

export const linearAvailabilityNotEnded = (
  asset: Asset,
  currentTime: Date
): boolean =>
  isAvailableLiveInTheFuture(asset, currentTime) ||
  isAvailableLiveNow(asset, currentTime);

const getAvailabilityFromName = (
  asset: Asset,
  now: Date,
  availabilityToGet: string
): boolean => {
  if (!asset || !asset.availabilities) {
    return false;
  }
  const availability = asset.availabilities[availabilityToGet];

  return isAvailable(availability, now) && availability;
};

export const catchUpIfAvailable = (asset: Asset, now: Date): boolean =>
  getAvailabilityFromName(asset, now, "catchUp");

const npvrIfAvailable = (asset: Asset, now: Date): boolean =>
  getAvailabilityFromName(asset, now, "npvr");

const liveIfAvailable = (asset: Asset, now: Date): boolean =>
  getAvailabilityFromName(asset, now, "linear");

const subscriptionIfAvailable = (asset: Asset, now: Date): boolean =>
  getAvailabilityFromName(asset, now, "subscription");

const externalIfAvailable = (asset: Asset, now: Date): boolean =>
  getAvailabilityFromName(asset, now, "external");

export const isAvailableExternal = (asset: Asset, now: Date): boolean =>
  !!asset?.availabilities?.external &&
  isAvailable(asset.availabilities.external, now);

export const isAvailableCatchup = (asset: Asset, now: Date): boolean =>
  !!asset?.availabilities?.catchUp &&
  isAvailable(asset.availabilities.catchUp, now);

export const isAvailableNpvr = (asset: Asset, now: Date): boolean =>
  !!asset?.availabilities?.npvr && isAvailable(asset.availabilities.npvr, now);

export const activeTransaction = (
  transaction: Transaction,
  now: Date
): boolean => {
  const start = new Date(transaction.licenceStart);
  const end = new Date(transaction.licenceEnd);

  return isBefore(start, now) && isBefore(now, end);
};

// Returns true if rent is available now
export const isAvailableToRent = (asset: Asset, now: Date): boolean =>
  !!asset?.availabilities?.rent &&
  isAvailable(asset.availabilities.rent, now) &&
  (!asset.availabilities.rent.transaction ||
    !activeTransaction(asset.availabilities.rent.transaction, now));

// Returns true if buy is available now
export const isAvailableToBuy = (asset: Asset, now: Date): boolean =>
  !!asset?.availabilities?.buy &&
  isAvailable(asset.availabilities.buy, now) &&
  (!asset.availabilities.buy.transaction ||
    !activeTransaction(asset.availabilities.buy.transaction, now));

export const isRented = (asset: Asset, now: Date): boolean =>
  (asset?.availabilities?.rent?.transaction &&
    activeTransaction(asset.availabilities.rent.transaction, now)) ||
  !!(
    asset &&
    typeof asset.rentPeriodExpiry === "string" &&
    isBefore(now, new Date(asset.rentPeriodExpiry))
  );

function getRentAvailability(asset: Asset, now: Date): Availability | boolean {
  if (isRented(asset, now) && asset?.availabilities?.rent) {
    return asset.availabilities.rent;
  }
  return false;
}

function getBuyAvailability(asset: Asset, now: Date): Availability | boolean {
  if (isBought({ contentId: 0, asset }, now) && asset?.availabilities?.buy) {
    return asset.availabilities.buy;
  }
  return false;
}

export const isAvailableNow = (asset: Asset, now: Date): boolean =>
  isAvailableLiveNow(asset, now) ||
  !!npvrIfAvailable(asset, now) ||
  !!catchUpIfAvailable(asset, now) ||
  !!subscriptionIfAvailable(asset, now) ||
  isAvailableExternal(asset, now) ||
  isRented(asset, now) ||
  isBought({ contentId: 0, asset }, now);

export const isAvailableNowFromCard = (
  card: Card,
  currentTime: Date
): boolean => card?.asset && isAvailableNow(card.asset, currentTime);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getAnyAvailability(card: Card | Episode, currentTime?: Date) {
  if (card?.asset?.availabilities) {
    if (
      card.asset.availabilities.linear &&
      currentTime &&
      isLive(card, currentTime)
    ) {
      return card.asset.availabilities.linear;
    }
    const availabilityKeys = Object.keys(card.asset.availabilities);

    if (availabilityKeys.length !== 0) {
      return card.asset.availabilities[availabilityKeys[0]];
    }
  }
  return false;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAvailability = (asset: Asset, currentTime: Date) =>
  getRentAvailability(asset, currentTime) ||
  getBuyAvailability(asset, currentTime) ||
  subscriptionIfAvailable(asset, currentTime) ||
  npvrIfAvailable(asset, currentTime) ||
  catchUpIfAvailable(asset, currentTime) ||
  liveIfAvailable(asset, currentTime) ||
  externalIfAvailable(asset, currentTime);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isAvailableInternally = (asset: Asset, currentTime: Date) =>
  getRentAvailability(asset, currentTime) ||
  getBuyAvailability(asset, currentTime) ||
  subscriptionIfAvailable(asset, currentTime) ||
  npvrIfAvailable(asset, currentTime) ||
  catchUpIfAvailable(asset, currentTime) ||
  liveIfAvailable(asset, currentTime);

export function awayFromHome(card: Card): boolean {
  return (
    !card?.asset?.availabilities?.linear?.watchable &&
    card?.asset?.availabilities?.linear?.watchableError ===
      "Avspilling er ikke tillatt utenfor hjemmet ditt."
  );
}

export function getLinearStart(card: Card | Episode): Date | null {
  if (card?.asset?.availabilities?.linear?.start) {
    return new Date(card.asset.availabilities.linear.start);
  }
  return null;
}

export function getLinearEnd(card: Card): Date | null {
  if (card?.asset?.availabilities?.linear?.end) {
    return new Date(card.asset.availabilities.linear.end);
  }
  return null;
}

// Converts a fraction to a percentage that can be used in css
export function fractionToPercentage(fraction: number): string {
  return `${Math.max(0, Math.min(100, Math.round(100 * fraction)))}%`;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getAnyStart(card: Card | Episode) {
  const linearStart = getLinearStart(card);

  if (linearStart !== null) {
    return linearStart;
  }
  const anyAvailability = getAnyAvailability(card);

  if (anyAvailability && anyAvailability.start) {
    return anyAvailability.start;
  }
  return null;
}

export function getAllAvailabilitiesParam(): string {
  return "catchUp,external,linear,npvr,subscription,rent,buy";
}
